import {BackendService} from "./backend.service";
import {SessionService} from "./session.service";
import {JsonOf} from "../utils/types/backendTypes";
import {AbstractService} from "./abstract.service";
import {Injectable} from "@angular/core";
import {IRechnung, isRechnung} from "../models/IRechnungModel";

@Injectable({
  providedIn: 'root'
})
export class RechnungService extends AbstractService<IRechnung>{

  public fromJsonObj<T>(obj: JsonOf<T>):T{
    BackendService.updateIdToProperty(obj, "kind", (id)=> (window as any).kindService.getById(id), false, () => (window as any).kindService.getRevision());
    return obj as unknown as T;
  }

  constructor(
    backend: BackendService,
    sessionService: SessionService) {
    super(
      backend,
      sessionService,
      "getRechnung",
      "createRechnung",
      "updateRechnung",
      "deleteRechnung"
    );
  }

  async getRechnungWithPdf(id: number): Promise<IRechnung> {
    const result = await this.backend.sendRequest("getRechnungWithPdf", {
      sessionId: this.sessionService.session.sessionId,
      id: id
    }, false);

    if(isRechnung(result)){
      return result;
    }
    throw result;
  }

  async sendRechnung(id: number){
    const result = await this.backend.sendRequest("sendRechnung", {
      sessionId: this.sessionService.session.sessionId,
      rechnungsId: id
    }, true);
  }
}
