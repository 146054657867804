<h3 class="text-center m-2">Kinder Anmelden</h3>
<app-filter [actionButtons]="actionButtons" [categories]="categories" [(query)]="query" (queryChange)="updateFilter()"></app-filter>
<div class="table-frame">
  <div *ngIf="!anmeldungenAktiv" class="rounded text-center mx-5 pcBorder py-1">
    <i class="fas fa-info-circle"></i>
    Anmeldungen sind noch nicht aktiv. Es können Voranmeldungen gemacht werden, welche ab dem {{anmeldungenDatum | datum}} {{anmeldungenDatum | zeit}} dann mit einem Klick in Anmeldungen geändert werden können.
    <i class="fas fa-info-circle"></i>
  </div>
  <table class="m-2 table text-center">
    <tr class="sticky-top grayBgColor">
      <th>ID</th>
      <th>Datum</th>
      <th>Titel</th>
      <th>Alter</th>
      <th>Preis</th>
      <th class="pcBorderRight">Sozialpreis</th>
      <th colspan="2" *ngFor="let k of kinder">{{k.vorname}} ({{calcPrice(k)}} €)</th>
    </tr>
    <tr *ngFor="let a of aktionen" [class]="getClass(a)">
      <td class="align-middle">{{a.id}}</td>
      <td class="align-middle">{{a | aktionDate:"all"}}</td>
      <td class="align-middle">{{a.titel}}</td>
      <td class="align-middle">{{a | aktionAlter}}</td>
      <td class="align-middle">{{a.preis.toFixed(2)}} €</td>
      <td class="align-middle pcBorderRight">{{a.soziPreis.toFixed(2)}} €</td>
      <ng-container *ngFor="let k of kinder">
        <td class="text-right primaryFontColor">
          <ng-container *ngIf="getAnmeldung(k, a)?.status !== AnmeldungStatus.GEBUCHT; else gebucht">
            <input type="checkbox" [disabled]="!matchesAge(a, k)" [(ngModel)]="selections[a.id][k.id]" class="largeCheckbox mx-2">
          </ng-container>
          <ng-template #gebucht>
            <div ngbTooltip="Kind ist bereits Gebucht. Bitte wenden Sie sich an den T.o.J. zum Abmelden.">
              <input type="checkbox" checked class="largeCheckbox mx-2" disabled>
            </div>
          </ng-template>
        </td>
        <td class="text-left primaryFontColor">
          <i *ngIf="getAnmeldung(k, a)?.status === AnmeldungStatus.WARTELISTE" class="fas fa-list"></i>
          <ng-container *ngIf="getAnmeldung(k, a)?.status === AnmeldungStatus.VORANGEMELDET">
            (<i class="fas fa-check"></i>)
          </ng-container>

          <i *ngIf="getAnmeldung(k, a)?.status === AnmeldungStatus.GEBUCHT" class="fas fa-check-double"></i>
          <i *ngIf="getAnmeldung(k, a)?.status === AnmeldungStatus.ANGEMELDET" class="fas fa-check"></i>
        </td>
      </ng-container>
    </tr>
    <tr class="white">
      <td colspan="4">Ferienpass</td>
      <td>{{passPreis.toFixed(2)}} €</td>
      <td>{{soziPassPreis.toFixed(2)}} €</td>
      <ng-container *ngFor="let k of kinder">
        <td class="text-right">
          <input disabled type="checkbox" [checked]="hasAnmeldung(k)" class="largeCheckbox mx-2">
        </td>
        <td></td>
      </ng-container>
    </tr>
  </table>
  <div class="p-2">
    <span class="white">Plätze Frei</span> |
    <span class="yellow">Fast Voll</span> |
    <span class="red">Voll</span><br>
    <i class="fas fa-list"></i> Warteliste.<br>
    (<i class="fas fa-check"></i>) Vorangemeldet (Das Kind ist für die Veranstaltung eingetragen, aber es muss noch von Ihnen Bestätigt werden, wenn die Anmeldungen freigegeben sind.).<br>
    <i class="fas fa-check"></i> Angemeldet (Das Kind ist für die Veranstaltung angemeldet. Das T.o.J. wird diese prüfen und dann buchen. Hierzu erhalten Sie dann eine Buchungsbestätigung als E-Mail.).<br>
    <i class="fas fa-check-double"></i> Gebucht (Das Kind wurde von einem T.o.J. Mitarbeiter in die Aktion aufgenommen).
  </div>
</div>
<div style="height:55px;"></div>
<div class="fixed-bottom secondaryBgColor d-flex justify-content-center justify-content-lg-end">
  <button [disabled]="saveActive" class="btn pcButton m-2" (click)="saveAnmeldungen()">Speichern <i *ngIf="saveActive" class="fas fa-circle-notch fa-spin"></i></button>
</div>
<div *ngIf="saveActive" style="z-index: 99999999; width: 100vw; height: 100vh; top: 0; left:0; background: rgba(0,0,0,0.3)" class="position-fixed">
  <i style="top: 50%; left: 50%; transform: translate(-50%, -50%)" class="position-absolute fa-10x fas fa-circle-notch fa-spin"></i>
</div>
