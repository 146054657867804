 <nav class="navbar navbar-expand-xl navbar-dark primaryBgColor"  [ngClass]="{'dev': dev}" >
  <!-- Brand -->
  <a class="navbar-brand" href="#">
    <img src="../../assets/img/toj.png" class="logo" alt="Logo" style="width:40px;">
  </a>

  <!-- Toggler/collapsibe Button -->
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
    <span class="navbar-toggler-icon"></span>
  </button>

  <!-- Navbar links -->
  <div class="collapse navbar-collapse" id="collapsibleNavbar">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a data-toggle="collapse" data-target="#collapsibleNavbar" class="nav-link" routerLink="/help">Startseite</a>
      </li>
      <li class="nav-item">
        <a data-toggle="collapse" data-target="#collapsibleNavbar" class="nav-link" routerLink="aktion">Aktionen</a>
      </li>
      <li class="nav-item" *ngIf="!isLoggedIn">
        <a data-toggle="collapse" data-target="#collapsibleNavbar" class="nav-link" routerLink="login">Anmeldung</a>
      </li>
      <li class="nav-item" *ngIf="!isLoggedIn">
        <a data-toggle="collapse" data-target="#collapsibleNavbar" class="nav-link" routerLink="registration">Registrieren</a>
      </li>
      <li class="nav-item" *ngIf="isLoggedIn && !isMa">
        <a data-toggle="collapse" data-target="#collapsibleNavbar" class="nav-link" routerLink="anmelden">Kinder-Anmelden</a>
      </li>
      <li class="nav-item" *ngIf="isMa">
        <a data-toggle="collapse" data-target="#collapsibleNavbar" class="nav-link" routerLink="anmelden/kinderBuchen">Kinder-Buchen</a>
      </li>
      <li class="nav-item" *ngIf="isLoggedIn">
        <a data-toggle="collapse" data-target="#collapsibleNavbar" class="nav-link" routerLink="settings/elternList">Einstellungen</a>
      </li>
      <li class="nav-item">
        <a data-toggle="collapse" data-target="#collapsibleNavbar" class="nav-link" routerLink="datenschutz">Datenschutz</a>
      </li>
      <li class="nav-item">
        <a data-toggle="collapse" data-target="#collapsibleNavbar" class="nav-link" routerLink="impressum">Impressum</a>
      </li>
    </ul>

    <hr class="d-block d-lg-none">
    <form class="form-inline ml-lg-auto">
      <app-login></app-login>
    </form>
  </div>

</nav>
