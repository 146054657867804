import { Component, OnInit } from '@angular/core';
import {AktionService} from "../../service/aktion.service";
import {AnmeldungService} from "../../service/anmeldung.service";
import {SessionService} from "../../service/session.service";
import {KindService} from "../../service/kind.service";
import {PdfGeneratorService} from "../../service/pdf-generator.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-system-settings',
  templateUrl: './system-settings.component.html',
  styleUrls: ['./system-settings.component.scss']
})
export class SystemSettingsComponent implements OnInit {

  anmeldungenAktiv = false;
  anmeldungenDatum!: number;

  newTime: number;

  constructor(
    private readonly aktionService: AktionService,
    private readonly anmeldungService: AnmeldungService,
    private readonly toastr: ToastrService
  ) {
    this.anmeldungenAktiv = this.anmeldungService.isAnmeldungActive();
    this.anmeldungenDatum = this.anmeldungService.getAnmeldungsbeginn();
    this.newTime = this.anmeldungenDatum;
  }

  ngOnInit(): void {
  }

  async save() {
    try{
      await this.anmeldungService.setAnmeldebeginn(this.newTime)
      this.toastr.success("Gespeichert");
    } catch (e) {
      console.log(e);
      this.toastr.error(e?.msg || e, "Fehler");
    }
  }
}
