import {Component, Input, OnInit} from '@angular/core';
import {IKind} from "../../models/IKind.interface";
import {KindService} from "../../service/kind.service";
import {IAnmeldungHistory} from "../../models/IAnmeldungHistory.interface";
import {AnmeldungHistoryService} from "../../service/anmeldungs-history.service";
import {AnmeldungService} from "../../service/anmeldung.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-anmeldungs-history',
  templateUrl: './anmeldungs-history.component.html',
  styleUrls: ['./anmeldungs-history.component.scss']
})
export class AnmeldungsHistoryComponent implements OnInit {

  @Input()
  public kindId: number
  public kind?: IKind
  public anmeldungHistory: IAnmeldungHistory[];
  isLoading =false;
  constructor(private readonly kindService: KindService,
              private readonly anmeldungHistoryService:AnmeldungHistoryService,
              private readonly anmeldungService: AnmeldungService,
              private readonly activeModal:NgbActiveModal){

  }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.kind = this.kindService.getById(this.kindId);

    await this.anmeldungHistoryService.updateData();
    this.anmeldungHistory = this.anmeldungHistoryService.getList();
    this.anmeldungHistory = this.anmeldungHistory.filter(a => a.anmeldung.kind.id === this.kindId);
    this.anmeldungHistory = this.anmeldungHistory.sort((a,b) => b.created - a.created);
    this.isLoading = false;
  }

  closeModal() {
    this.activeModal.close();
  }
}
