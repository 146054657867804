<app-subnav [items]="subnav" ></app-subnav>
<div class="container my-5">
  <app-filter [categories]="categories" [(query)]="query" (queryChange)="updateFilter()"></app-filter>
  <table class="table text-center">
    <tr>
      <th>ID</th>
      <th>Name</th>
      <th>E-Mail</th>
      <th>Kinder</th>
      <th> </th>
    </tr>
    <ng-container *ngFor="let et of eltern">
      <tr class="align-middle">
        <td>{{et.id}}</td>
        <td>{{et.vorname}} {{et.nachname}}</td>
        <td>{{et.mail}}</td>
        <td>{{et.kinder.length}}</td>
        <td class="text-right">
          <a class="btn pcButton mx-1" [routerLink]="'/kind/new/'+et.id" ngbTooltip="Kind hinzufügen"><i class="fas fa-plus"></i></a>
          <a class="btn pcButton mx-1" [routerLink]="'/eltern/edit/'+et.id" ngbTooltip="Account bearbeiten"><i class="fas fa-edit"></i></a>
          <button class="btn pcButton mx-1" ngbTooltip="Account löschen" (click)="deleteEltern(et)" ><i [class]="'fas ' + (et.id === deleteElternActiveId ? 'fa-circle-notch fa-spin' : 'fa-trash')"></i></button>
        </td>
      </tr>
      <ng-container *ngFor="let kd of et.kinder">
        <tr class="secondColor align-middle">
          <td class="text-right">{{kd.id}}</td>
          <td >{{kd.vorname}} {{kd.nachname}}</td>
          <td colspan="2">{{kd.geburtsdatum | ageFilter}} Jahre alt</td>
          <td class="py-0 align-middle text-right">
            <a class="btn mx-1 pcButton " [routerLink]="'/kind/edit/'+kd.id" ngbTooltip="Kind bearbeiten"><i class="fas fa-edit"></i></a>
            <button class="btn pcButton mx-1" ngbTooltip="Kind löschen" (click)="deleteKind(kd)"><i [class]="'fas ' + (kd.id === deleteKindActiveId ? 'fa-circle-notch fa-spin' : 'fa-trash')"></i></button>
          </td>
        </tr>
      </ng-container>
    </ng-container>
  </table>

</div>
