import {Component, OnInit} from '@angular/core';
import {AktionService} from "../../service/aktion.service";
import {AnmeldungService} from "../../service/anmeldung.service";
import {IKind} from "../../models/IKind.interface";
import {SessionService} from "../../service/session.service";
import {KindService} from "../../service/kind.service";
import {AnmeldungStatus} from "../../models/enums";
import {PdfGeneratorService} from "../../service/pdf-generator.service";

@Component({
  selector: 'app-anmeldung-confirm',
  templateUrl: './anmeldung-confirm.component.html',
  styleUrls: ['./anmeldung-confirm.component.scss']
})
export class AnmeldungConfirmComponent implements OnInit {

  anmeldungenAktiv = false;
  anmeldungenDatum!: number;
  kinder!: IKind[]

  constructor(
    private readonly aktionService: AktionService,
    private readonly anmeldungService: AnmeldungService,
    private readonly sessionService: SessionService,
    private readonly kindService: KindService,
    private readonly pdfService: PdfGeneratorService
  ) {
    this.anmeldungenAktiv = this.anmeldungService.isAnmeldungActive();
    this.anmeldungenDatum = this.anmeldungService.getAnmeldungsbeginn();
  }

  ngOnInit(): void {
    this.kinder = this.kindService.getList().filter(k => k.eltern.id === this.sessionService.session.nutzer.id);
  }

  public getAnmeldungenList(kind: IKind): {
    angemeldet: number,
    vorangemeldet: number,
    warteliste: number,
    gebucht: number
  } {
    const ret = {
      angemeldet:0,
      vorangemeldet:0,
      warteliste:0,
      gebucht:0
    }

    for(const a of kind.anmeldungen) {
      switch (a.status) {
        case AnmeldungStatus.GEBUCHT:
          ret.gebucht++;
          break;
        case AnmeldungStatus.VORANGEMELDET:
          ret.vorangemeldet++;
          break;
        case AnmeldungStatus.ANGEMELDET:
          ret.angemeldet++;
          break;
        case AnmeldungStatus.WARTELISTE:
          ret.warteliste++;
          break;
      }
    }

    return ret;
  }

  public download(kind: IKind) {
    this.pdfService.downloadAnmeldungen(kind);
  }

}
