<div class="container py-4">
  <div class=" form-group" *ngFor="let line of fields">
    <app-input [(val)]="eltern[line.key]" [name]="line.label" [required]="line.pflichtfeld" [placeholder]="line.placeholder" [type]="line.type"></app-input>
  </div>
  <div class="form-group" *ngIf="isMa && !isSelf">
    <label class="pflichtfeld">Rolle</label>
    <select class="form-control" [(ngModel)]="eltern.rolle">
      <option [ngValue]="Rolle.ET">Eltern</option>
      <option [ngValue]="Rolle.MA">Mitarbeiter</option>
      <option [ngValue]="Rolle.ADM" *ngIf="isAdm">Admin</option>
    </select>
  </div>
  <div class="form-group" *ngIf="isSelf || isAdm">
    <label>Passwort (Leer lassen um es nicht zu ändern)</label>
    <input class="form-control mb-3" [(ngModel)]="passwort1" type="password">
    <input class="form-control" *ngIf="passwort1" [(ngModel)]="passwort2" placeholder="Passwort wiederholen" type="password">
    <app-password-check [password]="passwort1" *ngIf="passwort1" (passwordValid)="passwortOk = $event"></app-password-check>
  </div>
  <div class="form-group mt-4">
    <input type="checkbox" class="largeCheckbox mr-3" [(ngModel)]="eltern.newsletter">
    <label>Newsletter</label>
  </div>
</div>
<div style="height:55px;"></div>
<div class="fixed-bottom secondaryBgColor d-flex justify-content-center justify-content-lg-end">
  <button class="btn pcButton m-2" (click)="saveEltern()">Speichern</button>
</div>
