<div class="p-3 scroll">
    <h3 class="text-center">Anmeldungshistory von {{kind.vorname}}</h3>
    <table class="table" *ngIf="!isLoading">
        <tr class="text-center">

            <th>ID</th>
            <th>Beschreibung</th>
            <th>Geändert von</th>
            <th>Aktion(ID)</th>
            <th>Erstellt</th>
            <th>alter Status</th>
            <th>neuer Status</th>
        </tr>
        <tr *ngFor="let a of anmeldungHistory">

                <td>{{a.id}}</td>
                <td>{{a.change}}</td>
                <td class="text-center">{{a.eltern?.vorname}} {{a.eltern?.nachname}}</td>
                <td>{{a.anmeldung.aktion.titel}}({{a.anmeldung.aktion.id}})</td>
                <td>{{a.created | datum}} {{a.created | zeit}}</td>
                <td class="text-center">{{a.alterStatus}}</td>
                <td class="text-center">{{a.neuerStatus}}</td>

        </tr>
    </table>
    <div *ngIf="isLoading" class="d-flex align-content-center justify-content-center">
        <div class="mt-5"><i class="fas fa-10x fa-circle-notch fa-spin"></i></div>
    </div>
</div>
<div class="closeIconDiv" (click)="closeModal()">
    <i class="fas fa-circle-xmark fa-2x text-danger"></i>
</div>