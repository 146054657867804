import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zeit'
})
export class ZeitPipe implements PipeTransform {

  transform(value: number): unknown {
    const date = new Date(value);
    return ZeitPipe.toTimeString(date);
  }
  private static toTimeString(date: Date): string {
    if(date.getMinutes() < 10) {
      return date.getHours() + ":0" + date.getMinutes();
    }
    return date.getHours() + ":" + date.getMinutes();
  }


}
