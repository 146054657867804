import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-confirm-window',
  templateUrl: './confirm-window.component.html',
  styleUrls: ['./confirm-window.component.scss']
})
export class ConfirmWindowComponent implements OnInit, OnDestroy {
  

  public titel:string;
  public text:string;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  confirm() {
    this.activeModal.close(true);
  }

  dismiss(){
    this.activeModal.close(false);
  }
  
  ngOnDestroy(): void {
    
    
  }
}
