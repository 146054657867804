<ng-container [ngSwitch]="type">
  <app-input-date *ngSwitchCase="'date'" [name]="name" [required]="required" [tooltip]="tooltip" [val]="val" (valChanged)="valChange.emit($event)" [withTime]="false"></app-input-date>
  <app-input-date *ngSwitchCase="'dateTime'" [name]="name" [required]="required" [tooltip]="tooltip" [val]="val" (valChanged)="valChange.emit($event)" [withTime]="true"></app-input-date>
  <app-input-iban *ngSwitchCase="'iban'" [name]="name" [required]="required" [tooltip]="tooltip" [val]="val" (valChange)="valChange.emit($event)"></app-input-iban>
  <div *ngSwitchCase="'textarea'" class="form-group">
    <label [class.pflichtfeld]="required" class="primaryFontColor">{{name}}</label>
    <textarea class="form-control" [ngbTooltip]="tooltip" [ngModel]="val" (ngModelChange)="val = $event; valChange.emit($event)" [placeholder]="placeholder"></textarea>
  </div>

  <div *ngSwitchDefault class="form-group">
    <label [class.pflichtfeld]="required" class="primaryFontColor">{{name}}</label>

    <input [type]="getType()"
           class="form-control"
           [ngModel]="val"
           (ngModelChange)="val = $event; valChange.emit($event)"
           [placeholder]="placeholder"

           [ngbTooltip]="tooltip">
  </div>
</ng-container>
