import {IAktion} from "./IAktion.interface";
import {getAbstractModelKeyPrettyName, IAbstractModel} from "./IAbstractModel.interface";
import {AnmeldungStatus} from "./enums";
import {IKind} from "./IKind.interface";
import {IAnmeldungHistory} from "./IAnmeldungHistory.interface";

export const SOZI_PASS_PREIS = 1;
export const PASS_PREIS = 3;

export interface IAnmeldung extends IAbstractModel{
  kind: IKind
  aktion: IAktion
  status: AnmeldungStatus
  bezahlt: boolean
  historie: IAnmeldungHistory[]
}


export function isAnmeldung(obj: Object): obj is IAnmeldung {
  function d(value: string): boolean{
    return value !== undefined;
  }

  return obj &&
    d(obj["kind"]) &&
    d(obj["aktion"]) &&
    d(obj["status"]) &&
    d(obj["bezahlt"]) &&
    d(obj["historie"])
}

export function getAnmeldungKeyPrettyName(key: keyof IAnmeldung): string {
  switch (key) {
    case "kind":
      return "Kind";
    case "aktion":
      return "Aktion";
    case "status":
      return "Status";
    case "bezahlt":
      return "Bezahlt";
    case "historie":
      return "Historie";
    default: return getAbstractModelKeyPrettyName(key);
  }
}

export function getAnmeldungSearchCategories() {
  return [
    { display: "Allgemein", key: "Allgemein", type: "CAT" },
    { key: "kind", type: "IKind" },
    { key: "aktion", type: "IAktion" },
    { key: "status", type: "string" },
    { key: "bezahlt", type: "boolean" },
    { key: "id", type: "number" },
  ].map(v => {
    if (v.display == null) {
      v.display = getAnmeldungKeyPrettyName(v.key as keyof IAnmeldung);
    }
    return v;
  });
}
