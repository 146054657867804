import {Component, Input, OnInit} from '@angular/core';
import {SessionService} from "../../service/session.service";
import {EventQueueService} from "../../service/event-queue-service.service";
import {Rolle} from "../../models/enums";
export interface SubnavItem{
  displayName: string;
  route: string;
  maOnly?: boolean;
}
@Component({
  selector: 'app-subnav',
  templateUrl: './subnav.component.html',
  styleUrls: ['./subnav.component.scss']
})
export class SubnavComponent implements OnInit {
  @Input() items: SubnavItem[];

  isMA: boolean = false;
  constructor(private readonly sessionService: SessionService,
              private readonly eventQueue: EventQueueService) {

  }

  ngOnInit(): void {
    this.isMA = this.sessionService.session?.currentPermission === Rolle.MA || this.sessionService.session?.currentPermission === Rolle.ADM;
    this.eventQueue.getObservable().subscribe(()=>{
      this.isMA = this.sessionService.session?.currentPermission === Rolle.MA || this.sessionService.session?.currentPermission === Rolle.ADM;
    })
  }

  isVisible() {
    return this.items.filter(i => !i.maOnly || this.isMA).length > 1;
  }
}
