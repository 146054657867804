import {Pipe, PipeTransform} from '@angular/core';
import {Geschlecht} from "../models/enums";

@Pipe({
  name: 'iconFilter'
})
export class IconFilterPipe implements PipeTransform {
  /**
   * Liefert CSS Klasse für das Icon oder Beschreibung für das Icon, abhängig vom Output Type
   * @param iconState Index des icons
   *    Vegiarier / Vegan:
   *      0 = Weder vegitarier, noch vegan
   *      1 = Vegitarier
   *      2 = Veganer
   *    Geschlecht:
   *      0 = Fehler
   *      1 = male
   *      2 = female
   *      3 = divers
   *    Veranstaltung:
   *      0 = Lokale
   *      1 = Übergreifende
   *      2 = REGP
   *    Rest:
   *      true / false
   * @param field Name des Feldes, desen Wert im Model ausgewertet werden soll
   * @param outputFormat 0 = CSS Klassen, 1 = Hilfetext
   */
  transform(iconState: number | boolean | string, field: string, outputFormat: number): string {
    let returnCss: string;
    let returnTitle: string;

    switch (field) {
      case 'vegetarier':
      case 'veganer':
        returnCss = iconState === 2 ? 'fa fa-seedling vegitarier' : iconState === 1 ? 'fas fa-leaf vegitarier' : 'fas fa-leaf icon_opacity vegitarier';
        returnTitle = iconState === 2 ? 'Veganer' : iconState === 1 ? 'Vegetarier' : 'Kein Veganer / Vegetarier';
        break;
      case 'geschlecht':
        returnCss = iconState === 1 ? 'fas fa-male defaultIcon' : iconState === 2 ? 'fas fa-female defaultIcon' : 'fas fa-transgender defaultIcon';
        returnTitle = iconState === 1 ? Geschlecht.MALE : iconState === 2 ? Geschlecht.FEMALE : Geschlecht.DIVERS;
        break;
      case 'handynummer':
      case 'per_telefonnummer':
        returnCss = iconState ? 'fa fa-phone phone' : 'fa fa-phone phone icon_opacity';
        returnTitle = iconState ? 'Telefon' : 'Keine Telefonnummer';
        break;
      case 'per_handynummer':
        returnCss = iconState ? 'fas fa-mobile-alt phone icon' : 'fas fa-mobile-alt phone icon icon_opacity';
        returnTitle = iconState ? 'Handy' : 'Keine Handy';
        break;
      case 'e_mail_adresse':
      case 'per_e_mail_adresse':
        returnCss = iconState ? 'far fa-envelope phone' : 'far fa-envelope phone icon_opacity';
        returnTitle = iconState ? 'E-Mail' : 'Keine E-Mail';
        break;
      case 'medizinische_hinweise':
        returnCss = iconState ? 'fas fa-pills medi' : 'fas fa-pills medi icon_opacity';
        returnTitle = iconState ? 'Medizinische Hinweise' : 'Keine medizinische Hinweise';
        break;
      case 'ernaehrungsbesonderheiten':
      case 'lebensmittelunvertraeglichkeiten':
        returnCss = iconState ? 'fas fa-balance-scale medi' : 'fas fa-balance-scale medi icon_opacity';
        returnTitle = iconState ? 'Ernährungsbesonderheiten' : 'Keine Ernährungsbesonderheiten';
        break;
      case 'laktoseintoleranz':
        returnCss = iconState ? 'fas fa-glass-whiskey laktoseintoleranz' : 'fas fa-glass-whiskey laktoseintoleranz icon_opacity';
        returnTitle = iconState ? 'Vertägt keine Laktose' : 'Kann Laktoseprodukte essen';
        break;
      case 'krankheiten':
        returnCss = iconState ? 'fas fa-notes-medical medi' : 'fas fa-notes-medical medi icon_opacity';
        returnTitle = iconState ? 'Hat Krankheiten' : 'Hat keine Krankheiten';
        break;
      case 'darf_unter_aufsicht_schwimmen':
        returnCss = iconState ? 'fas fa-swimmer laktoseintoleranz icon_opacity' : 'fas fa-swimmer laktoseintoleranz ';
        returnTitle = iconState ? 'Darf unter Aufsicht schwimmen' : 'Darf nicht unter Aufsicht schwimmen';
        break;
      case 'darf_fotografiert_werden':
        returnCss = iconState ? 'fas fa-camera defaultIcon icon_opacity' : 'fas fa-camera defaultIcon ';
        returnTitle = iconState ? 'Darf Fotografiert werden' : 'Darf nicht Fotografiert werden';
        break;

      case 'tetanus_impfung':
        returnCss = iconState ? 'fas fa-syringe medi icon_opacity' : 'fas fa-syringe medi';
        returnTitle = iconState ? 'Hat eine Tetanusimpfung' : 'Hat keine Tetanusimpfung';
        break;
      case 'darf_kanu_fahren':
        returnCss = iconState ? 'fas fa-ship laktoseintoleranz icon_opacity' : 'fas fa-ship laktoseintoleranz ';
        returnTitle = iconState ? 'Darf Kanu fahren' : 'Darf kein Kanu fahren';
        break;
      case 'unbeaufsichtigt_freizeit_erhalten':
        returnCss = iconState ? 'far fa-eye defaultIcon icon_opacity' : 'far fa-eye defaultIcon';
        returnTitle = iconState ? 'Darf unbeaufsichtigt Freizeit erhalten' : 'Darf nicht unbeaufsichtigt Freizeit erhalten';
        break;
      case 'keine_ansteckenden_krankheiten':
        returnCss = iconState ? 'fas fa-exclamation medi icon_opacity' : 'fas fa-exclamation medi ';
        returnTitle = iconState ? 'Hat keine ansteckende Krankheiten' : 'Hat ansteckende Krankheiten';
        break;
      case 'mitarbeiterstatus':
        returnCss = iconState ? 'fa fa-star star' : 'fa fa-star star icon_opacity';
        returnTitle = (!iconState ? 'Kein ' : '') + 'Mitarbeiter';
        break;
      case "veranstaltungstyp":
        returnCss = iconState == 0 ? 'fas fa-user icon userIcon' : iconState == 1 ? 'fas fa-user-friends icon userIcon' : 'fas fa-users icon userIcon';
        returnTitle = (iconState == 0 ? 'Lokale' : iconState == 1 ? 'Übergreifende' : 'REGP')+" Veranstaltung";
        break;
      default:
        returnCss = iconState ? 'fab fa-galactic-republic star' : 'fas fa-jedi star';
        returnTitle = iconState ? 'Republic' : 'Jedi';
        console.error('Icon konnte nicht gefunden werden.');
        break;
    }
    returnCss += ' icon';

    switch (outputFormat) {
      case 0:
        return returnCss;
      case 1:
        return returnTitle;
      default:
        console.error('Invalid output Format for icon filter');
        return 'Ich mach dir dein <HTML> kaputt, damit du blöder dev merkst, das was falsch ist!!!';
    }
  }
}
