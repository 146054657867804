import {getAbstractModelKeyPrettyName, IAbstractModel} from "./IAbstractModel.interface";
import {Geschlecht, Schwimmabzeichen} from "./enums";
import {IEltern} from "./IEltern.interface";
import {IAnmeldung} from "./IAnmeldung.interface";
import {IRechnung} from "./IRechnungModel";

export interface IKind extends IAbstractModel {
  vorname: string
  nachname: string
  geschlecht: Geschlecht
  strasse: string
  hausnummer: string
  ort: string
  plz: number
  zusatz: string
  geburtsdatum: number
  tel: string
  mobile: string
  soziPass: string
  soziFile: string
  krankheiten: string
  medikamente: string
  allergien: string
  schwimmabzeichen: Schwimmabzeichen
  bemerkung: string
  laktose: boolean
  vegetarier: boolean
  veganer: boolean
  eltern: IEltern
  anmeldungen: IAnmeldung[]
  aktivierungsdatum: number
  rechnungGesendet: number
  bezahlterBetrag: number
  rechnungen: IRechnung[]
}

export function isKind(obj: Object): obj is IKind {
  function d(value: string): boolean{
    return value !== undefined;
  }

  return obj &&
    d(obj["vorname"]) &&
    d(obj["nachname"]) &&
    d(obj["geschlecht"]) &&
    d(obj["strasse"]) &&
    d(obj["hausnummer"]) &&
    d(obj["ort"]) &&
    d(obj["plz"]) &&
    d(obj["zusatz"]) &&
    d(obj["geburtsdatum"]) &&
    d(obj["tel"]) &&
    d(obj["mobile"]) &&
    d(obj["soziPass"]) &&
    d(obj["soziFile"]) &&
    d(obj["krankheiten"]) &&
    d(obj["medikamente"]) &&
    d(obj["allergien"]) &&
    d(obj["schwimmabzeichen"]) &&
    d(obj["bemerkung"]) &&
    d(obj["laktose"]) &&
    d(obj["vegetarier"]) &&
    d(obj["veganer"]) &&
    d(obj["eltern"]) &&
    d(obj["anmeldungen"]) &&
    d(obj["rechnungGesendet"]) &&
    d(obj["aktivierungsdatum"]) &&
      d(obj["rechnungen"]);
}

export function getKindKeyPrettyName(key: keyof IKind): string {
  switch (key) {
    case "vorname":
      return "Vorname";
    case "nachname":
      return "Nachname";
    case "geschlecht":
      return "Geschlecht";
    case "strasse":
      return "Straße";
    case "hausnummer":
      return "Hausnummer";
    case "ort":
      return "Ort";
    case "plz":
      return "Postleitzahl";
    case "zusatz":
      return "Adresszusatz";
    case "geburtsdatum":
      return "Geburtsdatum";
    case "tel":
      return "Telefon";
    case "mobile":
      return "Handynummer";
    case "soziPass":
      return "Bildungskarten Nr.";
    case "soziFile":
      return "Bildungskarten Beleg";
    case "krankheiten":
      return "Krankheiten";
    case "medikamente":
      return "Medikamente";
    case "allergien":
      return "Allergien";
    case "schwimmabzeichen":
      return "Schwimmabzeichen";
    case "bemerkung":
      return "Bemerkungen";
    case "laktose":
      return "Laktoseintoleranz";
    case "vegetarier":
      return "Vegetarier";
    case "veganer":
      return "Veganer";
    case "eltern":
      return "Elternteil";
    case "anmeldungen":
      return "Anmeldungen";
    case "aktivierungsdatum":
      return "Aktivierungsdatum";
    case "rechnungGesendet":
      return "Aktivierungsdatum";
    case "rechnungen":
      return "Rechnungen";
    case "bezahlterBetrag":
      return "Bezahlter Betrag";
    default: return getAbstractModelKeyPrettyName(key);
  }
}

export function getKindSearchCategories() {

  return [
    {display: "Allgemein", key: "Allgemein", type: "CAT"},
    {key: "vorname", type: "string"},
    {key: "nachname", type: "string"},
    {key: "geschlecht", type: "string"},
    {key: "geburtsdatum", type: "Date"},
    {key: "soziPass", type: "boolean"},
    {key: "id", type: "number"},

    {display: "Kontakt", key: "Allgemein", type: "CAT"},
    {key: "strasse", type: "string"},
    {key: "hausnummer", type: "string"},
    {key: "ort", type: "string"},
    {key: "plz", type: "number"},
    {key: "zusatz", type: "string"},
    {key: "tel", type: "string"},
    {key: "mobile", type: "string"},

    {display: "Sonstiges", key: "Allgemein", type: "CAT"},
    {key: "krankheiten", type: "string"},
    {key: "medikamente", type: "string"},
    {key: "allergien", type: "string"},
    {key: "schwimmabzeichen", type: "string"},
    {key: "bemerkung", type: "string"},
    {key: "laktose", type: "boolean"},
    {key: "vegetarier", type: "boolean"},
    {key: "veganer", type: "boolean"},
    {key: "eltern", type: "IEltern"},

  ].map(v => {
    if (v.display == null) {
      v.display = getKindKeyPrettyName(v.key as keyof IKind);
    }
    return v;
  });
}
