<ng-container class="container" *ngIf="isMa; else noPermission">
  <h3 class="text-center m-2">{{kind.vorname}} {{kind.nachname}} buchen</h3>
  <app-filter [actionButtons]="actionButtons" [categories]="categories" [(query)]="query" (queryChange)="updateFilter()"></app-filter>
  <div class="table-frame">
    <table class="m-2 table text-center">
      <tr class="sticky-top grayBgColor">
        <th class="align-bottom">ID</th>
        <th class="align-bottom">Datum</th>
        <th class="align-bottom">Titel</th>
        <th class="align-bottom">Alter</th>
        <th class="align-bottom">Anzahl</th>
        <th *ngIf="!sozi" class="pcBorderRight align-bottom">Preis</th>
        <th *ngIf="sozi" class="pcBorderRight align-bottom">Sozialpreis</th>
        <th colspan="2">Gebucht / Warteliste | {{calcPrice()}} €</th>
      </tr>
      <tr *ngFor="let a of aktionen" [class]="getClass(a)">
        <td class="align-middle">{{a.id}}</td>
        <td class="align-middle">{{a | aktionDate:"all"}}</td>
        <td class="align-middle">{{a.titel}}</td>
        <td class="align-middle">{{a | aktionAlter}}</td>
        <td class="align-middle text-center anzahl" *ngIf="aktionList(a) as list">
          <span ngbTooltip="Voranmeldung" class="grau">{{list.vor}}</span>
          <span ngbTooltip="Angemeldet" class="blau">{{list.ang}}</span>
          <span ngbTooltip="Warteliste" class="gelb">{{list.wList}}</span>
          <span ngbTooltip="Gebucht" class="gruen">{{list.geb}}</span>
          /
          <span ngbTooltip="Max. Teilnehmer" class="primaryBgColor">{{a.maxKinder}}</span>
        </td>
        <td class="align-middle pcBorderRight" *ngIf="!sozi">{{a.preis.toFixed(2)}} €</td>
        <td class="align-middle pcBorderRight" *ngIf="sozi">{{a.soziPreis.toFixed(2)}} €</td>
        <td class="text-right primaryFontColor">
          <input type="checkbox" ngbTooltip="Gebucht" [checked]="status[a.id] === AnmeldungStatus.GEBUCHT" (click)="checkboxClicked(AnmeldungStatus.GEBUCHT, a)" [id]="'a'+a.id" class="largeCheckbox mx-3">
          <input type="checkbox" ngbTooltip="Warteliste" [checked]="status[a.id] === AnmeldungStatus.WARTELISTE" (click)="checkboxClicked(AnmeldungStatus.WARTELISTE, a)" [id]="'w'+a.id" class="largeCheckbox mx-3">
        </td>
        <td class="text-left primaryFontColor">
          <i [style.opacity]="!matchesAge(a) ? 1 : 0" class="fas fa-2x fa-user-clock mx-2" ngbTooltip="Nicht das passende Alter!"></i>
          <i [style.opacity]="isBlocked(a) ? 1 : 0" class="fas fa-2x fa-calendar-times mx-2" ngbTooltip="Terminüberschneidung!"></i>
          <i [style.opacity]="isFull(a) ? 1 : 0" class="fas fa-2x fa-users-slash mx-2" ngbTooltip="Aktion voll!"></i>
        </td>
      </tr>
      <tr class="white">
        <td colspan="5">Ferienpass</td>
        <td *ngIf="!sozi">{{passPreis.toFixed(2)}} €</td>
        <td *ngIf="sozi">{{soziPassPreis.toFixed(2)}} €</td>
        <td class="text-right">
          <input disabled type="checkbox" [checked]="hasAnmeldung()" class="largeCheckbox mx-3">
          <input disabled type="checkbox" [checked]="hasAnmeldung()" class="largeCheckbox mx-3" style="opacity: 0">
        </td>
        <td></td>
      </tr>
    </table>
    <div class="p-2">
      <span class="white">Plätze Frei</span> |
      <span class="yellow">Fast Voll</span> |
      <span class="red">Voll</span><br>
      <i class="fas fa-user-clock mx-2" ngbTooltip="Nicht das passende Alter!"></i>Nicht das passende Alter!<br>
      <i class="fas fa-calendar-times mx-2" ngbTooltip="Terminüberschneidung!"></i>Terminüberschneidung!<br>
      <i class="fas fa-users-slash mx-2" ngbTooltip="Aktion voll!"></i>Aktion voll!
    </div>
  </div>
  <div style="height:55px;"></div>
  <div class="fixed-bottom secondaryBgColor d-flex justify-content-center justify-content-lg-end">
    <button [disabled]="saveActive" class="btn pcButton m-2" (click)="saveAnmeldungen()">Speichern <i *ngIf="saveActive" class="fas fa-circle-notch fa-spin"></i></button>
  </div>
  <div *ngIf="saveActive" style="z-index: 99999999; width: 100vw; height: 100vh; top: 0; left:0; background: rgba(0,0,0,0.3)" class="position-fixed">
    <i style="top: 50%; left: 50%; transform: translate(-50%, -50%)" class="position-absolute fa-10x fas fa-circle-notch fa-spin"></i>
  </div>
</ng-container>
<ng-template #noPermission>
  Keine Berechtigung
</ng-template>
