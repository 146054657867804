import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {

  @Input() public required = false;
  @Input() public placeholder = "";
  @Input() public tooltip = "";
  @Input() public name!: string;
  @Input() public val!: any
  @Output() public valChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() public type!: "text" | "phone" | "mail" | "number" | "decimal" | "password" | "date" | "dateTime" | "iban" | "textarea"

  constructor() { }

  ngOnInit(): void {
  }

  getType(): string {
    switch (this.type) {
      case "text":
      case "mail":
        return "text";
      case "number":
      case "decimal":
      case "phone":
        return "tel";
      case "password":
        return "password";
    }
  }

  getInputMode(): string {
    switch (this.type) {
      case "text":
        return "text";
      case "mail":
        return "email";
      case "number":
        return "numeric";
      case "decimal":
        return "decimal"
      case "phone":
        return "tel";
      case "password":
        return "";
    }
  }
}
