<div class="form-group">
  <label [class.pflichtfeld]="required" class="primaryFontColor">{{name}}</label>

  <input type="text"
         class="form-control"
         [style.background-color]="!valid || !ibanChecksumValid ? '#FF7777' : ''"
         [ngModel]="valAsStr()"
         (ngModelChange)="setFromStr($event)"
         [placeholder]="placeholder"
         [ngbTooltip]="tooltip">
  <span *ngIf="!valid">Falsche Anzahl von Zeichen.</span>
  <span *ngIf="!ibanChecksumValid">IBAN hat einen Fehler.</span>

</div>
