<div class="container py-2">
  <ng-container *ngIf="anmeldungenAktiv">
    <h3>Anmeldung gespeichert</h3>
    <p>
      Sie haben Ihre Kinder erfolgreich an den jeweiligen Aktionen angemeldet. Das T.o.J.-Team wird
      die jeweiligen Anmeldungen prüfen. Per Mail erhalten Sie dann eine Anmeldungsbestätigung
      mit den Informationen, an welcher Aktionen Ihr Kind teilnehmen darf / wo es einen Wartelistenplatz bekommen hat.
      Bitte überweisen Sie innerhalb von 3 Tage den fälligen Betrag, damit Ihr Kind auch den Platz in den
      Aktionen behalten kann. Andernfalls wird der Platz neu vergeben.
    </p>
  </ng-container>
  <ng-container *ngIf="!anmeldungenAktiv">
    <h3>Voranmeldung gespeichert</h3>
    <p>
      Sie haben Ihre Kinder jetzt zu den Aktionen vorangemeldet. Ab dem {{anmeldungenDatum | datum}}
      {{anmeldungenDatum | zeit}} können Sie die Voranmeldungen zum T.o.J.-Team abschicken. Diese werden
      dann die jeweiligen Anmeldungen prüfen und Ihnen per E-Mail eine Bestätigung zukommen lassen.
      Mit dem erhalt der E-Mail sind Ihre Kinder für die jeweiligen Aktionen gebucht.
    </p>
  </ng-container>

  <table class="table">
    <tr>
      <th>ID</th>
      <th>Name</th>
      <th>Vorangemeldet</th>
      <th>Angemeldet</th>
      <th>Warteliste</th>
      <th>Gebucht</th>
      <th></th>
    </tr>
    <tr *ngFor="let k of kinder">
      <td>{{k.id}}</td>
      <td>{{k.vorname}}</td>
      <ng-container *ngIf="getAnmeldungenList(k) as list">
        <td>{{list.vorangemeldet}}</td>
        <td>{{list.angemeldet}}</td>
        <td>{{list.warteliste}}</td>
        <td>{{list.gebucht}}</td>
        <td><button *ngIf="list.angemeldet > 0" class="btn pcButton" (click)="download(k)"><i class="fas fa-download"></i></button></td>
      </ng-container>
    </tr>
  </table>

</div>
