<div class="container py-2">
  <h3>Systemeinstellungen</h3>
  <div class=" form-group">
    <app-input-date name="Hier kann man den Zeitpunkt für die Freischaltung der Anmeldung festlegen" required="true" [withTime]="true" [val]="anmeldungenDatum" (valChanged)="newTime=$event"></app-input-date>
  </div>
</div>
<div style="height:55px;"></div>
<div class="fixed-bottom secondaryBgColor d-flex justify-content-center justify-content-lg-end">
  <button class="btn pcButton m-2" (click)="save()">Speichern</button>
</div>
