import {BackendService} from "./backend.service";
import {SessionService} from "./session.service";
import {JsonOf, WriteReturnType} from "../utils/types/backendTypes";
import {AbstractService} from "./abstract.service";
import {Injectable} from "@angular/core";
import {IEltern} from "../models/IEltern.interface";

@Injectable({
  providedIn: 'root'
})
export class ElternService extends AbstractService<IEltern>{

  public fromJsonObj<T>(obj: JsonOf<T>):T{
    BackendService.updateIdToProperty(obj, "kinder", (id)=> (window as any).kindService.getById(id), true, () => (window as any).kindService.getRevision());
    return obj as unknown as T;
  }

  constructor(
    backend: BackendService,
    sessionService: SessionService) {
    super(
      backend,
      sessionService,
      "getEltern",
      "createEltern",
      "updateEltern",
      "deleteEltern"
    );
  }

  public getElternByMail(mail: string): IEltern | undefined {
    return this.items.find(e => e.mail === mail);
  }

  public async setPasswordFromRecovery(mail: string, token: string, password: string): Promise<WriteReturnType> {
    return this.backend.sendRequest("setPasswordFromRecovery", {
      password: password,
      token: token,
      mail: mail
    }, false);
  }

  sendPasswordRecoveryMail(email: string) {
    return this.backend.sendRequest("sendPasswordRecoveryMail", {
      email
    }, false);
  }

  public async activate(token:string){
    return this.backend.sendRequest("activate",{
      token
    },false);
  }
}
