import { Component, OnInit } from '@angular/core';
import {ElternService} from "../../service/eltern.service";
import {getElternSearchCategories, IEltern} from "../../models/IEltern.interface";
import {EventQueueService} from "../../service/event-queue-service.service";
import {SUBNAV_SETTNGS} from "../../app-routing.module";
import {FilterEngineService} from "../../service/filter-engine.service";
import {IKind} from "../../models/IKind.interface";
import {KindService} from "../../service/kind.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ConfirmWindowComponent} from "../../utils/confirm-window/confirm-window.component";

@Component({
  selector: 'app-eltern-list',
  templateUrl: './eltern-list.component.html',
  styleUrls: ['./eltern-list.component.scss']
})
export class ElternListComponent implements OnInit {

  eltern!: IEltern[];
  subnav = SUBNAV_SETTNGS;
  query = "";
  categories = getElternSearchCategories();

  deleteKindActiveId: null | number = null;
  deleteElternActiveId: null | number = null;

  constructor(private readonly elternService: ElternService,
              private readonly eventQueue: EventQueueService,
              private readonly kinderService: KindService,
              private readonly dialogService: NgbModal,
              private readonly filterService: FilterEngineService) {
  }

  async ngOnInit(): Promise<void> {
    this.eltern = await this.filterService.filter(this.query,this.elternService.getList(), "elternListInit");
    this.eventQueue.getObservable().subscribe(async msg=>{
      if(msg === "initDone"){
        this.eltern = await this.filterService.filter(this.query,this.elternService.getList(), "elternListRefresh");
      }
    })
  }

  async updateFilter():Promise<void>{
    this.eltern = await this.filterService.filter(this.query,this.elternService.getList(), "elternListInputChange");
  }

  async deleteKind(kind:IKind):Promise<void>{
    const dialog = this.dialogService.open(ConfirmWindowComponent);
    dialog.componentInstance.text = "Wollen Sie wirklich das Kind "+ kind.vorname + " " + kind.nachname +" löschen?";
    dialog.componentInstance.titel = "Kind Löschen?";
    const result = await dialog.result
    if(result){
      this.deleteKindActiveId = kind.id;
      this.kinderService.delete(kind);
    }
  }


  async deleteEltern(eltern:IEltern):Promise<void>{
    const dialog = this.dialogService.open(ConfirmWindowComponent);
    dialog.componentInstance.text = "Wollen Sie wirklich den Account "+ eltern.vorname + " " + eltern.nachname +" löschen?";
    dialog.componentInstance.titel = "Account Löschen?";
    const result = await dialog.result
    if(result){
      this.deleteElternActiveId = eltern.id;
      this.elternService.delete(eltern);
    }
  }



}
