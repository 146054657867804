import {Injectable} from '@angular/core';
//@ts-ignore
import {Content, PageSize, TCreatedPdf, TDocumentDefinitions} from 'pdfmake/build/pdfmake';
import {TimePickerService} from './time-picker-service.service';
import {IKind} from "../models/IKind.interface";
import {AgeFilterPipe} from "../filters/age-filter.pipe";
import {DatumPipe} from "../filters/datum.pipe";
import {AnmeldungStatus} from "../models/enums";
import {AktionService} from "./aktion.service";
import {AktionDatePipe} from "../filters/aktion-date.pipe";
import {IAktion} from "../models/IAktion.interface";
import {PASS_PREIS, SOZI_PASS_PREIS} from "../models/IAnmeldung.interface";
import {IRechnung} from "../models/IRechnungModel";


declare const createPdf: (
  documentDefinitions: TDocumentDefinitions,
  tableLayouts?: any,
  fonts?: any,
  vfs?: any
) => TCreatedPdf;

@Injectable({
  providedIn: 'root'
})
export class PdfGeneratorService {
  private readonly docW = 595;
  private readonly docH = 841.5;
  private readonly cx = x => x / 100 * this.docW;
  private readonly cy = y => y / 100 * this.docH;
  private readonly xmm = x => x / 210 * this.docW;
  private readonly ymm = y => y / 297 * this.docH;

  constructor(
    private readonly timePickerService: TimePickerService,
    private readonly agePipe: AgeFilterPipe,
    private readonly datumPipe: DatumPipe,
    private readonly aktionService: AktionService,
    private readonly aktionDatePipe: AktionDatePipe
  ) {
  }

  public async generateRechnung(kind: IKind): Promise<IRechnung>{

    const anmeldungen = kind.anmeldungen.filter(a => a.status === AnmeldungStatus.GEBUCHT || a.status === AnmeldungStatus.WARTELISTE);
    anmeldungen.sort((a,b) => a.aktion.start - b.aktion.start);
    let betrag = anmeldungen.filter(a => a.status === AnmeldungStatus.GEBUCHT).reduce((sum, a) => (kind.soziPass ? a.aktion.soziPreis : a.aktion.preis) + sum, 0 );
    betrag = betrag > 0 ? betrag + (kind.soziPass? SOZI_PASS_PREIS : PASS_PREIS) : 0;
    if(anmeldungen.length === 0){
      throw new Error("Kind hat keine Anmeldungen (Gebucht / Warteliste)!");
    }
    const rechnung: IRechnung = {
      id: -1,
      created: -1,
      edited: -1,
      betrag,
      pdf: undefined,
      status: "erstellt",
      kind: kind,
    }





    const rgNummer: string = ""+Date.now() + "-" + kind.id;
    const cx = this.cx, cy = this.cy, xmm = this.xmm, ymm = this.ymm;
    const pdf = createPdf({
      content: [
        {
          text: `Buchungsbestätigung Ferienpass ` + new Date().getFullYear(),
          fontSize: 14,
          bold: true,
          color: "#000",
          margin: [0, cx(0), 0, cx(0)],
          style: {alignment: 'center'}
        },
        {
          text: `für das Kind ${kind.vorname} ${kind.nachname}`,
          fontSize: 12,
          bold: false,
          color: "#000",
          style: {alignment: 'center'},
          margin: [0, cx(0), 0, cx(2)]
        },
        {
          text: `${kind.eltern.vorname} ${kind.eltern.nachname}`,
          fontSize: 12,
          bold: false,
          color: "#000",
          style: {alignment: 'left'},
          margin: [0, cx(0), 0, cx(0)]
        },
        {
          text: `${kind.eltern.strasse} ${kind.eltern.hausnummer}`,
          fontSize: 12,
          bold: false,
          color: "#000",
          style: {alignment: 'left'},
          margin: [0, cx(0), 0, cx(0)]
        },
        {
          text: `${kind.eltern.plz} ${kind.eltern.ort}`,
          fontSize: 12,
          bold: false,
          color: "#000",
          style: {alignment: 'left'},
          margin: [0, cx(0), 0, cx(2)]
        },
        {
          text: "Datum: " + new Date().getDate()+ "."+ (new Date().getMonth() + 1) + "." + new Date().getFullYear(),
          fontSize: 12,
          bold: false,
          color: "#000",
          style: {alignment: 'left'},
          margin: [0, cx(0), 0, cx(0)]
        },
        {
          text: "Rechnungsnummer: " + rgNummer,
          fontSize: 12,
          bold: false,
          color: "#000",
          style: {alignment: 'left'},
          margin: [0, cx(0), 0, cx(2)]
        },
        {
          margin: [0, cx(0), 0, cx(0)],
          table: {
            headerRows: 1,
            dontBreakRows: true,
            widths: [130, '*', 50, 40, 35],
            style: "tableExample",
            body: [
              [
                {
                  text: `Datum`,
                  fontSize: 10,
                  bold: true,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [true, true, true, true]
                },
                {
                  text: `Aktion`,
                  fontSize: 10,
                  bold: true,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [true, true, true, true]
                },
                {
                  text: `Alter`,
                  fontSize: 10,
                  bold: true,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [true, true, true, true]
                },
                {
                  text: `Kosten${kind.soziPass ? '*' : ''}`,
                  fontSize: 10,
                  bold: true,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [true, true, true, true]
                },
                {
                  text: `angem.`,
                  fontSize: 10,
                  bold: true,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [true, true, true, true]
                },
              ],
              ...anmeldungen.map(a => {
                return [{
                  text: `${this.aktionDatePipe.transform(a.aktion, "all")}`,
                  fontSize: 10,
                  bold: false,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [true, true, true, true]
                },
                  {
                    text: `${a.aktion.titel}`,
                    fontSize: 10,
                    bold: false,
                    color: "#000",
                    style: {alignment: 'left:'},
                    border: [true, true, true, true]
                  },
                  {
                    text: `${a.aktion.maxAlter === 99 ? ('Ab ' + a.aktion.minAlter) : (a.aktion.minAlter + " - " + a.aktion.maxAlter)} J.`,
                    fontSize: 10,
                    bold: false,
                    color: "#000",
                    style: {alignment: 'left:'},
                    border: [true, true, true, true]
                  },
                  {
                    text: a.status === AnmeldungStatus.GEBUCHT? ((kind.soziPass ? a.aktion.soziPreis : a.aktion.preis).toFixed(2) +" €") : ("---"),
                    fontSize: 10,
                    bold: false,
                    color: "#000",
                    style: {alignment: 'left:'},
                    border: [true, true, true, true]
                  },
                  {
                    text: `${this.anmeldungStatus(kind, a.aktion)}`,
                    fontSize: 10,
                    bold: false,
                    color: "#000",
                    style: {alignment: 'left:'},
                    border: [true, true, true, true]
                  }
                ]
              }),
              [
                {
                  text: ``,
                  fontSize: 10,
                  bold: false,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [false, false, false, false]
                },
                {
                  text: `Ferienpass`,
                  fontSize: 10,
                  bold: false,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [true, true, false, true]
                },
                {
                  text: ``,
                  fontSize: 10,
                  bold: false,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [false, true, true, true]
                },
                {
                  text: betrag === 0 ? "---" : ((kind.soziPass ? SOZI_PASS_PREIS : PASS_PREIS).toFixed(2) +" €"),
                  fontSize: 10,
                  bold: false,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [true, true, true, true]
                },
                {
                  text: betrag === 0 ? "":"G",
                  fontSize: 10,
                  bold: false,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [true, true, true, true]
                },
              ],
              [
                {
                  text: ``,
                  fontSize: 10,
                  bold: false,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [false, false, false, false]
                },
                {
                  text: `Summe`,
                  fontSize: 10,
                  bold: false,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [true, true, false, true]
                },
                {
                  text: ``,
                  fontSize: 10,
                  bold: false,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [false, true, true, true]
                },
                {
                  text: `${betrag.toFixed(2)} €`,
                  fontSize: 10,
                  bold: false,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [true, true, true, true]
                },
                {
                  text: ``,
                  fontSize: 10,
                  bold: false,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [false, false, false, false]
                },
              ]
            ]
          },
        },
        {
          text: `${kind.soziPass ? '* Für das Kind wird der Sozialpreis berechnet.': ''}`,
          fontSize: 9,
          bold: false,
          color: "#000",
          style: {alignment: 'left'},
          margin: [0, cx(2), 0, cx(0)]
        },
        {
          text: `G: Gebucht | W: Warteliste`,
          fontSize: 9,
          bold: false,
          color: "#000",
          style: {alignment: 'left'},
          margin: [0, cx(2), 0, cx(0)]
        },
        {
          text: `Dein Kind ist ab sofort bei den Aktionen mit G gekennzeichnet vorläufig angemeldet. Die Anmeldung wird verbindlich, sobald der zu zahlende Teilnehmerbetrag auf unser Konto eingegangen ist. Dies musst du bitte innerhalb von drei Werktagen erledigt haben. Als Verwendungszweck gibst du bitte folgendes an: ${kind.id}.`,
          fontSize: 12,
          bold: false,
          color: "#000",
          style: {alignment: 'left'},
          margin: [0, cx(2), 0, cx(0)]
        },
        {
          text: `Trägerverein offene Jugendarbeit e.V.`,
          fontSize: 12,
          bold: false,
          color: "#000",
          style: {alignment: 'left'},
          margin: [0, cx(2), 0, cx(0)]
        },
        {
          text: `VR Bank Westküste `,
          fontSize: 12,
          bold: false,
          color: "#000",
          style: {alignment: 'left'},
          margin: [0, cx(0), 0, cx(0)]
        },
        {
          text: `DE29 2176 2550 0010 1581 51 `,
          fontSize: 12,
          bold: false,
          color: "#000",
          style: {alignment: 'left'},
          margin: [0, cx(0), 0, cx(0)]
        },
        {
          text: `BIC: GENO DE F1 HUM `,
          fontSize: 12,
          bold: false,
          color: "#000",
          style: {alignment: 'left'},
          margin: [0, cx(0), 0, cx(0)]
        },
      ],
      pageMargins: [cx(10), cy(10), cx(10), cy(10)]
    });

    rechnung.pdf = await new Promise((resolve) => {
      pdf.getBase64(resolve);
    });

    return rechnung;
  }

  public async downloadKinderliste(aktion: IAktion): Promise<void>{
    const gebuchteKinder = aktion.anmeldungen.filter(a => a.status === AnmeldungStatus.GEBUCHT).sort((a,b)=> a.edited - b.edited);
    const warteliste = aktion.anmeldungen.filter(a => a.status === AnmeldungStatus.WARTELISTE).sort((a,b) => a.edited - b.edited);
    const cx = this.cy, cy = this.cx, xmm = this.ymm, ymm = this.xmm;
    createPdf({
      content: [
        {
          text: `Trägerverein offene Jugendarbeit e.V.`,
          fontSize: 14,
          bold: true,
          color: "#000",
          margin: [0, cx(0), 0, cx(0)],
          style: {alignment: 'left'}
        },
        {
          text: `Teilnehmerliste für `+aktion.titel+` `+ new Date().getFullYear(),
          fontSize: 12,
          bold: true,
          color: "#000",
          margin: [0, cx(0), 0, cx(0)],
          style: {alignment: 'left'}
        },
        {
          text: `Datum: `+ this.getDatumString(aktion.start) + " " + this.getZeitString(aktion.start) + " - "+ this.getDatumString(aktion.ende) + " " + this.getZeitString(aktion.ende),
          fontSize: 10,
          bold: false,
          color: "#000",
          margin: [0, cx(1), 0, cx(0)],
          style: {alignment: 'left'}
        },
        {
          text: `Ort: ${aktion.ort}`,
          fontSize: 10,
          bold: false,
          color: "#000",
          margin: [0, cx(0), 0, cx(0)],
          style: {alignment: 'left'}
        },
        {
          text: `Veranstalter: ${aktion.veranstalter}`,
          fontSize: 10,
          bold: false,
          color: "#000",
          margin: [0, cx(0), 0, cx(0)],
          style: {alignment: 'left'}
        },
        {
          margin: [0, cx(1), 0, cx(0)],
          table: {
            headerRows: 1,
            dontBreakRows: true,
            widths: [15, '*', 75, '*', '*',35,'*','*'],
            style: "tableExample",
            body: [[
              {
                text: `ID`,
                fontSize: 10,
                bold: true,
                color: "#000",
                style: {alignment: 'center'},
                border: [true, true, true, true]
              },
              {
                text: `Name`,
                fontSize: 10,
                bold: true,
                color: "#000",
                style: {alignment: 'center'},
                border: [true, true, true, true]
              },
              {
                text: `Ort`,
                fontSize: 10,
                bold: true,
                color: "#000",
                style: {alignment: 'center'},
                border: [true, true, true, true]
              },
              {
                text: `Tel.`,
                fontSize: 10,
                bold: true,
                color: "#000",
                style: {alignment: 'center'},
                border: [true, true, true, true]
              },
              {
                text: `Mobil`,
                fontSize: 10,
                bold: true,
                color: "#000",
                style: {alignment: 'center'},
                border: [true, true, true, true]
              },
              {
                text: `Alter`,
                fontSize: 10,
                bold: true,
                color: "#000",
                style: {alignment: 'center'},
                border: [true, true, true, true]
              },
              {
                text: `Info`,
                fontSize: 10,
                bold: true,
                color: "#000",
                style: {alignment: 'center'},
                border: [true, true, true, true]
              },
              {
                text: `Schwimmabzeichen`,
                fontSize: 10,
                bold: true,
                color: "#000",
                style: {alignment: 'center'},
                border: [true, true, true, true]
              }
            ], ...gebuchteKinder.map(a => {
              return [
                  {
                  text: a.kind.id,
                  fontSize: 9,
                  bold: false,
                  color: "#000",
                  style: {alignment: 'center'},
                  border: [true, true, true, true]
                  },
                {
                  text: a.kind.vorname +" "+a.kind.nachname,
                  fontSize: 9,
                  bold: false,
                  color: "#000",
                  style: {alignment: 'left'},
                  border: [true, true, true, true]
                },
                {
                  text: a.kind.ort,
                  fontSize: 9,
                  bold: false,
                  color: "#000",
                  style: {alignment: 'left'},
                  border: [true, true, true, true]
                },
                {
                  text: a.kind.eltern.telefon? a.kind.eltern.telefon : "K: "+a.kind.tel,
                  fontSize: 9,
                  bold: false,
                  color: "#000",
                  style: {alignment: 'left'},
                  border: [true, true, true, true]
                },
                {
                  text: a.kind.eltern.mobile? a.kind.eltern.mobile: "K: "+a.kind.mobile,
                  fontSize: 9,
                  bold: false,
                  color: "#000",
                  style: {alignment: 'left'},
                  border: [true, true, true, true]
                },
                {
                  text: this.agePipe.transform(a.kind.geburtsdatum),
                  fontSize: 9,
                  bold: false,
                  color: "#000",
                  style: {alignment: 'center'},
                  border: [true, true, true, true]
                },
                {
                  text: a.kind.bemerkung === "null"? "":a.kind.bemerkung,
                  fontSize: 9,
                  bold: false,
                  color: "#000",
                  style: {alignment: 'left'},
                  border: [true, true, true, true]
                },
                {
                  text: a.kind.schwimmabzeichen,
                  fontSize: 9,
                  bold: false,
                  color: "#000",
                  style: {alignment: 'center'},
                  border: [true, true, true, true]
                }
              ]
            }),
                [
                  {
                    text: "Warteliste",
                    fontSize: 10,
                    bold: true,
                    color: "#000",
                    style: {alignment: 'center'},
                    border: [true, true, true, true],
                    colSpan:8
                  },
                ],...warteliste.map(a => {
                return [
                  {
                    text: a.kind.id,
                    fontSize: 9,
                    bold: false,
                    color: "#000",
                    style: {alignment: 'center'},
                    border: [true, true, true, true]
                  },
                  {
                    text: a.kind.vorname +" "+a.kind.nachname,
                    fontSize: 9,
                    bold: false,
                    color: "#000",
                    style: {alignment: 'left'},
                    border: [true, true, true, true]
                  },
                  {
                    text: a.kind.ort,
                    fontSize: 9,
                    bold: false,
                    color: "#000",
                    style: {alignment: 'left'},
                    border: [true, true, true, true]
                  },
                  {
                    text: a.kind.tel,
                    fontSize: 9,
                    bold: false,
                    color: "#000",
                    style: {alignment: 'left'},
                    border: [true, true, true, true]
                  },
                  {
                    text: a.kind.mobile,
                    fontSize: 9,
                    bold: false,
                    color: "#000",
                    style: {alignment: 'left'},
                    border: [true, true, true, true]
                  },
                  {
                    text: this.agePipe.transform(a.kind.geburtsdatum),
                    fontSize: 9,
                    bold: false,
                    color: "#000",
                    style: {alignment: 'center'},
                    border: [true, true, true, true]
                  },
                  {
                    text: a.kind.bemerkung === "null"? "":a.kind.bemerkung,
                    fontSize: 9,
                    bold: false,
                    color: "#000",
                    style: {alignment: 'left'},
                    border: [true, true, true, true]
                  },
                  {
                    text: a.kind.schwimmabzeichen,
                    fontSize: 9,
                    bold: false,
                    color: "#000",
                    style: {alignment: 'center'},
                    border: [true, true, true, true]
                  }
                ]
              }),
            ]
          }
        }
      ],
      pageMargins: [cx(5), cy(5), cx(5), cy(5)],
      pageOrientation: 'landscape'
        }).download("Teilnehmerliste-"+aktion.id+".pdf");
  }

  public async downloadMediListe(aktion: IAktion): Promise<void> {
    const gebuchteKinder = aktion.anmeldungen.filter(a => {

      if(a.status !== AnmeldungStatus.GEBUCHT){
        return false;
      }
      if(a.kind.allergien !== null && a.kind.allergien?.length !== 0 && a.kind.allergien !== "null"){
        return true;
      }

      if(a.kind.bemerkung !== null && a.kind.bemerkung?.length !== 0 && a.kind.bemerkung !== "null"){
       return true;
      }

      if(a.kind.medikamente !== null && a.kind.medikamente?.length !== 0 && a.kind.medikamente !== "null"){
        return true;
      }
      if(a.kind.krankheiten !== null && a.kind.krankheiten?.length !==0 && a.kind.krankheiten !== "null"){
        return true;
      }

      return false
    }).sort((a, b) => a.edited - b.edited);
    const cx = this.cy, cy = this.cx, xmm = this.ymm, ymm = this.xmm;
    createPdf({
      content: [
        {
          text: `Trägerverein offene Jugendarbeit e.V.`,
          fontSize: 14,
          bold: true,
          color: "#000",
          margin: [0, cx(0), 0, cx(0)],
          style: {alignment: 'left'}
        },
        {
          text: `Medikamentenliste für ` + aktion.titel + ` ` + new Date().getFullYear(),
          fontSize: 12,
          bold: true,
          color: "#000",
          margin: [0, cx(0), 0, cx(0)],
          style: {alignment: 'left'}
        },
        {
          text: `Datum: ` + this.getDatumString(aktion.start) + " " + this.getZeitString(aktion.start) + " - " + this.getDatumString(aktion.ende) + " " + this.getZeitString(aktion.ende),
          fontSize: 10,
          bold: false,
          color: "#000",
          margin: [0, cx(1), 0, cx(0)],
          style: {alignment: 'left'}
        },
        {
          text: `Ort: ${aktion.ort}`,
          fontSize: 10,
          bold: false,
          color: "#000",
          margin: [0, cx(0), 0, cx(0)],
          style: {alignment: 'left'}
        },
        {
          text: `Veranstalter: ${aktion.veranstalter}`,
          fontSize: 10,
          bold: false,
          color: "#000",
          margin: [0, cx(0), 0, cx(3)],
          style: {alignment: 'left'}
        }, ... gebuchteKinder.map(a=>{
          return [
              {
                columns:[
                  {
                    text: a.kind.id,
                    fontSize: 10,
                    bold: false,
                    color: "#000",
                    margin: [0, cx(0), 0, cx(0)],
                    style: {alignment: 'left'},
                    width: 100
                  },
                  {
                    text: a.kind.vorname + " " + a.kind.nachname,
                    fontSize: 10,
                    bold: false,
                    color: "#000",
                    margin: [0, cx(0), 0, cx(0)],
                    style: {alignment: 'left'},
                    width: "*"
                  }
                ],
                columnGap:10
              },
            {
              columns:[
                {
                  text: "Allergien:",
                  fontSize: 10,
                  bold: false,
                  color: "#000",
                  margin: [0, cx(0), 0, cx(0)],
                  style: {alignment: 'left'},
                  width: 100
                },
                {
                  text: a.kind.allergien,
                  fontSize: 10,
                  bold: false,
                  color: "#000",
                  margin: [0, cx(0), 0, cx(0)],
                  style: {alignment: 'left'},
                  width: "*"
                }
              ],
              columnGap:10
            },
            {
              columns:[
                {
                  text: "Medikamente:",
                  fontSize: 10,
                  bold: false,
                  color: "#000",
                  margin: [0, cx(0), 0, cx(0)],
                  style: {alignment: 'left'},
                  width: 100
                },
                {
                  text: a.kind.medikamente,
                  fontSize: 10,
                  bold: false,
                  color: "#000",
                  margin: [0, cx(0), 0, cx(0)],
                  style: {alignment: 'left'},
                  width: "*"
                }
              ],
              columnGap:10
            },
            {
              columns:[
                {
                  text: "Krankeiten:",
                  fontSize: 10,
                  bold: false,
                  color: "#000",
                  margin: [0, cx(0), 0, cx(0)],
                  style: {alignment: 'left'},
                  width: 100
                },
                {
                  text: a.kind.krankheiten,
                  fontSize: 10,
                  bold: false,
                  color: "#000",
                  margin: [0, cx(0), 0, cx(0)],
                  style: {alignment: 'left'},
                  width: "*"
                }
              ],
              columnGap:10
            },
            {
              columns:[
                {
                  text: "Info:",
                  fontSize: 10,
                  bold: false,
                  color: "#000",
                  margin: [0, cx(0), 0, cx(0)],
                  style: {alignment: 'left'},
                  width: 100
                },
                {
                  text: a.kind.bemerkung,
                  fontSize: 10,
                  bold: false,
                  color: "#000",
                  margin: [0, cx(0), 0, cx(0)],
                  style: {alignment: 'left'},
                  width: "*"
                }
              ],
              columnGap:10,
              margin: [0,0,0,cx(3)]
            }
          ];
        })
        ]
    }).download("MediListe-"+aktion.id+".pdf");
  }


  public async downloadAnmeldungen(kind: IKind): Promise<void> {
    const cx = this.cx, cy = this.cy, xmm = this.xmm, ymm = this.ymm;
    createPdf({
      content: [
        {
          text: `Übersicht Ferienpass ` + new Date().getFullYear(),
          fontSize: 14,
          bold: true,
          color: "#000",
          margin: [0, cx(0), 0, cx(0)],
          style: {alignment: 'center'}
        },
        {
          text: `${kind.vorname} ${kind.nachname}`,
          fontSize: 12,
          bold: false,
          color: "#000",
          style: {alignment: 'center'},
          margin: [0, cx(0), 0, cx(2)]
        },
        {
          margin: [0, cx(0), 0, cx(0)],
          table: {
            headerRows: 1,
            dontBreakRows: true,
            widths: ['*', '*', '*', '*', '*'],
            style: "tableExample",
            body: [
              [
                {
                  text: `Datum`,
                  fontSize: 10,
                  bold: true,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [true, true, true, true]
                },
                {
                  text: `Aktion`,
                  fontSize: 10,
                  bold: true,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [true, true, true, true]
                },
                {
                  text: `Alter`,
                  fontSize: 10,
                  bold: true,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [true, true, true, true]
                },
                {
                  text: `Kosten${kind.soziPass ? '*' : ''}`,
                  fontSize: 10,
                  bold: true,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [true, true, true, true]
                },
                {
                  text: `angem.`,
                  fontSize: 10,
                  bold: true,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [true, true, true, true]
                },
              ],
              ...this.aktionService.getList().filter(a => a.aktiv).map(a => {
                return [{
                  text: `${this.aktionDatePipe.transform(a, "all")}`,
                  fontSize: 10,
                  bold: false,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [true, true, true, true]
                },
                  {
                    text: `${a.titel}`,
                    fontSize: 10,
                    bold: false,
                    color: "#000",
                    style: {alignment: 'left:'},
                    border: [true, true, true, true]
                  },
                  {
                    text: `${a.maxAlter === 99 ? ('Ab ' + a.minAlter) : (a.minAlter + " - " + a.maxAlter)} J.`,
                    fontSize: 10,
                    bold: false,
                    color: "#000",
                    style: {alignment: 'left:'},
                    border: [true, true, true, true]
                  },
                  {
                    text: `${(kind.soziPass ? a.soziPreis : a.preis).toFixed(2)} €`,
                    fontSize: 10,
                    bold: false,
                    color: "#000",
                    style: {alignment: 'left:'},
                    border: [true, true, true, true]
                  },
                  {
                    text: `${this.anmeldungStatus(kind, a)}`,
                    fontSize: 10,
                    bold: false,
                    color: "#000",
                    style: {alignment: 'left:'},
                    border: [true, true, true, true]
                  }
                ]
              }),
              [
                {
                  text: ``,
                  fontSize: 10,
                  bold: false,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [false, false, false, false]
                },
                {
                  text: `Ferienpass`,
                  fontSize: 10,
                  bold: false,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [true, true, false, true]
                },
                {
                  text: ``,
                  fontSize: 10,
                  bold: false,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [false, true, true, true]
                },
                {
                  text: `${(kind.soziPass ? SOZI_PASS_PREIS : PASS_PREIS).toFixed(2)} €`,
                  fontSize: 10,
                  bold: false,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [true, true, true, true]
                },
                {
                  text: `x`,
                  fontSize: 10,
                  bold: false,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [true, true, true, true]
                },
              ],
              [
                {
                  text: ``,
                  fontSize: 10,
                  bold: false,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [false, false, false, false]
                },
                {
                  text: `Summe`,
                  fontSize: 10,
                  bold: false,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [true, true, false, true]
                },
                {
                  text: ``,
                  fontSize: 10,
                  bold: false,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [false, true, true, true]
                },
                {
                  text: `${this.calcPreis(kind).toFixed(2)} €`,
                  fontSize: 10,
                  bold: false,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [true, true, true, true]
                },
                {
                  text: ``,
                  fontSize: 10,
                  bold: false,
                  color: "#000",
                  style: {alignment: 'left:'},
                  border: [false, false, false, false]
                },
              ]
            ]
          },
        },
        {
          text: `${kind.soziPass ? '* Für das Kind wird der Sozialpreis berechnet.': ''}`,
          fontSize: 9,
          bold: false,
          color: "#000",
          style: {alignment: 'left'},
          margin: [0, cx(2), 0, cx(0)]
        },
        {
          text: `G: Gebucht | W: Warteliste | X: Angemeldet | V: Vorangemeldet`,
          fontSize: 9,
          bold: false,
          color: "#000",
          style: {alignment: 'left'},
          margin: [0, cx(2), 0, cx(0)]
        },
      ],
      pageMargins: [cx(10), cy(10), cx(10), cy(10)]
    }).download();
  }

  private calcPreis(kind: IKind): number {
    const sozi = !!kind.soziPass;
    let price = 0;
    for(const a of kind.anmeldungen) {
      if(a.status === AnmeldungStatus.ANGEMELDET) {
        price += sozi ? a.aktion.soziPreis : a.aktion.preis;
      }
    }
    price += sozi ? SOZI_PASS_PREIS : PASS_PREIS;
    return price;
  }

  private anmeldungStatus(kind: IKind, aktion: IAktion): string {
    for(const a of kind.anmeldungen) {
      if(a.aktion.id === aktion.id) {
        switch (a.status) {
          case AnmeldungStatus.GEBUCHT:
            return 'G'
          case AnmeldungStatus.WARTELISTE:
            return 'W'
          case AnmeldungStatus.ANGEMELDET:
            return 'X'
          case AnmeldungStatus.VORANGEMELDET:
            return 'V'
        }
        return '';
      }
    }
    return '';
  }

  private getDatumString(t: number): string{
    return this.makeTwoNumbers(new Date(t).getDate()) + "." + this.makeTwoNumbers(new Date(t).getMonth()+1) + "." + new Date(t).getDate();
  }

  private getZeitString(t: number): string{
    return this.makeTwoNumbers(new Date(t).getHours()) + ":" + this.makeTwoNumbers(new Date(t).getSeconds());
  }

  private makeTwoNumbers(n: number):string{

    if(n<10){
      return "0"+n;
    }
    return ""+n;
  }

  //
  // public generateAnmeldung(event: IVeranstaltung, stamm: IStamm, prefilledTns?: ITeilnehmer[]): TCreatedPdf {
  //   if(!prefilledTns) {
  //     return this.generateAnmeldungEmpty(event, stamm);
  //   }
  //
  //   const contents = prefilledTns.map((tn, index) => this.generateContent(event, stamm, tn, index === 0));
  //
  //   const combined = [];
  //   contents.forEach(c => combined.push(...c));
  //
  //   const cx = this.cx, cy = this.cy, xmm = this.xmm, ymm = this.ymm;
  //   const pdf = createPdf({
  //     background: this.generateBackground(stamm),
  //     footer: this.generateFooterAnmeldung(stamm, event),
  //     content: combined,
  //     pageMargins: [cx(10), cy(7), cx(27), cy(10)]
  //   });
  //
  //   return pdf;
  // }

//   public generateContent(event: IVeranstaltung, stamm: IStamm, tn: ITeilnehmer, first: boolean): any {
//     const cx = this.cx, cy = this.cy, xmm = this.xmm, ymm = this.ymm;
//
//     const content = [
//       { text: `Anmeldebogen zur Veranstaltung`, fontSize: 20, bold: true, color: "#000", margin: [0, cx(0), 0, cx(0)], style: { alignment: 'center' } },
//       { text: `${event.titel}`, fontSize: 16, italic: true, color: "#000", margin: [0, cx(0), 0, cx(4)], style: { alignment: 'center' } },
//       { text: `Informationen zur Veranstaltung:`, fontSize: 12, bold: true, color: "#000", style: { alignment: 'left' } },
//       {
//         table: {
//           headerRows: 0,
//           dontBreakRows: true,
//           widths: ['*', '*'],
//           style: "tableExample",
//           body: [[
//             {
//               text: `Beginn: ${this.timePickerService.toDateString(event.anfangsdatum)}`,
//               fontSize: 10, bold: false, color: "#000", style: { alignment: 'left:' }, border: [false, false, false, false]
//             },
//             {
//               text: `Ende: ${this.timePickerService.toDateString(event.enddatum)}`,
//               fontSize: 10, bold: false, color: "#000", style: { alignment: 'left:' }, border: [false, false, false, false]
//             }
//           ],
//           [
//             {
//               text: `Ort: ${event.ort}`,
//               fontSize: 10, bold: false, color: "#000", style: { alignment: 'left:' }, border: [false, false, false, false]
//             },
//             {
//               text: `Anmeldeschluss: ${this.timePickerService.toDateString(event.anmeldeschluss)}`,
//               fontSize: 10, bold: false, color: "#000", style: { alignment: 'left:' }, border: [false, false, false, false]
//             }
//           ],
//           [
//             {
//               text: `Preis: ${event.preis.toFixed(2)} €`,
//               fontSize: 10, bold: false, color: "#000", style: { alignment: 'left:' }, border: [false, false, false, false]
//             },
//             {
//               text: `Veranstalter: ${event.veranstalter.name}`,
//               fontSize: 10, bold: false, color: "#000", style: { alignment: 'left:' }, border: [false, false, false, false]
//             }
//           ]]
//         }
//       },
//       { text: `Hiermit melde ich/melden wir unseren Sohn/unsere Tochter zur Veranstaltung "${event.titel}" verbindlich an.`, fontSize: 10, bold: false, color: "#000", margin: [0, cx(1), 0, cx(1)], style: { alignment: 'left' } },
//       ,
//       ...this.generateFormFields(event, tn)
//       ,
//       {
//         text: `Zustimmung durch den Unterzeichner:`, fontSize: 12, bold: true, color: "#000", style: { alignment: 'left' }, margin: [0, cy(2), 0, cx(2)]
//       },
//       {
//         text: `Hiermit versichert sich der Unterzeichnende der Erhebung und der Verarbeitung seiner Daten und des Teilnehmers ` +
//           `für den er erziehungsberechtigt ist durch die Urban-System als Dienstleister des Ring Evangelischer Gemeindepfadfinder` +
//           `(REGP) zuzustimmen und über seine Rechte belehrt worden zu sein. Alle Informationen zum Datenschutz finden Sie unter anmeldung.regp.de/datenschutz.`,
//         fontSize: 10, bold: false, color: "#000", margin: [0, cx(0), 0, cx(0)], style: { alignment: 'left' }
//       },
//       {
//         text: "__________________________________________________________", fontSize: 10, bold: false, color: "#000", margin: [0, cx(4), 0, cx(0)], style: { alignment: 'left' }
//       },
//       {
//         text: "(Datum, Unterschrift)", fontSize: 10, bold: false, color: "#000", margin: [0, cx(0), 0, cx(0)], style: { alignment: 'left' },
//       }
//     ];
//
//     if(!first) {
//       //@ts-ignore
//       content[0].pageBreak = "before";
//     }
//     return content;
//   }
//
//   public generateAnmeldungEmpty(event: IVeranstaltung, stamm: IStamm): TCreatedPdf {
//     const cx = this.cx, cy = this.cy, xmm = this.xmm, ymm = this.ymm;
//
//     const pfd = createPdf({
//       background: this.generateBackground(stamm),
//       footer: this.generateFooterAnmeldung(stamm, event),
//       content: [
//         { text: `Anmeldebogen zur Veranstaltung`, fontSize: 20, bold: true, color: "#000", margin: [0, cx(0), 0, cx(0)], style: { alignment: 'center' } },
//         { text: `${event.titel}`, fontSize: 16, italic: true, color: "#000", margin: [0, cx(0), 0, cx(4)], style: { alignment: 'center' } },
//         { text: `Informationen zur Veranstaltung:`, fontSize: 12, bold: true, color: "#000", style: { alignment: 'left' } },
//         {
//           table: {
//             headerRows: 0,
//             dontBreakRows: true,
//             widths: ['*', '*'],
//             style: "tableExample",
//             body: [[
//               {
//                 text: `Beginn: ${this.timePickerService.toDateString(event.anfangsdatum)}`,
//                 fontSize: 10, bold: false, color: "#000", style: { alignment: 'left:' }, border: [false, false, false, false]
//               },
//               {
//                 text: `Ende: ${this.timePickerService.toDateString(event.enddatum)}`,
//                 fontSize: 10, bold: false, color: "#000", style: { alignment: 'left:' }, border: [false, false, false, false]
//               }
//             ],
//             [
//               {
//                 text: `Ort: ${event.ort}`,
//                 fontSize: 10, bold: false, color: "#000", style: { alignment: 'left:' }, border: [false, false, false, false]
//               },
//               {
//                 text: `Anmeldeschluss: ${this.timePickerService.toDateString(event.anmeldeschluss)}`,
//                 fontSize: 10, bold: false, color: "#000", style: { alignment: 'left:' }, border: [false, false, false, false]
//               }
//             ],
//             [
//               {
//                 text: `Preis: ${event.preis.toFixed(2)} €`,
//                 fontSize: 10, bold: false, color: "#000", style: { alignment: 'left:' }, border: [false, false, false, false]
//               },
//               {
//                 text: `Veranstalter: ${event.veranstalter.name}`,
//                 fontSize: 10, bold: false, color: "#000", style: { alignment: 'left:' }, border: [false, false, false, false]
//               }
//             ]]
//           }
//         },
//         { text: `Hiermit melde ich/melden wir unseren Sohn/unsere Tochter zur Veranstaltung "${event.titel}" verbindlich an.`, fontSize: 10, bold: false, color: "#000", margin: [0, cx(1), 0, cx(1)], style: { alignment: 'left' } },
//         ,
//         ...this.generateFormFields(event)
//         ,
//         {
//           text: `Zustimmung durch den Unterzeichner:`, fontSize: 12, bold: true, color: "#000", style: { alignment: 'left' }, margin: [0, cy(2), 0, cx(2)]
//         },
//         {
//           text: `Hiermit versichert sich der Unterzeichnende der Erhebung und der Verarbeitung seiner Daten und des Teilnehmers ` +
//             `für den er erziehungsberechtigt ist durch die Urban-System als Dienstleister des Ring Evangelischer Gemeindepfadfinder` +
//             `(REGP) zuzustimmen und über seine Rechte belehrt worden zu sein. Alle Informationen zum Datenschutz finden Sie unter anmeldung.regp.de/datenschutz.`,
//           fontSize: 10, bold: false, color: "#000", margin: [0, cx(0), 0, cx(0)], style: { alignment: 'left' }
//         },
//         {
//           text: "__________________________________________________________", fontSize: 10, bold: false, color: "#000", margin: [0, cx(4), 0, cx(0)], style: { alignment: 'left' }
//         },
//         {
//           text: "(Datum, Unterschrift)", fontSize: 10, bold: false, color: "#000", margin: [0, cx(0), 0, cx(0)], style: { alignment: 'left' },
//         }
//       ],
//       pageMargins: [cx(10), cy(7), cx(27), cy(10)]
//     });
//
//     return pfd;
//
//   }
//
//   private generateFormFields(event: IVeranstaltung, tn?: ITeilnehmer): Array<ContentTable | ContentText> {
//     let registerForm: Array<RegisterField | RegisterLine> = getTeilnehmerRegisterFields();
//     registerForm.forEach(rf => {
//       if (RegisterLine.isRegisterLine(rf)) {
//         //Mir egal
//       } else {
//         const selectedInDb = new RegExp("(^|;)" + rf.key + "($|;)").test(event.registerForm || "");
//         const printIsJa = rf.print === "ja";
//         rf.selected = selectedInDb || printIsJa;
//       }
//     });
//
//     registerForm = registerForm.filter(rf => {
//       if (RegisterLine.isRegisterLine(rf)) {
//         return true;
//       }
//       return rf.selected;
//     });
//
//     const result: Array<ContentTable | ContentText> = [];
//     for (let i = 0; i < registerForm.length; i++) {
//       const rf: RegisterLine | RegisterField = registerForm[i];
//       const next: RegisterField = registerForm[i+1] as RegisterField;
//       if (RegisterLine.isRegisterLine(rf)) {
//         result.push({
//           text: rf.msg,
//           fontSize: rf.size === "sm" ? 8 : 12, bold: false, color: "#000", margin: [0, this.cy(1), 0, this.cy(0)]
//         });
//         continue;
//       }
//       if(rf.cols === 1 && next?.cols === 1){
//         //Merge 2 Felder in ein col;
//         result.push({table: {
//           headerRows: 0,
//           dontBreakRows: true,
//           widths: ['*', '*'],
//           //style: "tableExample",
//           body: [[
//             this.generateCell(rf, tn),
//             this.generateCell(next, tn)
//           ]]
//         }});
//         i++;
//         continue;
//       } else if(rf.cols < 3){
//         //Als eine Zeile bauen
//         result.push({table: {
//           headerRows: 0,
//           dontBreakRows: true,
//           widths: ['*'],
//           //style: "tableExample",
//           body: [[
//             this.generateCell(rf, tn)
//           ]]
//         }});
//       } else {
//         //Als 2 Zeilen
//         result.push({table: {
//           headerRows: 0,
//           dontBreakRows: true,
//           widths: ['*'],
//           //style: "tableExample",
//           body: [[
//             this.generateCell(rf, tn)
//           ],[
//             {
//               text: " ",
//               fontSize: 10, bold: false, color: "#000", style: { alignment: 'left:' }, margin: [0, this.cy(1), 0, this.cy(0)], border: [false, false, false, true]
//             }
//           ]]
//         }});
//       }
//     }
//     return result;
//   }
//
//   public generateCell(rf: RegisterField, tn?: ITeilnehmer): TableCell{
//     let value = tn ? tn[rf.key] : "";
//     if(typeof value === "boolean"){
//       value = value ? "(Ja)" : "(Nein)";
//     }
//     //@ts-ignore
//     if(typeof value.getMonth === "function"){
//       //@ts-ignore
//       value = this.timePickerService.toDateString(value);
//     }
//     if(rf.type === "string"){
//       return {stack:[{
//         text: rf.title + ": ",
//         fontSize: 10, bold: false, color: "#000", style: { alignment: 'left:' }, margin: [0, this.cy(1), 0, this.cy(0)]
//       },{
//         text: value,
//         fontSize: 10, bold: false, color: "#444", style: { alignment: 'left:' }, margin: [0, this.cy(1), 0, this.cy(0)]
//       }],
//       border: [false, false, false, true]};
//     }
//     return {stack:[{
//       text: rf.title + ":\n   [   ] Ja   [   ] Nein ",
//       fontSize: 10, bold: false, color: "#000", style: { alignment: 'left:' }, margin: [0, this.cy(1), 0, this.cy(0)]
//      },{
//       text: value,
//       fontSize: 10, bold: false, color: "#444", style: { alignment: 'left:' }, margin: [0, this.cy(1), 0, this.cy(0)]
//      }],
//      border: [false, false, false, false]};
//   }
//
//
//   public generateRechnung(
//     stammAbsender: IStamm, stammEmpfanger: IStamm, tnList: Anmeldung[], rechnungsnummer: string, verwendungszweck: string, veranstaltung: IVeranstaltung, anzahlTeilehmer: number): TCreatedPdf {
//     const now = new Date();
//     const cx = this.cx, cy = this.cy, xmm = this.xmm, ymm = this.ymm;
//
//     const pdf = createPdf({
//       background: this.generateBackground(stammAbsender),
//       footer: this.generateFooterRechnung(),
//       content: [
//         { text: `${stammAbsender.name}`, fontSize: 20, bold: true, color: "#000", margin: [0, cx(2), 0, cx(25)], style: { alignment: 'center' } },
//         {
//           text: `${stammAbsender.name}\n${stammAbsender.strasse} ${stammAbsender.hausnummer}, ${stammAbsender.postleitzahl} ${stammAbsender.ort}`,
//           fontSize: 7, bold: false, color: "#000", absolutePosition: { x: xmm(22), y: ymm(47) }
//         },
//         {
//           text: `${stammEmpfanger.name},\n${stammEmpfanger.strasse} ${stammEmpfanger.hausnummer}\n${stammEmpfanger.postleitzahl} ${stammEmpfanger.ort}`,
//           fontSize: 10, bold: false, color: "#000", absolutePosition: { x: xmm(22), y: ymm(54.7) }
//         },
//         {
//           text: `Rechnungsnummer: ${rechnungsnummer}\n${stammAbsender.ort}, ${this.timePickerService.toDateString(now)}`,
//           fontSize: 10, bold: false, color: "#000", style: { alignment: 'right' },
//         },
//         {
//           text: `Rechnung ${veranstaltung.titel}\n\n`,
//           fontSize: 12, bold: true, color: "#000", style: { alignment: 'left' },
//         },
//         {
//           text: `
// Sehr geehrte Damen und Herren,
//
// wir möchten Sie bitten, für die Teilnehmer aus Ihrem Pfadfinderstamm an der Veranstallung ${veranstaltung.titel} in ${veranstaltung.ort} vom ${this.timePickerService.toDateString(veranstaltung.anfangsdatum)} bis ${this.timePickerService.toDateString(veranstaltung.enddatum)} die unten genannte Summe auf das Konto des`,
//           fontSize: 12, bold: false, color: "#000", style: { alignment: 'left' },
//         },
//         {
//           text: `
// ${stammAbsender.kontoinhaber}
// ${stammAbsender.bank}
// ${stammAbsender.iban}
// ${stammAbsender.bic}
// Verwendungszweck: ${verwendungszweck}
// `,
//           fontSize: 12, bold: true, color: "#000", style: { alignment: 'center' },
//         },
//         {
//           text: `\nanzuweisen.\n\n`,
//           fontSize: 12, bold: false, color: "#000", style: { alignment: 'left' },
//         },
//         {
//           table: {
//             headerRows: 1,
//             dontBreakRows: true,
//             widths: ['*', '*', '*'],
//             body: [[
//               {
//                 text: "Name",
//                 fontSize: 10, bold: true, color: "#000", style: { alignment: 'center' },
//               },
//               {
//                 text: "Status",
//                 fontSize: 10, bold: true, color: "#000", style: { alignment: 'center' },
//               },
//               {
//                 text: "Preis",
//                 fontSize: 10, bold: true, color: "#000", style: { alignment: 'center' },
//               }
//             ]].concat(
//               tnList.map(entry => {
//                 return [
//                   {
//                     text: entry.teilnehmer.vorname + " " + entry.teilnehmer.nachname,
//                     fontSize: 10, bold: false, color: "#000", style: { alignment: 'left' },
//                   },
//                   {
//                     text: entry.ausgefallen ? "Ausgefallen" : entry.mitarbeiter ? "Mitarbeiter" : "",
//                     fontSize: 10, bold: false, color: "#000", style: { alignment: 'center' },
//                   },
//                   {
//                     text: entry.preis.toFixed(2) + " €",
//                     fontSize: 10, bold: false, color: "#000", style: { alignment: 'right' },
//                   }
//                 ];
//               })).concat([[
//                 {
//                   text: "",
//                   fontSize: 10, bold: false, color: "#000", style: { alignment: 'left' },
//                 },
//                 {
//                   text: "",
//                   fontSize: 10, bold: false, color: "#000", style: { alignment: 'center' },
//                 },
//                 {
//                   text: "",
//                   fontSize: 10, bold: false, color: "#000", style: { alignment: 'right' },
//                 }
//               ], [
//                 {
//                   text: "Summe",
//                   fontSize: 10, bold: true, color: "#000", style: { alignment: 'center' },
//                 },
//                 {
//                   text: "",
//                   fontSize: 10, bold: false, color: "#000", style: { alignment: 'center' },
//                 },
//                 {
//                   text: tnList.reduce((sum, tn) => tn.preis + sum, 0).toFixed(2) + " €",
//                   fontSize: 10, bold: true, color: "#000", style: { alignment: 'right' },
//                 }
//               ]])
//           }
//         },
//         {
//           text: `\nHiermit bestätigen wir, dass an der oben genannten Veranstalltung ${anzahlTeilehmer} Personen angemeldet waren.\n`,
//           fontSize: 12, bold: false, color: "#000", style: { alignment: 'left' },
//         },
//         {
//           text: `\n\nDieses Schreiben wurde maschinell erstellt und ist ohne Unterschrift gültig.`,
//           fontSize: 10, bold: false, color: "#000", style: { alignment: 'left' },
//         },
//       ],
//       pageMargins: [cx(10), cy(7), cx(27), cy(10)]
//     });
//
//     return pdf;
//   }
//
//   private generateFooterRechnung() {
//     return (currentPage, pageCount) => {
//       return {
//         text: `Seite ${currentPage.toString()} von ${pageCount}`, fontSize: 8,
//         absolutePosition: { x: this.cx(60), y: this.cy(7) }
//       };
//     };
//   }
//
//   private generateFooterAnmeldung(stamm: IStamm, event: IVeranstaltung) {
//     return (currentPage, pageCount) => {
//       return [{
//         text: `Seite ${currentPage.toString()} von ${pageCount}`, fontSize: 8,
//         absolutePosition: { x: this.cx(83), y: this.cy(7) }, style: { alignment: "rigth" }
//       }, {
//         text: `ID: ${event.id}`, fontSize: 8,
//         absolutePosition: { x: this.cx(10), y: this.cy(7) }, style: { alignment: "left" }
//       }, {
//         text: `${stamm.name} | ${stamm.strasse} ${stamm.hausnummer} | ${stamm.postleitzahl} ${stamm.ort} | ${stamm.website}`, fontSize: 8,
//         absolutePosition: { x: this.cx(10), y: this.cy(5) }
//       }];
//     };
//   }
//
//   private generateBackground(stammAbsender: IStamm): ((currentPage: number, pageSize: PageSize) => string | Content | null) {
//     const bgSvg = `
//     <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000">
//       <rect x="${this.cx(0)}" y="${this.cy(92)}" width="${this.cx(75)}" height="${this.cy(8)}" fill="#c0c0c0" stroke-width="0"/>
//       <rect x="${this.cx(75)}" y="${this.cy(0)}" width="${this.cx(25)}" height="${this.cy(10)}" fill="#808080" stroke-width="0"/>
//       <rect x="${this.cx(75)}" y="${this.cy(25)}" width="${this.cx(25)}" height="${this.cy(75)}" fill="#808080" stroke-width="0"/>
//
//       <rect x="${this.xmm(5)}" y="${this.ymm(104.75)}" width="${this.xmm(5)}" height="${this.ymm(0.5)}" fill="#808080" stroke-width="0"/>
//       <rect x="${this.xmm(5)}" y="${this.ymm(209.75)}" width="${this.xmm(5)}" height="${this.ymm(0.5)}" fill="#808080" stroke-width="0"/>
//       <rect x="${this.xmm(5)}" y="${this.ymm(148.25)}" width="${this.xmm(5)}" height="${this.ymm(0.5)}" fill="#808080" stroke-width="0"/>
//     </svg>`;
//
//     const bgText = [
//       { text: `${stammAbsender.name}\n`, fontSize: 11, bold: true, color: "#ffffff" },
//       { text: `\n`, fontSize: 11, bold: true, color: "#ffffff" },
//       { text: `Geschäftsadresse:\n`, fontSize: 11, bold: true, color: "#ffffff" },
//       { text: `${stammAbsender.strasse} ${stammAbsender.hausnummer}\n`, fontSize: 10, bold: false, color: "#ffffff" },
//       { text: `${stammAbsender.postleitzahl} ${stammAbsender.ort}\n`, fontSize: 10, bold: false, color: "#ffffff" },
//       { text: `${stammAbsender.telefon ? "Fon " + stammAbsender.telefon : ""}\n`, fontSize: 10, bold: false, color: "#ffffff" },
//       { text: `${stammAbsender.fax ? "Fax " + stammAbsender.fax : ""}\n`, fontSize: 10, bold: false, color: "#ffffff" },
//       { text: `${stammAbsender.email ? stammAbsender.email : ""}\n`, fontSize: 10, bold: false, color: "#ffffff" },
//       { text: `${stammAbsender.website ? stammAbsender.website : ""}\n`, fontSize: 10, bold: false, color: "#ffffff" },
//       { text: `\n`, fontSize: 10, bold: false, color: "#ffffff" },
//       { text: `Bankverbindungen:\n`, fontSize: 11, bold: true, color: "#ffffff" },
//       { text: `${stammAbsender.bank}\n`, fontSize: 10, bold: false, color: "#ffffff" },
//       { text: `\n`, fontSize: 10, bold: false, color: "#ffffff" },
//       { text: `IBAN\n`, fontSize: 10, bold: false, color: "#ffffff" },
//       { text: `${stammAbsender.iban}\n`, fontSize: 10, bold: false, color: "#ffffff" },
//       { text: `BIC\n`, fontSize: 10, bold: false, color: "#ffffff" },
//       { text: `${stammAbsender.bic}\n`, fontSize: 10, bold: false, color: "#ffffff" }
//     ];
//
//     return page => {
//       return [
//         {
//           svg: bgSvg,
//           width: 1000,
//           height: 841.5
//         },
//         {
//           image: stammAbsender.icon,
//           fit: [this.cx(20), this.cy(10)],
//           width: this.cx(20),
//           height: this.cy(10),
//           absolutePosition: { x: this.cx(77.5), y: this.cy(12.5) }
//         },
//         {
//           text: bgText,
//           absolutePosition: { x: this.cx(77.5), y: this.cy(60) }
//         },
//       ];
//     };
//   }

}
