import { Injectable } from '@angular/core';
import { NgbDateStruct, NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class TimePickerService {

  constructor() { }

  public dateToStruct(date: Date): NgbDateStruct {
    const struct = new NgbDate(date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDate());
    return struct;
  }

  public structToDate(struct: NgbDateStruct): Date {
    return this.numToDate(struct.year, struct.month, struct.day);
  }

  public numToDate(year: number, month: number, day: number): Date{
    const yearS = year + "";
    const monthS = (month + "").padStart(2, '0');
    const dayS = (day + "").padStart(2, '0');
    const time = "00:00:00";
    const dateString = `${yearS}-${monthS}-${dayS}T${time}Z`;
    return new Date(dateString);
  }

  public toDateString(date: Date): string{
    const struct = this.dateToStruct(date);
    const yearS = struct.year + "";
    const monthS = (struct.month + "").padStart(2, '0');
    const dayS = (struct.day + "").padStart(2, '0');
    return `${dayS}.${monthS}.${yearS}`;
  }

  public dateIsValid(date: Date){
    return !isNaN(date.getUTCDate());
  }
}
