import {BackendService} from "./backend.service";
import {SessionService} from "./session.service";
import {BackendRequestMethods, JsonOf, WriteReturnType} from "../utils/types/backendTypes";
import {AbstractService} from "./abstract.service";
import {Injectable} from "@angular/core";
import {IAnmeldung} from "../models/IAnmeldung.interface";

@Injectable({
  providedIn: 'root'
})
export class AnmeldungService extends AbstractService<IAnmeldung>{

  private anmeldebeginnn: number = Number.MAX_SAFE_INTEGER;

  public fromJsonObj<T>(obj: JsonOf<T>):T{
    BackendService.updateIdToProperty(obj, "kind", (id)=> (window as any).kindService.getById(id), false, () => (window as any).kindService.getRevision());
    BackendService.updateIdToProperty(obj, "aktion", (id)=> (window as any).aktionService.getById(id), false, () => (window as any).aktionService.getRevision());
    //BackendService.updateIdToProperty(obj, "historie", (id)=> (window as any).anmeldungHistoryService.getById(id), true, () => (window as any).anmeldungHistoryService.getRevision());
    return obj as unknown as T;
  }

  constructor(
    backend: BackendService,
    sessionService: SessionService) {
    super(
      backend,
      sessionService,
      "getAnmeldung",
      "createAnmeldung",
      "updateAnmeldung",
      "deleteAnmeldung"
    );
  }


  public async kinderAnmelden() {
    const data = await this.backend.sendRequest("kinderAnmelden", {
      sessionId: this.sessionService.session.sessionId
    },false);

    const wrt = data as WriteReturnType;

    if(wrt.success === false){
      throw wrt.error;
    }
    return "Anmeldungen gespeichert";
  }

  protected async write(elem: IAnmeldung, method: BackendRequestMethods, successMsg: string): Promise<string> {
    const json = BackendService.toJsonObj(elem);

    const data = await this.backend.sendRequest(method, {
      elem: json,
      sessionId: this.sessionService.session.sessionId
    }, false as unknown as true);

    const wrt = data as WriteReturnType;

    if(wrt.success === false){
      throw wrt.error;
    }
    return successMsg;
  }


  public async updateData(): Promise<IAnmeldung[]> {
    this.backend.sendRequest("getAnmeldungsbeginn", {}, false).then(a =>
      this.anmeldebeginnn = a.anmeldungsbeginn
    );
    return super.updateData();
  }

  public async setAnmeldebeginn(newVal: number): Promise<void> {
    await this.backend.sendRequest("updateAnmeldungsbeginn", {
      sessionId: this.sessionService.session.sessionId,
      anmeldungsbeginn: newVal
    }, false);
  }

  public getAnmeldungsbeginn(): number {
    return this.anmeldebeginnn;
  }

  public isAnmeldungActive(): boolean {
    return this.anmeldebeginnn < Date.now();
  }

}
