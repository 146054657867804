import { Pipe, PipeTransform } from '@angular/core';
import {IAktion} from "../models/IAktion.interface";

@Pipe({
  name: 'aktionAlter'
})
export class AktionAlterPipe implements PipeTransform {

  transform(aktion: IAktion): string {
    if(aktion.maxAlter === 99) {
      return "Ab " + aktion.minAlter;
    }
    return aktion.minAlter + "-" + aktion.maxAlter;
  }

}
