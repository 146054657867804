import {Component, OnInit} from '@angular/core';
import {AktionService} from "../../service/aktion.service";
import {IAktion} from "../../models/IAktion.interface";
import {ActivatedRoute} from "@angular/router";
import {IAnmeldung} from "../../models/IAnmeldung.interface";
import {AnmeldungStatus} from "../../models/enums";
import {AnmeldungService} from "../../service/anmeldung.service";
import {EventQueueService} from "../../service/event-queue-service.service";
import {PdfGeneratorService} from "../../service/pdf-generator.service";

@Component({
  selector: 'app-aktion-kinder-list',
  templateUrl: './aktion-kinder-list.component.html',
  styleUrls: ['./aktion-kinder-list.component.scss']
})
export class AktionKinderListComponent implements OnInit {

  aktion?: IAktion;
  angemeldeteListe: IAnmeldung[];
  warteListe: IAnmeldung[];
  vorangemeldeteListe: IAnmeldung[];
  gebuchtListe: IAnmeldung[];
  isLoading = [];
  anmeldeStatus = AnmeldungStatus;

  constructor(private readonly aktionService: AktionService,
              private readonly route: ActivatedRoute,
              private readonly anmeldungService:AnmeldungService,
              private readonly eventQueue: EventQueueService,
              private readonly pdfService: PdfGeneratorService) {
    this.route.paramMap.subscribe(params => {
      const id = parseInt(params.get("id"));
      this.aktion = this.aktionService.getById(id);
    });
   this.updateLists();

  }

  ngOnInit(): void {
    this.eventQueue.getObservable().subscribe(async msg=>{
      if(msg === "initDone"){
        this.route.paramMap.subscribe(params => {
          const id = parseInt(params.get("id"));
          this.aktion = this.aktionService.getById(id);
        });
        this.updateLists();

        for(const id in this.isLoading){
          if(this.isLoading[id] === 2){
            delete this.isLoading[id];
          }
        }
      }
    })
  }

  updateLists(){
    this.angemeldeteListe = this.aktion.anmeldungen.filter(a => a.status === AnmeldungStatus.ANGEMELDET).sort((a,b)=>a.edited - b.edited);
    this.warteListe = this.aktion.anmeldungen.filter(a => a.status === AnmeldungStatus.WARTELISTE).sort((a,b)=>a.edited - b.edited);
    this.vorangemeldeteListe = this.aktion.anmeldungen.filter(a => a.status === AnmeldungStatus.VORANGEMELDET).sort((a,b)=>a.edited - b.edited);
    this.gebuchtListe = this.aktion.anmeldungen.filter(a => a.status === AnmeldungStatus.GEBUCHT).sort((a,b)=>a.edited - b.edited);
  }

  async changeAnmeldestatus(a: IAnmeldung, s: AnmeldungStatus){
    this.isLoading[a.kind.id] = 1;
    a.status = s;
    await this.anmeldungService.update(a);
    this.isLoading[a.kind.id] = 2;
  }

  mediListeDrucken() {
    this.pdfService.downloadMediListe(this.aktion);
  }

  kinderlisteDrucken() {
    this.pdfService.downloadKinderliste(this.aktion);
  }
}
