import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ElementRef} from '@angular/core';
import {IActionButton} from './buttons';
import {SessionService} from "../../service/session.service";

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnChanges {
  @Input() actionButtons: IActionButton[] = [];
  @Output() queryChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() query: string = "";
  @Input() useUrlBar = true;
  @Input() public categories:any = [{key: "Name", type: "boolean"}, {key: "Hausnummer", type: "number"}];
  private readonly urlPath: string;

  constructor(
    private readonly el: ElementRef,
    private readonly sessionService: SessionService
  ) {
    if (this.useUrlBar && document.location) {
      const url = document.location.href;
      if (url.indexOf('q=') !== -1) {
        let regexResult = /q=([^&]*)/g.exec(document.location.href);
        if (regexResult) {
          this.urlPath = regexResult[1];
        } else {
          this.urlPath = "";
        }
      } else {
        this.urlPath = "";
      }
    } else {
      this.urlPath = "";
    }
  }

  ngOnInit() {
    if (this.useUrlBar && this.urlPath) {
      this.query = decodeURIComponent(this.urlPath);
    }
    this.textFieldUpdate();
  }

  ngOnChanges() {
    this.queryChange.emit(this.query);
    this.updateUrl();
  }

  textFieldUpdate() {
    this.queryChange.emit(this.query);
    this.updateUrl();
  }

  addQuery(newPart){
    this.query = this.query + newPart;
    this.textFieldUpdate();
    this.focus();
  }

  focus(){
    this.el.nativeElement.querySelector("input").focus();
  }

  updateUrl() {
    if (!this.query) {
      return;
    }

    if(this.useUrlBar && document.location){
      const oldUrl = document.location.href;
      let newUrl;
      if (oldUrl.indexOf('?') === -1) {
        newUrl = oldUrl + '?q=' + this.query;
      } else if (oldUrl.indexOf('?q=') === -1 && oldUrl.indexOf('&q=') === -1) {
        newUrl = oldUrl + '&q=' + encodeURIComponent(this.query);
      } else {
        newUrl = oldUrl.replace(/q=[^&]*/g, 'q=' + encodeURIComponent(this.query));
      }
      if (window.history.replaceState) {
        // prevents browser from storing history with each change:
        window.history.replaceState({}, 'REGP', newUrl);
      }
    }
  }

  get visibleActionButtons(): IActionButton[] {
    return this.actionButtons.filter(b => b.rolle.indexOf(this.sessionService.session.currentPermission) !== -1);
  }
}
