import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';


@Component({
  selector: 'app-single-datalist',
  templateUrl: './single-datalist.component.html',
  styleUrls: ['./single-datalist.component.scss']
})
export class SingleDatalistComponent<T> implements OnInit, OnChanges {
  @Input() fullList: T[] = [];
  public sortedList: T[] = [];
  public query: string = "";
  @Input() auswahl: T | null = null;
  @Input() toText: (obj:T) => string = (v) => v.toString();
  @Input() toHtml: (obj:T) => string = (v) => v.toString();
  @Input() trackByFn: (obj:T) => any = (v) => v;
  @Input() multi: boolean;
  @Output() onSelect: EventEmitter<T | null> = new EventEmitter<T | null>();
  constructor() { 
    this.multi = false;
  }
 
  inputChange(){
    if(this.auswahl){
      this.setValue(null);
    }
    this.updateSortedList();
  }

  private updateSortedList() {
    this.sortedList.length = 0;
    this.fullList.forEach((v: T) => {
      if (this.toText(v).toLowerCase().indexOf(this.query.toLowerCase()) > -1) {
        this.sortedList.push(v);
      }
    });
  }

  ngOnInit() {
    this.sortedList = [];
    
    if(this.auswahl != undefined){
      this.setValue(this.auswahl);
    }else{
      this.query = "";
      this.inputChange();
    }
  }


  ngOnChanges(changes:any): void {
    if(changes.fullList && changes.fullList.previousValue && changes.fullList.previousValue.length === changes.fullList.currentValue.length){
      return;
    }
    this.updateSortedList();
  }

  setValue(selectedValue:T | null){
    this.auswahl = selectedValue;
    if(selectedValue){
      this.onSelect.emit(this.auswahl);
    }
    if(this.multi && selectedValue){
      this.query = "";
      this.inputChange();
    } else if(selectedValue) {
      this.query = this.toText(selectedValue);
      this.sortedList.push(selectedValue);
      this.sortedList.length = 0
    }
  }

}
