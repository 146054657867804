<app-subnav [items]="subnav" ></app-subnav>
<div class="container">
  <h2 class="mt-3 text-center">Icon Verwaltung</h2>

  <div class="w-100 m-3">
    Neues Icon hochladen:
    <i *ngIf="uploadActive" class="fas fa-circle-notch fa-spin mx-2"></i>
    <input (change)="addIcon($event);" type="file" id="image-input" accept="image/jpeg, image/png, image/jpg">
  </div>

  <hr>

  <div class="d-flex flex-row flex-wrap ">
    <ng-container *ngFor="let icon of icons">
      <ng-container *ngIf="icon.id !== -1">
        <div class="m-2 icon-tile pcBorder shadow rounded d-flex flex-column align-items-center align-content-center">
          <div class="my-2">
            <img width="150" height="150" class="icon-no-size" [src]="icon.icon" alt="Icon der Aktion">
          </div>
          <div class="pcBorderTop p-2 w-100">
            <div class="d-inline-block">
              ID: {{icon.id}}<br/>
              Größe: {{(icon.icon.length / 1024).toFixed(0)}} kB <br>
            </div>
            <div class="d-inline-block float-right" [ngbTooltip]="icon.aktionen.length > 0 ? 'Icons die verwendet werden, können nicht gelöscht werden.' : ''">
              <button (click)="delete(icon)" [disabled]="icon.aktionen.length > 0" class="btn btn-warning ml-2" ngbTooltip="Löschen">
                <i *ngIf="icon !== activeDelete" class="fas fa-trash"></i>
                <i *ngIf="icon === activeDelete" class="fas fa-circle-notch fa-spin"></i>
              </button>
            </div>
            Verwundungen: {{icon.aktionen.length}}
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>


</div>
