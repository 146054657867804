<nav *ngIf="isVisible()" class="navbar navbar-expand-xl justify-content-center ">

  <!-- Links -->
  <ul class="navbar-nav text-center">
    <ng-container *ngFor="let item of items">
    <li *ngIf="!item.maOnly || isMA" class="nav-item justify-content-center">
      <a class="nav-link" [routerLink]="item.route">{{item.displayName}}</a>
    </li>
    </ng-container>
  </ul>

</nav>
