<div class=" form-group" *ngFor="let line of fields1">
  <app-input [(val)]="kind[line.key]" [name]="line.label" [required]="line.pflichtfeld" [placeholder]="line.placeholder" [type]="line.type"></app-input>
</div>
<div class="form-group">
  <label class="pflichtfeld">Geschlecht</label>
  <select class="form-control" [(ngModel)]="kind.geschlecht">
    <option [ngValue]="Geschlecht.MALE">Junge</option>
    <option [ngValue]="Geschlecht.FEMALE">Mädchen</option>
    <option [ngValue]="Geschlecht.DIVERS">Divers</option>
  </select>
</div>

<div class=" form-group" *ngFor="let line of fields2">
  <app-input [(val)]="kind[line.key]" [name]="line.label" [required]="line.pflichtfeld" [placeholder]="line.placeholder" [type]="line.type"></app-input>
</div>
<div class="form-group">
  <label class="">Schwimmabzeichen</label>
  <select class="form-control" [(ngModel)]="kind.schwimmabzeichen">
    <option [ngValue]="Schwimmabzeichen.KEINS">Keins</option>
    <option [ngValue]="Schwimmabzeichen.SEEPFERDCHEN">Seepferdchen</option>
    <option [ngValue]="Schwimmabzeichen.BRONZE">Bronze</option>
    <option [ngValue]="Schwimmabzeichen.SILBER">Silber</option>
    <option [ngValue]="Schwimmabzeichen.GOLD">Gold</option>
  </select>
</div>
<div class="form-group mt-4">
  <input type="checkbox" class="largeCheckbox mr-3" name="transferleistung" [(ngModel)]="transferleistung" (change)="transferleistungChange.emit(transferleistung)">
  <label>Bildungskarte / Transferleistung</label>
</div>
<ng-container *ngIf="transferleistung">
  <div class="form-group">
    <label>Bildungskartennummer / Transferleistungsnummer</label>
    <input class="form-control" type="text" [(ngModel)]="kind.soziPass">
  </div>
  <div class="form-group">
    <label>Beleg Upload</label>
    <a class="ml-2 btn pcButton mb-1" *ngIf="kind.soziFile.startsWith('data:image')" [href]="kind.soziFile | safe" [download]="'Beleg_'+kind.id+'.jpg'">Download</a>
    <a class="ml-2 btn pcButton mb-1" *ngIf="kind.soziFile.startsWith('data:application/pdf')" [href]="kind.soziFile | safe" [download]="'Beleg_'+kind.id+'.pdf'">Download</a>
    <input class="form-control" (change)="uploadSoziFile($event);" type="file" id="sozi-input" accept="image/jpeg, image/png, image/jpg, application/pdf">
  </div>
</ng-container>
