<div class="container text-center pt-5">
  <h1>Anmeldung Ferienpass T.o.J. eV.</h1>
  <p class="mt-4">
    Hier können Sie sich als Elternteil beim Ferienpass einloggen. Sollten Sie noch keine Account haben, können Sie sich über Registrieren einen anlegen.
  </p>

  <div class="row" *ngIf="!sessionService.session.sessionId">
    <div class="col-lg-2 d-none d-lg-block"></div>
    <div class="col-12 col-lg-8 anmeldeDiv p-3 rounded shadow-lg">
      <h4>Anmelden</h4>
      <form>

        <input class="m-1  form-control" [(ngModel)]="username" name="username" type="text" placeholder="E-Mail">
        <input class="m-1  form-control" [(ngModel)]="password" name="password" type="password" placeholder="Passwort">
        <input class="m-1 btn scButton " type="submit" value="Login" (click)="login()">
        <input class="m-1 btn scButton " type="button" value="Registrieren" routerLink="/registration">
        <input class="m-1 btn scButton " type="button" value="Login vergessen" data-toggle="collapse" data-target="#recoveryInput" aria-expanded="false" aria-controls="recoveryInput">

      </form>
    </div>
    <div class="col-lg-2 d-none d-lg-block"></div>
  </div>
  <div class="mt-3 collapse row" id="recoveryInput">
    <div class="col-lg-2 d-none d-lg-block"></div>
    <div class="col-12 col-lg-8 anmeldeDiv p-3 rounded shadow-lg">
      <h4>Passwort wiederherstellen</h4>
      <input class="m-1 form-control" [(ngModel)]="email" name="email" type="text" placeholder="E-Mail">
      <button class="m-1 btn scButton " type="button" (click)="sendPasswordRecoveryMail()">Passwort zurücksetzen <i *ngIf="mailIsSending" class="fas fa-circle-notch fa-spin"></i></button>
    </div>
    <div class="col-lg-2 d-none d-lg-block"></div>
  </div>
</div>
