<div class="container my-5">
  <h3 class="my-2">Willkommen beim Ferienpass der Gemeinde Handewitt, durchgeführt vom Trägerverein offene Jugendarbeit e.V. im Anschluss T.o.J. genannt.</h3>

  <p>
    Hier können Sie Ihre Kinder für die verschiedenen Aktion aus dem Ferienpassprogramm
    anmelden. Im Folgenden wird Ihnen der Ablauf einmal beschrieben und erklärt.
  </p>
  <ol>
    <li>
      Sollten Sie noch keinen Account haben, müssen Sie sich zunächst <a routerLink="/registration">registrieren</a>.
      Accounts aus dem Vorjahr wurden alle im Herbst gelöscht!
      <ol type="a">
        <li>
          Nach der Registration erhalten Sie eine Mail mit einem Link, mit dem Sie Ihre
          Registration bestätigen können.
        </li>
      </ol>
    </li>
    <li>
      Melden Sie sich über die <a routerLink="/login">Anmelde-Seite</a> im System an.
      <ol type="a">
        <li>
          Sollten Sie Ihr Passwort vergessen haben, können Sie dieses ganz einfach über
          die Passwort-Vergessen-Funktion zurücksetzen.
        </li>
      </ol>
    </li>
    <li>
      Nach der Anmeldung können Sie über <a routerLink="/settings/elternList">Einstellungen</a> Ihre jeweiligen Kinder mit dem
      Plus neben Ihrem Namen anlegen.
      <ol type="a">
        <li>
          Sollten Sie soziale Unterstützung in Form von einer Bildungskarte oder
          Transverleistungen bekommen, so können Sie bei dem jeweiligen Kind die
          Bildungskarte angeben und hochladen.
        </li>
      </ol>
    </li>
    <li>
      Nach dem Anlegen Ihrer Kinder, können Sie für Ihr Kinder die jeweiligen Aktionen
      unter <a routerLink="/anmelden">Kinder-Anmelden</a> buchen. Weitere Details zu Kinder-Anmelden finden Sie
      weiter unten beschrieben.
    </li>
  </ol>
  <h4>
    Kinder-Anmelden: Der Prozess einmal im Detail beschrieben:
  </h4>
  <p>
    Es gibt verschiedene Status bei der Kinder-Anmeldung, die im Folgenden einmal beschrieben
    werden.
  </p>
  <h6>
    Voranmeldung
  </h6>
  <p>
    Sie, als Elternteile können bis zu einem bestimmten Datum ({{anmeldungenDatum | datum}}
      {{anmeldungenDatum | zeit}}) Ihre Kinder an den jeweiligen Aktionen voranmelden. Dabei
    handelt es sich nur um eine reine Vorauswahl und Ihre Kinder sind noch zu keiner Aktion angemeldet.
  </p>

  <h6>Angemeldet</h6>
  <p>
    Wenn das definierte Anmeldedatum ({{anmeldungenDatum | datum}}
      {{anmeldungenDatum | zeit}}) erreicht ist, können Sie die jeweiligen
    Voranmeldungen in eine Anmeldung umwandeln. Dazu müssen Sie auf die
    Aktions-Seite gehen und dort auf den Button (Kinder Anmelden) klicken. Damit werden alle
    gespeicherten Voranmeldungen in richtige Anmeldungen umgewandelt. Diese Anmeldungen
    werden dem T.o.J. Team dann in der chronologischen Reihenfolge angezeigt.
  </p>
  <h6>Gebucht</h6>
  <p>
    Das T.o.J. Team hat eine Liste von Anmeldungen, die es dann durch geht und die jeweiligen
    Kinder für die Aktionen bucht. Wenn das T.o.J Team eine Anmeldung bestätigt hat, wird
    diese als gebucht markiert und Sie bekommen dann eine E-Mail mit der
    Buchungsbestätigung. Zusätzlich erhalten Sie dann auch eine Buchungsübersicht und einen
    Betrag, den Sie innerhalb der nächsten 3 Werktage zu zahlen haben. Buchungen, die nach 3
    Tagen nicht bezahlt worden sind, können vom T.o.J. Team storniert werden und der Platz
    könnte einem anderen Kind zugeteilt werden.
  </p>
  <h6>Warteliste</h6>
  <p>
    Bei Aktionen, bei denen die maximale Teilnehmerzahl erreicht ist, können Kinder auf eine
    Warteliste gesetzt werden. Sollte dann ein Platz in der Aktion frei werden, kann das nächste
    Kind aus der Wartelist nachrücken.
  </p>

  <p>
    Sie können uns über <i class="fab fa-whatsapp"></i> Whatsapp kontaktieren: <a href="https://wa.me/49046089720960">04608 972 0960</a> und über <a href="mailto:ferienpass@toj-online.de">ferienpass@toj-online.de</a>.
  </p>

</div>
