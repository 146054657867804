import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datum'
})
export class DatumPipe implements PipeTransform {

  transform(value: number): unknown {
    let date = new Date(value);
    return date.getDate() + "." + (date.getMonth()+1) + "." + date.getFullYear();
  }

}
