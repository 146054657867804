import {Component, OnInit} from '@angular/core';
import {IAktion} from "../../models/IAktion.interface";
import {AktionService} from "../../service/aktion.service";
import {Schwimmabzeichen} from "../../models/enums";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {IconService} from "../../service/icon.service";

@Component({
  selector: 'app-new-aktion',
  templateUrl: './new-aktion.component.html',
  styleUrls: ['./new-aktion.component.scss']
})
export class NewAktionComponent implements OnInit {

  aktion: IAktion

  constructor(private readonly aktionService : AktionService,
              private readonly toastrService: ToastrService,
              private readonly router: Router,
              private readonly iconService: IconService) {
    this.aktion = {
      titel: "",
      beschreibung: "",
      start: 1641034800000,
      ende: 1641034800000,
      veranstalter: "T.o.J.-Team",
      ort: "",
      preis: 0,
      soziPreis: 0,
      maxKinder: 0,
      minAlter: 6,
      maxAlter: 99,
      schwimmabzeichen: Schwimmabzeichen.KEINS,
      icon: iconService.getById(-1), // TODO Icon Dropdown anzeigen
      aktiv: false,
      anmeldungen: [],
      ausgaben: [],
      id: -1,
      created: 1641034800000,
      edited: 1641034800000
    }
  }

  ngOnInit(): void {

  }

  async saveAktion(): Promise<void>{
    try{
      await this.aktionService.create(this.aktion);
      this.toastrService.success("Aktion wurde gespeicher.");
      this.router.navigate(['aktionsList']);
    }catch (msg){
      this.toastrService.warning(msg, "Fehler");
    }
  }

}
