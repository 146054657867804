import {Component, OnDestroy, OnInit} from '@angular/core';
import {IIcon} from "../../models/IIcon.interface";
import {IconService} from "../../service/icon.service";
import {SUBNAV_SETTNGS} from "../../app-routing.module";
import {EventQueueService} from "../../service/event-queue-service.service";
import {Subscription} from "rxjs";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss']
})
export class IconsComponent implements OnInit, OnDestroy {
  public subnav = SUBNAV_SETTNGS;
  public icons!: IIcon[]

  private subscription: Subscription;
  public activeDelete: IIcon | null = null;
  public uploadActive: boolean

  constructor(
    private readonly iconService: IconService,
    private readonly eventQueue: EventQueueService,
    private readonly toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.icons = this.iconService.getList();
    this.subscription = this.eventQueue.getObservable().subscribe(msg => {
      if (msg === "initDone") {
        this.icons = this.iconService.getList();
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  addIcon(event: any) {
      const reader = new FileReader();
      reader.addEventListener("load", async () => {
        const uploaded_image = reader.result;
        if((uploaded_image as string).length > 1000000) { //ca. 1MB
          this.toastr.error("Die Datei ist zu groß.", "Fehler");
          this.uploadActive = false;
          return;
        }

        await this.iconService.create({
          icon: uploaded_image as string,
          aktionen: [],
          edited: 0,
          created: 0,
          id: 0
        });
        this.uploadActive = false;
      });
      reader.readAsDataURL(event.target.files[0]);
      this.uploadActive = true;
  }

  async delete(icon: IIcon) {
    this.activeDelete = icon;
    await this.iconService.delete(icon);
  }
}
