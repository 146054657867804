<div class="position-relative">
  <img class="selectedImg icon" (click)="editMode = !editMode" width="50px" height="50px" [src]="icon.icon" alt="Icon der Aktion">
  <div *ngIf="editMode" class="position-absolute frame pcBorder grayBgColor p-1 rounded">
    <ng-container *ngFor="let i of icons">
      <img class="imgOption m-1 icon" (click)="iconChange.emit(i); editMode = false" width="50px" height="50px" [src]="i.icon" alt="Icon der Aktion">
    </ng-container>


  </div>
</div>
