import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {getKindKeyPrettyName, IKind} from "../../models/IKind.interface";
import {Geschlecht, Schwimmabzeichen} from "../../models/enums";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-kind-editor',
  templateUrl: './kind-editor.component.html',
  styleUrls: ['./kind-editor.component.scss']
})
export class KindEditorComponent implements OnInit {


  @Input() kind!:IKind;
  Schwimmabzeichen = Schwimmabzeichen;
  Geschlecht = Geschlecht;

  @Input() transferleistung!:boolean;
  @Output() transferleistungChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  public readonly fields1 = [
    {key: 'vorname', type: 'text', placeholder: '', pflichtfeld: true},
    {key: 'nachname', type: 'text', placeholder: '', pflichtfeld: true}
  ];
  public readonly fields2 = [
    {key: 'strasse', type: 'text', placeholder: '', pflichtfeld: true},
    {key: 'hausnummer', type: "text",  placeholder: '', pflichtfeld: true},
    {key: 'plz', type: 'number', placeholder: '', pflichtfeld: true},
    {key: 'ort', type: 'text', placeholder: '', pflichtfeld: true},
    {key: 'zusatz', type: 'text', placeholder: '', pflichtfeld: false},
    {key: 'geburtsdatum', type: 'date', placeholder: '', pflichtfeld: true},
    {key: 'tel', type: 'phone', placeholder: '', pflichtfeld: false},
    {key: 'mobile', type: 'phone', placeholder: '', pflichtfeld: false},
    {key: 'krankheiten', type: 'textarea', placeholder: 'Nur Krankheiten, die für die Aktionen relevant sind!', pflichtfeld: false},
    {key: 'medikamente', type: 'textarea', placeholder: 'Nur Medikamente, die für die Aktionen relevant sind!', pflichtfeld: false},
    {key: 'allergien', type: 'textarea', placeholder: 'Nur Allergien, die für die Aktionen relevant sind!', pflichtfeld: false},
    {key: 'bemerkung', type: 'textarea', placeholder: 'Hinweise, die für die Aktionen relevant sind!', pflichtfeld: false}
  ];
  constructor(
    private toastr: ToastrService
  ) {
    KindEditorComponent.addLabel(this.fields1);
    KindEditorComponent.addLabel(this.fields2);
  }

  private static addLabel(arr: Array<{key: string, label?: string}>) {
    arr.forEach(v => {
      if(!v.label) {
        return v.label = getKindKeyPrettyName(v.key as keyof IKind);
      }
    });
  }

  ngOnInit(): void {
  }

  uploadSoziFile(event: any) {
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const uploaded_image = reader.result;
      if((uploaded_image as string).length > 2000000) { //ca. 2MB
        this.toastr.error("Die Datei ist zu groß.", "Fehler");
        return;
      }
      this.kind.soziFile = uploaded_image as string;
    });
    reader.readAsDataURL(event.target.files[0]);
  }

  downloadSoziFile() {

  }
}
