<div class="container">
  <app-filter [actionButtons]="actionButtons" [categories]="categories" [(query)]="query" (queryChange)="updateFilter()"></app-filter>
</div>
<div *ngIf="isMa; else noPermission">
  <table class="table">
    <tr class="text-center grayBgColor sticky-top">
      <th>ID</th>
      <th>Name</th>
      <th>Adresse</th>
      <th>
        Anzahl Aktionen<br>
        (V, A, W, G, Ab)
      </th>
      <th>Alter</th>
      <th>Aktivierungsdatum</th>
      <th>Rechnungsstatus</th>
        <th>Bezahlt</th>
      <th></th>
    </tr>
    <ng-container *ngFor="let k of kinder">

        <ng-container *ngIf="k.aktivierungsdatum" [ngTemplateOutlet]="kinderTable" [ngTemplateOutletContext]="{k:k,r:getRechnung(k)}"></ng-container>

    </ng-container>
    <tr class="grayBgColor">
      <th class="text-center" colspan="9">Noch nicht Abgesendet</th>
    </tr>
    <ng-container *ngFor="let k of kinder">

        <ng-container *ngIf="!k.aktivierungsdatum" [ngTemplateOutlet]="kinderTable" [ngTemplateOutletContext]="{k:k,r:getRechnung(k)}">

        </ng-container>
      </ng-container>


  </table>
</div>
<ng-template #noPermission>
  Keine Berechtigung
</ng-template>

<ng-template #kinderTable let-k="k" let-r="r" >
  <tr>
    <ng-container *ngIf="aktionList(k) as list">

        <td class="text-center">{{k.id}}</td>
        <td>{{k.vorname}} {{k.nachname}}</td>
        <td>{{k.strasse}} {{k.hausnummer}}, {{k.plz}} {{k.ort}}</td>
        <td class="text-center anzahl" >
            <span ngbTooltip="Voranmeldung" class="grau">{{list.vor}}</span>
            <span ngbTooltip="Angemeldet" class="blau">{{list.ang}}</span>
            <span ngbTooltip="Warteliste" class="gelb">{{list.wList}}</span>
            <span ngbTooltip="Gebucht" class="gruen">{{list.geb}}</span>
            <span ngbTooltip="Abgemeldet" class="rot">{{list.abGem}}</span>
            <ng-container *ngIf="getChanges(k) as c">
                <span *ngIf="isAlert(c)" ngbTooltip="Eltern haben eine Änderung vorgenommen! Klicken für Details." (click)="openHistory(k)" class="primaryBgColor"><i class="fas fa-warning"></i></span>
                <span *ngIf="!isAlert(c)" ngbTooltip="Klicken für History." (click)="openHistory(k)" class="primaryBgColor"><i class="fas fa-info-circle"></i></span>
                <ng-template #historyList >

                        <p *ngFor="let h of c">{{h.created | date}} {{h.created | zeit}}, {{h.anmeldung.aktion.titel}}, {{h.change}}</p>
                        <p *ngIf="isAlert(c)">Eltern haben eine &Auml;nderung vorgenommen!</p>



                </ng-template>
            </ng-container>
        </td>
        <td class="text-center">{{k.geburtsdatum | ageFilter}}</td>
        <td class="text-center" *ngIf="!k.aktivierungsdatum">---</td>
        <td class="text-center" *ngIf="k.aktivierungsdatum">{{k.aktivierungsdatum | datum}} {{k.aktivierungsdatum | zeit}}</td>

        <td class="text-center">
            <ng-container *ngIf="r">
                {{r.status}}
            </ng-container>
        </td>
        <td >
            <span (click)="editKind(k)" ngbTooltip="Zum beabeiten klicken" *ngIf="k.id !== editMode" >

                {{k.bezahlterBetrag.toFixed(2)}} € <span *ngIf="r">von {{r.betrag.toFixed(2)}} €</span>
            </span>

            <div class="form-group" *ngIf="editMode === k.id">

                <input type="number" [(ngModel)]="editBetrag" step="0.01" class="form-control d-inline">
                <button class="btn pcButton mt-1 mr-2" (click)="saveBetrag(k)"><i class="fas fa-save" ></i></button>
                <button class="btn pcButton mt-1" (click)="cancelBetrag()"><i class="fas fa-close" ></i></button>
            </div>
        </td>
        <td class="text-center">
          <button class="btn pcButton m-1" [routerLink]="'/anmelden/kindBuchen/' + k.id" [ngbTooltip]="(list.vor || list.ang)? 'Kind buchen': 'Buchung anpassen'"><i class="fas fa-check" *ngIf="list.vor || list.ang"></i><i class="fas fa-check-double" *ngIf="!list.vor && !list.ang"></i></button>
          <button class="btn pcButton m-1" [routerLink]="'/kind/edit/'+k.id" ngbTooltip="Kind Bearbeiten"><i class="fas fa-edit"></i></button>
          <button class="btn pcButton m-1" [routerLink]="'/eltern/edit/'+k.eltern.id" [ngbTooltip]="elternInfo"><i class="fas fa-user-alt"></i></button>
          <button class="btn pcButton m-1" (click)="rechnungErstellen(k)" ngbTooltip="Rechnung erstellen"><i class="fas fa-file-invoice" *ngIf="!rechnungLoadingIds[k.id]"></i><i class="fas fa-circle-notch fa-spin" *ngIf="rechnungLoadingIds[k.id]"></i></button>
          <button class="btn pcButton m-1" [disabled]="!r" (click)="rechnungDrucken(r)" ngbTooltip="Rechnung drucken"><i class="fas fa-print"></i></button>
          <button class="btn pcButton m-1" [disabled]="!r" (click)="rechnungSenden(r)" ngbTooltip="Rechnung als E-Mail versenden"><i class="fas fa-paper-plane" *ngIf="!rechnungSendingIds[k.id]"></i><i class="fas fa-circle-notch fa-spin" *ngIf="rechnungSendingIds[k.id]"></i></button>

          <ng-template #elternInfo>
            <div class="d-flex flex-column">
              <h6>Eltern</h6>
              <span class="mt-1">{{k.eltern.vorname}} {{k.eltern.nachname}}</span>
              <span class="my-1">{{k.eltern.mail}}</span>
              <span class="mb-1">{{k.eltern.mobile || k.eltern.telefon}}</span>
            </div>
          </ng-template>
        </td>

    </ng-container>
  </tr>
</ng-template>