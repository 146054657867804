import {Component, Input, OnInit} from '@angular/core';
import {getAktionKeyPrettyName, IAktion} from "../../models/IAktion.interface";
import {Schwimmabzeichen} from "../../models/enums";

@Component({
  selector: 'app-aktion-editor',
  templateUrl: './aktion-editor.component.html',
  styleUrls: ['./aktion-editor.component.scss']
})
export class AktionEditorComponent implements OnInit {

  @Input() aktion!:IAktion
  alterAb: boolean = false;
  Schwimmabzeichen = Schwimmabzeichen;

  public readonly fields1 = [
    {key: 'titel', type: 'text', placeholder: '', pflichtfeld: true},
    {key: 'beschreibung', type: 'textarea', placeholder: '', pflichtfeld: true},
    {key: 'start', type: 'dateTime', placeholder: '', pflichtfeld: true},
    {key: 'ende', type: "dateTime",  placeholder: '', pflichtfeld: true},
    {key: 'veranstalter', type: 'text', placeholder: '', pflichtfeld: true},
    {key: 'ort', type: 'text', placeholder: '', pflichtfeld: true},
    {key: 'preis', type: 'decimal', placeholder: '', pflichtfeld: true},
    {key: 'soziPreis', type: 'decimal', placeholder: '', pflichtfeld: true},
    {key: 'maxKinder', type: 'number', placeholder: '', pflichtfeld: true},
    {key: 'minAlter', type: 'number', placeholder: '', pflichtfeld: true}
  ];


  constructor() {
    AktionEditorComponent.addLabel(this.fields1);
  }

  ngOnInit(): void {
    this.alterAb = this.aktion.maxAlter === 99;
  }

  private static addLabel(arr: Array<{key: string, label?: string}>) {
    arr.forEach(v => {
      if(!v.label) {
        return v.label = getAktionKeyPrettyName(v.key as keyof IAktion);
      }
    });
  }
}
