import {BackendService} from "./backend.service";
import {SessionService} from "./session.service";
import {JsonOf} from "../utils/types/backendTypes";
import {AbstractService} from "./abstract.service";
import {Injectable} from "@angular/core";
import {IAusgaben} from "../models/IAusgaben.interface";

@Injectable({
  providedIn: 'root'
})
export class AusgabenService extends AbstractService<IAusgaben>{

  public fromJsonObj<T>(obj: JsonOf<T>):T{
    BackendService.updateIdToProperty(obj, "aktion", (id)=> (window as any).aktionService.getById(id), false, () => (window as any).aktionService.getRevision());
    return obj as unknown as T;
  }

  constructor(
    backend: BackendService,
    sessionService: SessionService) {
    super(
      backend,
      sessionService,
      "getAusgaben",
      "createAusgaben",
      "updateAusgaben",
      "deleteAusgaben"
    );
  }
}
