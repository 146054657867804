import {BackendService} from "./backend.service";
import {SessionService} from "./session.service";
import {JsonOf} from "../utils/types/backendTypes";
import {AbstractService} from "./abstract.service";
import {Injectable} from "@angular/core";
import {IAnmeldungHistory} from "../models/IAnmeldungHistory.interface";

@Injectable({
  providedIn: 'root'
})
export class AnmeldungHistoryService extends AbstractService<IAnmeldungHistory>{

  public fromJsonObj<T>(obj: JsonOf<T>):T{
    BackendService.updateIdToProperty(obj, "anmeldung", (id)=> (window as any).anmeldungService.getById(id), false, () => (window as any).anmeldungService.getRevision());
    BackendService.updateIdToProperty(obj, "eltern", (id)=> (window as any).elternService.getById(id), false, () => (window as any).elternService.getRevision());
    return obj as unknown as T;
  }

  constructor(
    backend: BackendService,
    sessionService: SessionService) {
    super(
      backend,
      sessionService,
      "getAnmeldungsHistorie",
      null,
      null,
      null
    );
  }
}
