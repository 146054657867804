import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AktionService} from "../../service/aktion.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-edit-aktion',
  templateUrl: './edit-aktion.component.html',
  styleUrls: ['./edit-aktion.component.scss']
})
export class EditAktionComponent implements OnInit {

  public aktion;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly aktionService: AktionService,
    private readonly toastrService: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const id = parseInt(params.get("id"));
      this.aktion = this.aktionService.getById(id);
    });
  }

  async saveAktion() {
    try {
      await this.aktionService.update(this.aktion);
      this.toastrService.success("Änderungen gespeichert.", "Erfolgreich")
      this.router.navigate(["aktion"])
    } catch (exc) {
      this.toastrService.error(exc, "Fehler")
    }
  }
}
