import {Component, OnInit} from '@angular/core';
import {SessionService} from "../../service/session.service";
import {EventQueueService} from "../../service/event-queue-service.service";
import {KindService} from "../../service/kind.service";
import {AnmeldungService} from "../../service/anmeldung.service";
import {getKindSearchCategories, IKind} from "../../models/IKind.interface";
import {AnmeldungStatus, Rolle} from "../../models/enums";
import {IActionButton} from "../../utils/filter/buttons";
import {FilterEngineService} from "../../service/filter-engine.service";
import {PdfGeneratorService} from "../../service/pdf-generator.service";
import {RechnungService} from "../../service/rechnung.service";
import {ToastrService} from "ngx-toastr";
import {IRechnung} from "../../models/IRechnungModel";
import {IAnmeldungHistory} from "../../models/IAnmeldungHistory.interface";
import {AnmeldungHistoryService} from "../../service/anmeldungs-history.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AnmeldungsHistoryComponent} from "../anmeldungs-history/anmeldungs-history.component";

@Component({
  selector: 'app-kinder-buchen',
  templateUrl: './kinder-buchen.component.html',
  styleUrls: ['./kinder-buchen.component.scss']
})
export class KinderBuchenComponent implements OnInit {

  public isMa!: boolean;
  public kinder: IKind[]
  actionButtons: IActionButton[] = [];
  public rechnungLoadingIds: any = {};
  public rechnungSendingIds: any = {};
  query = "";
  categories = getKindSearchCategories();
  anmeldungsHistory: IAnmeldungHistory[] = [];
  anmeldungsHistoryByKind: {[key:number]:IAnmeldungHistory[]} = {};
  editMode:number|undefined;
  editBetrag = 0;

  constructor(
    private readonly sessionService: SessionService,
    private readonly eventQueue: EventQueueService,
    private readonly kindService: KindService,
    private readonly anmeldungService: AnmeldungService,
    private readonly filterService: FilterEngineService,
    private readonly pdfService: PdfGeneratorService,
    private readonly rechnungService: RechnungService,
    private readonly toastrService: ToastrService,
    private readonly anmeldungsHistoryService: AnmeldungHistoryService,
    private readonly ngbModal: NgbModal
  ) {
    this.isMa = sessionService.isMa();
    (async () => {
      this.kinder =
        (await this.filterService
          .filter(
            this.query,
            this.kindService.getList(),
            "init")
        )
        .sort((k1, k2) => KinderBuchenComponent.compareFn(k1, k2));
    })()

    this.eventQueue.getObservable().subscribe(async msg => {
      if (msg === "initDone") {
        this.isMa = this.sessionService.isMa();

        this.kinder =
          (await this.filterService
            .filter(
              this.query,
              this.kindService.getList(),
              "updateFromInitDone")
          )
          .sort((k1, k2) => KinderBuchenComponent.compareFn(k1, k2));
        for(const id in this.rechnungLoadingIds){
          if(this.rechnungLoadingIds[id] === 2){
            delete this.rechnungLoadingIds[id];

          }
        }
        for(const id in this.rechnungSendingIds){
          if(this.rechnungSendingIds[id] === 2){
            delete this.rechnungSendingIds[id];

          }
        }
      }
    });


  }

  private static compareFn(k1, k2){
    if(k1.aktivierungsdatum && k2.aktivierungsdatum) {
      return k1.aktivierungsdatum - k2.aktivierungsdatum;
    }
    if(!k1.aktivierungsdatum && !k2.aktivierungsdatum) {
      return 0
    }
    return !!k1.aktivierungsdatum ? 1 : -1
  }

  async updateFilter():Promise<void>{
    this.kinder =
      (await this.filterService
        .filter(
          this.query,
          this.kindService.getList(),
          "updateFromInitDone")
      )
      .sort((k1, k2) => KinderBuchenComponent.compareFn(k1, k2));
  }


  async ngOnInit(): Promise<void> {
    await this.anmeldungsHistoryService.updateData();
    this.anmeldungsHistory = this.anmeldungsHistoryService.getList();
    this.anmeldungsHistoryByKind = {};
    for(const a of this.anmeldungsHistory){
      if(!this.anmeldungsHistoryByKind[a.anmeldung.kind.id]){
        this.anmeldungsHistoryByKind[a.anmeldung.kind.id] = [a];
      }else{
        this.anmeldungsHistoryByKind[a.anmeldung.kind.id].push(a);
      }
    }


    for(const [id,l] of Object.entries(this.anmeldungsHistoryByKind)){
      l.sort((a, b) => a.created-b.created);
    }
  }

  aktionList(k: IKind): {
    vor: number,
    ang: number,
    geb: number,
    wList: number,
    abGem: number,
  } {
    let vor = 0, ang = 0, geb = 0, wList = 0, abGem = 0;
    for(const an of k.anmeldungen) {
      switch (an.status) {
        case AnmeldungStatus.GEBUCHT:
          geb++; break;
        case AnmeldungStatus.VORANGEMELDET:
          vor++; break;
        case AnmeldungStatus.ANGEMELDET:
          ang++; break;
        case AnmeldungStatus.WARTELISTE:
          wList++; break;
        case AnmeldungStatus.ABGEMELDET:
          abGem++; break;
      }
    }

    return {
      vor,
      ang,
      geb,
      wList,
      abGem
    }
  }

  async rechnungErstellen(kind: IKind){
    try{
      this.rechnungLoadingIds[kind.id] = 1;
      const rechnung =await this.pdfService.generateRechnung(kind);
      await this.rechnungService.create(rechnung);
      this.rechnungLoadingIds[kind.id] = 2;
    }catch (e){
      this.toastrService.error(e?.msg || e, "Fehler");
      delete this.rechnungLoadingIds[kind.id];
    }
  }

  getRechnung(k: IKind) : null | IRechnung {
    let rechnung = null;
    let max = 0;
    for(const r of k.rechnungen){
      if(r.created > max){
        max = r.created;
        rechnung = r;
      }
    }
    return rechnung;
  }

  async rechnungDrucken(r: IRechnung) {
    const rechnungWidthPdf = await this.rechnungService.getRechnungWithPdf(r.id);
    const pdf = rechnungWidthPdf.pdf;
    this.downloadPDF(pdf, r.kind.id + "-Rechnung");

  }

   private downloadPDF(pdf, name) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = name+".pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  async rechnungSenden(r: IRechnung) {
    try{
    this.rechnungSendingIds[r.kind.id] = 1;
    await this.rechnungService.sendRechnung(r.id);
    this.rechnungSendingIds[r.kind.id] = 2;

    }catch(e){
      delete this.rechnungSendingIds[r.kind.id];
    }
  }

  getChanges(k: IKind): IAnmeldungHistory[] {
    return this.anmeldungsHistoryByKind[k.id] || [];
  }


  isAlert(c: IAnmeldungHistory[]): boolean {
    if(c.length === 0){
      return false;
    }
    if(c[c.length -1].eltern === undefined){
      return true;
    }
    return c[c.length -1]?.eltern.rolle === Rolle.ET;
  }

  async saveBetrag(k: IKind) {
    k.bezahlterBetrag = this.editBetrag;
    await this.kindService.update(k);
    this.editMode = undefined;
  }

  editKind(k: IKind) {
    if(this.editMode){
      return
    }
    this.editMode = k.id;
    this.editBetrag = k.bezahlterBetrag;
  }

  cancelBetrag() {
    this.editMode = undefined;
  }

  openHistory(k: IKind){
    const activeModal = this.ngbModal.open(AnmeldungsHistoryComponent, {
      size: 'lg',
      windowClass: "ngModalHistory"
    });
    activeModal.componentInstance.kindId = k.id;
  }

}
