<span class="info">Es müssen 3 von 4 Zeichenkategorien benutzt werden. Die Länge muss immer erreicht werden.</span>
<table>
  <tbody>
    <tr *ngFor="let rule of rules">
      <td *ngIf="rule.ok"><i class="far fa-check-square"></i></td>
      <td *ngIf="!rule.ok"><i class="far fa-square"></i></td>
      <td>{{rule.name}}</td>
    </tr>
  </tbody>
</table>
