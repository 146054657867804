import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AktionService} from "../../service/aktion.service";
import {ToastrService} from "ngx-toastr";
import {AusgabenService} from "../../service/ausgaben.service";
import {IAktion} from "../../models/IAktion.interface";
import {IAusgaben} from "../../models/IAusgaben.interface";
import {EventQueueService} from "../../service/event-queue-service.service";
import {ConfirmWindowComponent} from "../../utils/confirm-window/confirm-window.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-aktion-cost-list',
  templateUrl: './aktion-cost-list.component.html',
  styleUrls: ['./aktion-cost-list.component.scss']
})
export class AktionCostListComponent implements OnInit {

  aktion: IAktion;
  editLine?: number;
  newDesc: string
  newPrice: number
  loading = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly aktionService: AktionService,
    private readonly toastrService: ToastrService,
    private readonly costService: AusgabenService,
    private readonly eventQueue: EventQueueService,
    private readonly dialogService: NgbModal
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const id = parseInt(params.get("id"));
      this.aktion = this.aktionService.getById(id);
    });
    this.eventQueue.getObservable().subscribe(msg => {
      if(msg === "initDone") {
        this.aktion = this.aktionService.getById(this.aktion.id);
        this.loading = false;
      }
    })
  }

  async saveEdit(cost: IAusgaben) {
    try {
      await this.costService.update(cost);
      this.toastrService.success("Gespeichert")
      this.editLine = undefined;
      this.loading = true;
    } catch (err) {
      this.toastrService.error(err?.error || err, "Fehler")
    }
  }

  async saveCreate() {
    try {
      const cost: IAusgaben = {
        aktion: this.aktion,
        preis: this.newPrice,
        beschreibung: this.newDesc,
        created: 0,
        edited: 0,
        id: 0
      }
      await this.costService.create(cost);
      this.toastrService.success("Gespeichert")
      this.editLine = undefined;
      this.loading = true;
      this.newPrice = 0;
      this.newDesc = "";
    } catch (err) {
      this.toastrService.error(err?.error || err, "Fehler")
    }
  }

  get sum() {
    return this.aktion.ausgaben.reduce((previousValue, currentValue) => previousValue + currentValue.preis, 0);
  }

  get sortedCosts() {
    return this.aktion.ausgaben.sort((a, b) => a.id - b.id);
  }

  async delete(cost: IAusgaben) {
    try {
      const dialog = this.dialogService.open(ConfirmWindowComponent);
      dialog.componentInstance.text = "Wollen Sie wirklich löschen?";
      dialog.componentInstance.titel = "Löschen?";
      const result = await dialog.result
      if(result){
        await this.costService.delete(cost);
        this.toastrService.success("Gelöscht")
        this.editLine = undefined;
        this.loading = true;
      }

    } catch (err) {
      this.toastrService.error(err?.error || err, "Fehler")
    }
  }
}
