<div class="container">
  <div *ngIf="kinderZumAnmelden && anmeldungenAktiv" class="primaryBgColor rounded my-2 p-2 grayFontColor d-flex flex-row">
    <div class="align-self-center mx-2"><i class="fa fa-3x fa-info-circle"></i></div>
    <div class="align-middle align-self-center">
      Sie haben Kinder Vorangemldet. Der Anmeldezeitraum hat begonnen, und sie können die Kinder jetzt anmelden.
      Zum Anmelden der Kinder bitte den Button drücken.
    </div>
    <button class="btn scButton align-self-center" (click)="kinderAnmelden()">Kinder Anmelden</button>
    <div class="align-self-center mx-2"><i class="fa fa-3x fa-info-circle"></i></div>
  </div>

  <app-filter [actionButtons]="actionButtons" [categories]="categories" [(query)]="query" (queryChange)="updateFilter()"></app-filter>

  <ng-container *ngIf="!isMa">
    <div class="d-flex my-3 grayBgColor rounded pcBorder overflow-hidden w-100 flex-row" *ngFor="let aktion of aktionen">
      <div class="black d-flex w-25 flex-column pcBorderRight">
        <div class="primaryBgColor timeBox d-flex flex-column p-2">
          <div>Datum</div>
          <div class="large align-self-center flex-grow-1 d-flex"><div class="align-self-center text-center">{{aktion | aktionDate:"date"}}</div></div>
        </div>
        <div class="primaryBgColor timeBox d-flex flex-column my-1 p-2">
          <div>Start</div>
          <div class="large align-self-center flex-grow-1 d-flex"><div class="align-self-center text-center">{{aktion | aktionDate:"timeStart"}}</div></div>
        </div>
        <div class="primaryBgColor timeBox d-flex flex-column p-2">
          <div>Ende</div>
          <div class="large align-self-center flex-grow-1 d-flex"><div class="align-self-center text-center">{{aktion | aktionDate:"timeEnd"}}</div></div>
        </div>
      </div>
      <div class="d-flex w-75 flex-column">
        <div class="d-flex flex-row pcBorderBottom">
          <div class="ml-2 mr-auto large">{{aktion.titel}}</div>
          <img [src]="aktion.icon?.icon" alt="" class="icon align-self-center align-middle mx-3" width="32" height="32">
          <div class="id mr-1">{{aktion.id}}</div>
        </div>
        <div class="p-2 flex-grow-1">
          {{aktion.beschreibung}}
        </div>
        <div class="p-2">
          Veranstalter: {{aktion.veranstalter}}
        </div>
        <div class="d-flex flex-row justify-content-around pcBorderTop infoBar">
          <div class="align-self-center">
            Ort: {{aktion.ort}}
          </div>
          <div class="align-self-center">
            Maximale Teilnehmer: <span [class]="getClass(aktion)">{{aktion.maxKinder}}</span>
          </div>
        </div>
        <div class="d-flex flex-row justify-content-around primaryBgColor black bottomBar">
          <div class="align-self-center">
            Preis: {{aktion.preis.toFixed(2)}} €
          </div>
          <div class="align-self-center">
            Sozialpreis: {{aktion.soziPreis.toFixed(2)}} €
          </div>
          <div class="align-self-center">
            Alter: {{aktion | aktionAlter}}
          </div>
        </div>
      </div>
    </div>
    <span class="white">Plätze Frei</span> | <span class="yellow">Fast Voll</span> | <span class="red">Voll</span>
  </ng-container>
</div>
<table *ngIf="isMa" class="table text-center">
  <tr>
    <th>ID</th>
    <th>Datum</th>
    <th>Titel</th>
    <th>Alter</th>
    <th>Preis</th>
    <th>Sozialpreis</th>
    <th>Anmeldungen</th>
    <th>Aktiv</th>
    <th></th>
  </tr>
  <tr *ngFor="let a of aktionen" [class]="getClass(a)">
    <td class="align-middle">{{a.id}}</td>
    <td class="align-middle">{{a | aktionDate:"all"}}</td>
    <td class="align-middle">{{a.titel}}</td>
    <td class="align-middle">{{a | aktionAlter}}</td>
    <td class="align-middle">{{a.preis.toFixed(2)}} €</td>
    <td class="align-middle">{{a.soziPreis.toFixed(2)}} €</td>

    <td class="align-middle text-center anzahl" *ngIf="aktionList(a) as list">
      <span ngbTooltip="Voranmeldung" class="grau">{{list.vor}}</span>
      <span ngbTooltip="Angemeldet" class="blau">{{list.ang}}</span>
      <span ngbTooltip="Warteliste" class="gelb">{{list.wList}}</span>
      <span ngbTooltip="Gebucht" class="gruen">{{list.geb}}</span>
      /
      <span ngbTooltip="Max. Teilnehmer" class="primaryBgColor">{{a.maxKinder}}</span>
    </td>

    <td class="align-middle"><input type="checkbox" disabled [checked]="a.aktiv" class="largeCheckbox"></td>
    <td class="align-middle">
      <button class="btn pcButton mx-2" ngbTooltip="Bearbeiten" [routerLink]="'/aktion/edit/' + a.id"><i class="fas fa-edit"></i></button>
      <button class="btn pcButton mx-2" ngbTooltip="Kosten" [routerLink]="'/aktion/cost/' + a.id"><i class="fas fa-euro-sign"></i></button>
      <button class="btn pcButton mx-2" ngbTooltip="Anmeldungsliste" [routerLink]="'/aktion/kinderList/' + a.id"><i class="fas fa-list"></i></button>
    </td>
  </tr>
</table>
