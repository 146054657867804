import {Component, OnInit} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {SessionService} from 'src/app/service/session.service';
import {Router} from '@angular/router';
import * as sha1 from 'js-sha1';
import {getElternKeyPrettyName, IEltern} from "../../models/IEltern.interface";
import {ElternService} from "../../service/eltern.service";
import {Rolle} from "../../models/enums";

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  public nutzer: IEltern & {passwort: string, passwortKopie: string};

  public readonly fields1 = [
    {key: 'vorname', type: 'text', placeholder: 'Max', pflichtfeld: true},
    {key: 'nachname', type: 'text', placeholder: 'Mustermann', pflichtfeld: true},
    {key: 'mail', type: 'mail', placeholder: 'max@mustermann.de', pflichtfeld: true},
    {key: 'passwort', label: "Passwort", type: 'password', placeholder: '************', pflichtfeld: true},
    {key: 'passwortKopie', label: "Passwort wiederholen", type: 'password',placeholder: '************',pflichtfeld: true},
  ];
  public readonly fields2 = [
    {key: 'strasse', type: 'text', placeholder: 'Haupstraße', pflichtfeld: true},
    {key: 'hausnummer', type: 'text', placeholder: '1a', pflichtfeld: true},
    {key: 'plz', type: 'number', placeholder: '11111', pflichtfeld: true},
    {key: 'ort', type: 'text', placeholder: 'Musterstadt', pflichtfeld: true},
    {key: 'zusatz', type: 'text', placeholder: 'Im ersten Stock', pflichtfeld: false},

    {key: 'telefon', type: 'phone', placeholder: '0461 930 644 0', pflichtfeld: false},
    {key: 'mobile', type: 'phone', placeholder: '0151 ....', pflichtfeld: false},
  ];
  public readonly fields3 = [
    {key: 'kontoinhaber', type: 'text', placeholder: 'kontoinhaber', pflichtfeld: true},
    {key: 'iban', type: 'iban', placeholder: 'iban', pflichtfeld: true},
    {key: 'bic', type: 'text', placeholder: 'bic', pflichtfeld: true},
    {key: 'bank', type: 'text', placeholder: 'bank', pflichtfeld: true},
  ];

  public passwordValid = false;
  public acceptAGBs = false;


  constructor(
    private elternService: ElternService,
    private router: Router,
    private toast: ToastrService,
    public sessionService: SessionService
  ) {
    RegistrationComponent.addLabel(this.fields1)
    RegistrationComponent.addLabel(this.fields2)
    RegistrationComponent.addLabel(this.fields3)

    this.nutzer = {
      id: -1,
      vorname: '',
      nachname: '',
      strasse: '',
      hausnummer: '',
      plz: 0,
      ort: '',
      zusatz: '',
      mail: '',
      telefon: '',
      mobile: '',
      passworthash: '',
      lastPasswordChange: Date.now(),
      kontoinhaber: '',
      iban: '',
      bic: '',
      bank: '',
      rolle: Rolle.WAIT,
      newsletter: false,
      kinder: [],
      passwort: '',
      passwortKopie: '',
      created: Date.now(),
      edited: Date.now()
    };
  }

  private static addLabel(arr: Array<{key: string, label?: string}>) {
    arr.forEach(v => {
      if(!v.label) {
        return v.label = getElternKeyPrettyName(v.key as keyof IEltern);
      }
    });
  }

  ngOnInit() {
  }

  public async send() {
    const validationResult = this.validate();
    if (validationResult === 'OK') {
      // @ts-ignore
      this.nutzer.passworthash = sha1(this.nutzer.passwort);
      this.nutzer.passwort = "";
      this.nutzer.passwortKopie = "";
      try {
        const msg = await this.elternService.create(this.nutzer);
        this.toast.success(msg, 'Erfolgreich');
        this.router.navigate(["/home"]);
      } catch (msg) {
        this.toast.error(msg, 'Fehler')
      }
    }else {
      this.toast.warning(validationResult, 'Ungültige Daten');
    }
  }

  private validate(): string {
    if (this.nutzer.mail.indexOf(" ") !== -1) {
      return 'E-Mail darf kein Leerzeichen enthalten.'
    }
    if (!this.nutzer.mail) {
      return 'E-Mail muss gesetzt werden.';
    }
    if (!this.nutzer.vorname) {
      return 'Es muss ein Vorname eingegeben werden.';
    }
    if (!this.nutzer.passwort) {
      return 'Das Passwort darf nicht leer sein.';
    }
    if(!this.passwordValid) {
      return 'Das Passwort erfüllt nicht die Sicherheitsanforderungen.';
    }
    if(this.nutzer.passwortKopie !== this.nutzer.passwort) {
      return "Passwörter stimmen nicht überein.";
    }
    if(!this.acceptAGBs){
      return "Bitte akzeptieren Sie unsere AGBs.";
    }
    return 'OK';
  }
}
