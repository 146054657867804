<div class="container-fluid pt-5">
  <div class="row">
    <div class="col-lg-2 col-xl-3">

    </div>
    <div class="col-12 col-lg-8 col-xl-6">

      <h3>Registration</h3>
      <p>
        Wenn Sie Eltern sind und noch keinen Account besitzen, können Sie über dieses Formular einen erstellen:
      </p>
      <div class="container-fluid">
        <div class=" form-group my-4 my-lg-2" *ngFor="let line of fields1">
          <app-input [(val)]="nutzer[line.key]" [name]="line.label" [tooltip]="line.label" [required]="line.pflichtfeld"
                     [placeholder]="" [type]="line.type"></app-input>
        </div>
        <div class="w-100">
          <app-password-check [password]="nutzer.passwort"
                              (passwordValid)="passwordValid = $event"></app-password-check>
        </div>
        <div class=" form-group my-4 my-lg-2" *ngFor="let line of fields2">
          <app-input [(val)]="nutzer[line.key]" [name]="line.label" [tooltip]="line.label" [required]="line.pflichtfeld"
                     [placeholder]="" [type]="line.type"></app-input>
        </div>
        <div class="w-100">
          Bankdaten, damit Rücküberweisungen bei stornierten Anmeldungen abgewickelt werden können.
        </div>
        <div class=" form-group my-4 my-lg-2" *ngFor="let line of fields3">
          <app-input [(val)]="nutzer[line.key]" [name]="line.label" [tooltip]="line.label" [required]="line.pflichtfeld"
                     [placeholder]="" [type]="line.type"></app-input>
        </div>
        <div class="agb">
          <p>
            !!! Wichtig !!! Bitte informieren Sie auch die Betreuer vor Ort über die Medikamente und
            Allergien.
          </p>
          <p>
            Ich bin damit einverstanden, dass mein Kind bei den Ausflügen in einer Kleingruppe von mindestens 3
            Kindern allein ohne Betreuer unterwegs ist, wenn es einen festen Treffpunkt gibt, an dem immer ein Betreuer
            anzutreffen ist. Ich bin damit einverstanden, dass die persönlichen Daten meines Kindes an die
            veranstaltende Organisation weitergegeben werden.
          </p>

          <ul>
            <li>
              Sollte mein Kind an der Veranstaltung nicht teilnehmen, muss ich die
              dadurch entstehenden Mehrkosten von 5 Euro pro Tag übernehmen.
            </li>
            <li>
              Sollte mein Kind sich nicht an die während der Aktion/Freizeit
              geltenden Regeln halten und massiv und/oder zum wiederholten Male gegen
              sie verstoßen, erkläre ich mich nach vorheriger Rücksprache bereit, es
              abzuholen bzw. die Kosten für den Rücktransport zu übernehmen.
            </li>
            <li>
              Mein Kind darf mit PKW´s vom Veranstalter transportiert werden.
            </li>
            <li>
              Ich bin damit einverstanden, dass Fotos von meinem Kind gemacht
              werden und diese im Jugendzentrum, im Gemeindeblatt oder auf unserer
              Homepage zu sehen sein könnten.
            </li>
            <li>
              Bitte informieren Sie sich über die zur Veranstaltung geltenden Coronaregelungen, z.B.
              Mundschutzpflicht im öffentlichen Nahverkehr etc.
            </li>
          </ul>
        </div>
        <div>
          <input type="checkbox" class="largeCheckbox" [(ngModel)]="acceptAGBs"> Ich bin mit den Bedingungen einverstanden.
        </div>
        <div class="py-2">
          <input type="checkbox" class="largeCheckbox" [(ngModel)]="nutzer.newsletter"> Ich möchte den Newsletter
          bekommen!
        </div>
      </div>
      <button class="btn pcButton my-3 ml-3" (click)="send()">Zugang anfragen</button>
    </div>

  </div>
</div>

