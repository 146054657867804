import { Injectable } from '@angular/core';
import { EventQueueService } from './event-queue-service.service';
import {AktionService} from "./aktion.service";
import {AnmeldungService} from "./anmeldung.service";
import {AnmeldungHistoryService} from "./anmeldungs-history.service";
import {AusgabenService} from "./ausgaben.service";
import {ElternService} from "./eltern.service";
import {IconService} from "./icon.service";
import {KindService} from "./kind.service";
import {RechnungService} from "./rechnung.service";

@Injectable({
  providedIn: 'root'
})
export class CheatServiceService {

  private _isLoading = false;

  constructor(
    private aktionService: AktionService,
    private anmeldungService: AnmeldungService,
    private anmeldungHistoryService: AnmeldungHistoryService,
    private ausgabenService: AusgabenService,
    private elternService: ElternService,
    private iconService: IconService,
    private kindService: KindService,
    private eventQueue: EventQueueService,
    private rechnungService: RechnungService
  ) {
    (window as any).aktionService = this.aktionService;
    (window as any).anmeldungService = this.anmeldungService;
    (window as any).anmeldungHistoryService = this.anmeldungHistoryService;
    (window as any).ausgabenService = this.ausgabenService;
    (window as any).elternService = this.elternService;
    (window as any).iconService = this.iconService;
    (window as any).kindService = this.kindService;
    (window as any).rechnungService = this.rechnungService;



    eventQueue.getObservable().subscribe((msg) => {
      switch (msg) {
        case "permissionChange":
        case "relaod":
          this.update();
          break;
        default:
          break;
      }
    })
  }


  public async update() {
    this._isLoading = true;
    return new Promise((resolve, reject) => {
      const serviceCount = 7;
      let i = 0;

      const serviceInitDone = () => {
        i++;
        if (i === serviceCount) {
          this.eventQueue.fireEvent("initDone");
          this._isLoading = false;
          resolve();
        }
      };

      this.aktionService.updateData().then(serviceInitDone).catch((err) => reject(err));
      this.anmeldungService.updateData().then(serviceInitDone).catch((err) => reject(err));
      this.ausgabenService.updateData().then(serviceInitDone).catch((err) => reject(err));
      this.elternService.updateData().then(serviceInitDone).catch((err) => reject(err));
      this.iconService.updateData().then(serviceInitDone).catch((err) => reject(err));
      this.kindService.updateData().then(serviceInitDone).catch((err) => reject(err));
      this.rechnungService.updateData().then(serviceInitDone).catch((err) => reject(err));
    });
  }

  public get isLoading(){
    return this._isLoading;
  }
}
