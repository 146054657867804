export enum Schwimmabzeichen {
  KEINE_ANGABE = "KEINE_ANGABE",
  KEINS = "KEINS",
  SEEPFERDCHEN = "SEEPFERDCHEN",
  BRONZE = "BRONZE",
  SILBER = "SILBER",
  GOLD = "GOLD",
}

export function formatSchwimmabzeichen(abzeichen: Schwimmabzeichen): string {
  switch (abzeichen) {
    case Schwimmabzeichen.KEINE_ANGABE:
      return "Keine angabe";
    case Schwimmabzeichen.KEINS:
      return "Keins";
    case Schwimmabzeichen.SEEPFERDCHEN:
      return "Seepferdchen";
    case Schwimmabzeichen.BRONZE:
      return "Bronze";
    case Schwimmabzeichen.SILBER:
      return "Silber";
    case Schwimmabzeichen.GOLD:
      return "Gold";
  }
}

export enum AnmeldungStatus {
  VORANGEMELDET = "VORANGEMELDET",
  ANGEMELDET = "ANGEMELDET",
  GEBUCHT = "GEBUCHT",
  ABGEMELDET = "ABGEMELDET",
  WARTELISTE = "WARTELISTE",
}

export function formatAnmeldungStatus(status: AnmeldungStatus): string {
  switch (status) {
    case AnmeldungStatus.VORANGEMELDET: return "Vorangemeldet";
    case AnmeldungStatus.ANGEMELDET: return "Angemeldet";
    case AnmeldungStatus.GEBUCHT: return "Gebucht";
    case AnmeldungStatus.ABGEMELDET: return "Abgemeldet";
    case AnmeldungStatus.WARTELISTE: return "Warteliste";
  }
}

export enum Rolle {
  ADM = "ADM",
  MA = "MA",
  ET = "ET",
  WAIT = "WAIT"
}

export function formatRolle(rolle: Rolle): string {
  switch (rolle) {
    case Rolle.ADM: return "Admin";
    case Rolle.MA: return "Mitarbeiter";
    case Rolle.ET: return "Eltern";
    case Rolle.WAIT: return "Wartend";
  }
}

export enum Geschlecht {
  MALE = "MALE",
  FEMALE = "FEMALE",
  DIVERS = "DIVERS"
}

export function formatGeschlecht(geschlecht: Geschlecht): string {
  switch (geschlecht){
    case Geschlecht.MALE:
      return "Männlich";
    case Geschlecht.FEMALE:
      return "Weiblich";
    case Geschlecht.DIVERS:
      return "Divers";
  }
}
