<div class="form-group">
  <label [class.pflichtfeld]="required" class="primaryFontColor">{{name}}</label>

  <input type="text"
         class="form-control"
         [ngModel]="valAsStr()"
         (ngModelChange)="onInputChange($event)"
         [placeholder]="placeholder"
         [ngbTooltip]="tooltip">
  <span *ngIf="!valid">Fehler bei der Eingabe.</span>
</div>
