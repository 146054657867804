import {getAbstractModelKeyPrettyName, IAbstractModel} from "./IAbstractModel.interface";
import {IAktion} from "./IAktion.interface";

export interface IIcon extends IAbstractModel {
  icon: string
  aktionen: IAktion[]
}




export function isIcon(obj: Object): obj is IIcon {
  function d(value: string): boolean{
    return value !== undefined;
  }

  return obj &&
    d(obj["icon"]) &&
    d(obj["aktionen"])
}

export function getIconKeyPrettyName(key: keyof IIcon): string {
  switch (key) {
    case "icon":
      return "Icon";
    case "aktionen":
      return "Aktionen";
    default: return getAbstractModelKeyPrettyName(key);
  }
}

export function getIconSearchCategories() {
  return [
    {display: "Allgemein", key: "Allgemein", type: "CAT"},
    {key: "aktionen", type: "IAktion[]"},

  ].map(v => {
    if (v.display == null) {
      v.display = getIconKeyPrettyName(v.key as keyof IIcon);
    }
    return v;
  });
}
