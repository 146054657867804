import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-password-check',
  templateUrl: './password-check.component.html',
  styleUrls: ['./password-check.component.scss']
})
export class PasswordCheckComponent implements OnInit, OnChanges {

  public rules: Array<{
    ok: boolean,
    force: boolean
    name: string,
    regex: RegExp
  }>;

  @Input() public password: string;
  @Output() public passwordValid = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
    this.rules = [
      {
        ok: false,
        force: false,
        name: "Kleine Buchstaben",
        regex: /.*[a-z].*/
      },
      {
        ok: false,
        force: false,
        name: "Große Buchstaben",
        regex: /.*[A-Z].*/
      },
      {
        ok: false,
        force: false,
        name: "Zahlen",
        regex: /.*[0-9].*/
      },
      {
        ok: false,
        force: false,
        name: "Sonderzeichen: !?;:-_ ...",
        regex: /.*[!"§$%&/()=?*'><#°^_\-\\{}+;:,.|`´~[\]@€].*/
      },
      {
        ok: false,
        force: true,
        name: "Mindestens 12 Zeichen",
        regex: /.............*/
      },
    ]
    this.passwordValid.emit(false);
  }

  ngOnChanges(): void {
    if(!this.rules) {
      //Not initialised
      return;
    }
    this.rules.forEach(r => {
      r.ok = !!r.regex.exec(this.password);
    });

    let ruleOkCount = this.rules.filter(r => r.ok).length;
    let forcedRuleOk = !this.rules.filter(r => r.force).filter(r => !r.ok).length

    const valid = ruleOkCount >= 4 && forcedRuleOk;
    this.passwordValid.emit(valid);
  }
}
