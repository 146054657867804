<form class="form-inline float-right form" *ngIf="sessionService.session.sessionId">
    <div class="mx-2 form-inline">
      <label>{{sessionService.session.nutzer?.vorname || ""}} {{sessionService.session.nutzer?.nachname || ""}}</label>
      <i *ngIf="cheatService.isLoading" class="fas fa-circle-notch fa-spin ml-2"></i>
    </div>
    <input id="logout" class="m-1 shadow btn scButton" type="button" value="Logout" (click)="logout()">
    <div *ngIf="maxPerm !== Rolle.ET">
      <button [ngClass]="{'passiveButton': !isEt, 'activeButton': isEt}" [disabled]="loading" class="btn scButton leftButton" (click)="changeRoll()">ET</button>
      <button [ngClass]="{'passiveButton': isEt, 'activeButton': !isEt}" [disabled]="loading" class="btn scButton rightButton" (click)="changeRoll()" [innerHtml]="maxPerm"></button>
    </div>
</form>
