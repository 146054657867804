<div class="form-group mt-4 d-flex flex-row justify-content-between">
  <div>
    <input type="checkbox" class="largeCheckbox mr-3" [(ngModel)]="aktion.aktiv">
    <label>Aktiv</label>
  </div>
  <div>
    <app-icon-selector [(icon)]="aktion.icon"></app-icon-selector>
  </div>
</div>
<div class=" form-group" *ngFor="let line of fields1">
  <app-input [(val)]="aktion[line.key]" [name]="line.label" [required]="line.pflichtfeld" [placeholder]="line.placeholder" [type]="line.type"></app-input>
</div>
<div class="form-group">

  <!-- Das muss so komisch sein, nicht ändern -->
  <input type="radio" name="alterModus" class="largeCheckbox mr-2" [checked]="!alterAb" (click)="alterAb = false; aktion.maxAlter = aktion.maxAlter">
  <label class="">Von Bis</label>

  <input type="radio" name="alterModus" class="largeCheckbox ml-5 mr-2" [checked]="alterAb" (click)="alterAb = true; aktion.maxAlter = 99">
  <label class="">Ab</label>
</div>
<app-input [(val)]="aktion.maxAlter" name="Höchstalter" [required]="true" type="number"  *ngIf="!alterAb"></app-input>
<div class="form-group">
<label class="">Schwimmabzeichen</label>
  <select class="form-control" [(ngModel)]="aktion.schwimmabzeichen">
    <option [ngValue]="Schwimmabzeichen.KEINS">Keins</option>
    <option [ngValue]="Schwimmabzeichen.SEEPFERDCHEN">Seepferdchen</option>
    <option [ngValue]="Schwimmabzeichen.BRONZE">Bronze</option>
    <option [ngValue]="Schwimmabzeichen.SILBER">Silber</option>
    <option [ngValue]="Schwimmabzeichen.GOLD">Gold</option>
  </select>
</div>
