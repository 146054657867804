import { Pipe, PipeTransform } from '@angular/core';
import {IAktion} from "../models/IAktion.interface";

@Pipe({
  name: 'aktionDate'
})
export class AktionDatePipe implements PipeTransform {

  transform(aktion: IAktion, format: "date" | "timeStart" | "timeEnd" | "all"): string {
    const start = new Date(aktion.start);
    const end = new Date(aktion.ende);
    const sameDate = start.getFullYear() == end.getFullYear() && start.getMonth() == end.getMonth() && start.getDate() == end.getDate();

    switch (format) {
      case "all":
        if(sameDate) {
          //Nur ein Datum
          return AktionDatePipe.toDateString(start) + " " + AktionDatePipe.toTimeString(start) + " - " + AktionDatePipe.toTimeString(end);
        }
        // 2x Datum
        return AktionDatePipe.toDateString(start) + " " + AktionDatePipe.toTimeString(start) + " " + AktionDatePipe.toDateString(end) + " " + AktionDatePipe.toTimeString(end);
      case "date":
        if(sameDate) {
          return AktionDatePipe.toDateString(start);
        }
        return AktionDatePipe.toDateString(start) + " " + AktionDatePipe.toDateString(end);
      case "timeStart":
        return AktionDatePipe.toTimeString(start);
      case "timeEnd":
        return AktionDatePipe.toTimeString(end);
      default:
        return "Unerwarteter Fehler";
    }

  }

  private static toDateString(date: Date): string {
    let day;
    switch (date.getDay()) {
      case 0: day = "So"; break;
      case 1: day = "Mo"; break;
      case 2: day = "Di"; break;
      case 3: day = "Mi"; break;
      case 4: day = "Do"; break;
      case 5: day = "Fr"; break;
      case 6: day = "Sa"; break;
      default: day = "XX";break;
    }

    return day + " " + date.getDate() + "." + (date.getMonth()+1) + "." + date.getFullYear();
  }

  private static toTimeString(date: Date): string {
    if(date.getMinutes() < 10) {
      return date.getHours() + ":0" + date.getMinutes();
    }
    return date.getHours() + ":" + date.getMinutes();
  }

}
