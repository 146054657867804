import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss']
})
export class InputDateComponent implements OnInit {

  @Input() public withTime = false;
  @Input() public required = false;
  @Input() public tooltip = "";
  @Input() public name!: string;
  @Input() public val!: number;
  @Output() public valChanged: EventEmitter<number> = new EventEmitter<number>();

  public valid = true;
  public placeholder!: string;
  private regex!: RegExp;

  private static regexWithoutTime = /(\d\d?)\.(\d\d?)\.(\d\d\d?\d?)/;
  private static regexWithTime = /(\d\d?)\.(\d\d?)\.(\d\d\d?\d?) (\d\d?):(\d\d)/;
  private timeout?: any

  constructor() {
  }

  ngOnInit(): void {
    let hint: string;
    if (this.withTime) {
      hint = "DD.MM.JJJJ HH:MM";
      this.regex = InputDateComponent.regexWithTime;
    } else {
      hint = "DD.MM.JJJJ"
      this.regex = InputDateComponent.regexWithoutTime;
    }
    this.placeholder = hint
    this.tooltip = this.tooltip || hint;
  }

  public valAsStr(): string {
    const date = new Date(this.val);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const dateStr = InputDateComponent.toDigitCount(day, 2) + "." +
      InputDateComponent.toDigitCount(month, 2) + "." +
      InputDateComponent.toDigitCount(year, 4)

    if (this.withTime) {
      const hour = date.getHours();
      const minute = date.getMinutes();

      return dateStr + " " +
        InputDateComponent.toDigitCount(hour, 2) + ":" +
        InputDateComponent.toDigitCount(minute, 2);
    } else {
      return dateStr;
    }
  }

  private static toDigitCount(val: number, digits: number): string {
    let ret = "" + val;
    while (ret.length < digits) {
      ret = "0" + ret;
    }
    return ret;
  }

  private isValid(str: string): boolean {
    const regexResult = this.regex.exec(str);
    return regexResult && (regexResult.length === (this.withTime ? 6 : 4));
  }

  public onInputChange(str: string): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this.setFromStr(str), 1000);
  }

  public setFromStr(str: string): void {
    if(!this.isValid(str)) {
      this.valid = false;
      return;
    }
    this.valid = true;

    const result = this.regex.exec(str);
    const day = Number.parseInt(result[1]);
    const month = Number.parseInt(result[2]) - 1;
    let year = Number.parseInt(result[3]);
    if(year < 1000) {
      year += 2000;
    }

    let hour;
    let minute;

    if(this.withTime) {
      hour = Number.parseInt(result[4]);
      minute = Number.parseInt(result[5]);
    } else {
      hour = 12;
      minute = 0;
    }

    this.val = new Date(year, month, day, hour, minute).getTime();
    this.valChanged.emit(this.val);
  }
}
