import {Injectable} from '@angular/core';
import {ISession} from '../models/ISession.interface';
import {BackendService} from './backend.service';
import {EventQueueService} from './event-queue-service.service';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Rolle} from "../models/enums";

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private _session: ISession = {sessionId: null, nutzer: null, currentPermission: Rolle.WAIT};
  private navigateAfterRestore: any;

  constructor(
    private backend: BackendService,
    private eventQueue: EventQueueService,
    private router: Router,
    private toastr: ToastrService) {
  }

  public async restoreSession(): Promise<void> {
    const sessionId = localStorage.getItem('sessionId');
    if (sessionId) {
      const result = await this.backend.sendRequest('checkSession', {
        sessionId: sessionId
      }, false);

      if (result.success) {
        this._session.sessionId = sessionId;
        this._session.currentPermission = result.currentPermission;

        BackendService.updateIdToProperty(this._session, 'nutzer', () => (window as any).elternService.getElternByMail(result.mail), false, () => (window as any).elternService.getRevision());
        this.eventQueue.fireEvent('permissionChange');
        this.toastr.success('Sitzung wiederhergestellt', 'Erfolgreiche Anmeldung');
        this.router.navigate([this.navigateAfterRestore]);
      }
    }
  }

  public async login(mail: string, password: string): Promise<void> {
    try {
      const result = await this.backend.sendRequest('login', {
        mail: mail,
        password: password
      }, false);

      if (result.sessionId === null) {
        console.log(result);
        this._session = {sessionId: null, nutzer: null, currentPermission: Rolle.WAIT};
        this.toastr.error(result.msg, 'Fehlerhafte Anmeldung');
        return;
      }

      BackendService.updateIdToProperty(result, 'nutzer', (id) => (window as any).elternService.getById(id), false, () => (window as any).elternService.getRevision());
      this._session = result as unknown as ISession;
      localStorage.setItem('sessionId', this._session.sessionId);
      this.eventQueue.fireEvent('permissionChange');
      this.router.navigate(['/aktion']);
      this.toastr.success('Sie haben sich erfolgreich angemeldet', 'Erfolgreiche Anmeldung');

    } catch (error) {
      console.log(error);
      this._session = {sessionId: null, nutzer: null, currentPermission: Rolle.WAIT};
      this.toastr.error('Anmeldung fehlgeschlagen', 'Fehlerhafte Anmeldung');
    }
  }

  public get session() {
    return this._session;
  }

  public logout() {
    this.backend.sendRequest('logout', {sessionId: this._session.sessionId}, false);
    this._session = {sessionId: null, nutzer: null, currentPermission: Rolle.WAIT};
    localStorage.removeItem('sessionId');
    this.router.navigate(['/home']);
    this.eventQueue.fireEvent('permissionChange');
    this.toastr.success('Sie haben sich erfolgreich abgemeldet', 'Erfolgreiche Abmeldung');
  }

  public togglePermission() {
    if (this.session.nutzer.rolle === Rolle.WAIT) {
      return;
    }
    if (this.session.currentPermission === this.session.nutzer.rolle) {
      this.backend.sendRequest('setSessionPermissions', {
        sessionId: this.session.sessionId,
        newPermissions: Rolle.ET
      }, true).then(() => {
        this.session.currentPermission = Rolle.ET;
      });
    } else {
      this.backend.sendRequest('setSessionPermissions', {
        sessionId: this.session.sessionId,
        newPermissions: this.session.nutzer.rolle
      }, true).then(() => {
        this.session.currentPermission = this.session.nutzer.rolle;
      });
    }
  }

  // Check Funktion fürs Routing
  // noinspection JSUnusedGlobalSymbols,JSUnusedLocalSymbols
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const ignorError = !this.navigateAfterRestore;
    this.navigateAfterRestore = route.url.join('/');
    const isLoggedIn = this._session?.sessionId;
    if (isLoggedIn) {
      return true;
    } else if (ignorError) {
      this.router.navigate(['/']);
      return false;
    } else {
      this.router.navigate(['/']);
      this.toastr.warning('Navigation nicht möglich. Bitte einloggen!', 'Keine Berechtigung');
      return false;
    }
  }

  public isMa() : boolean{
    return this.isAdm()|| this.session.currentPermission === Rolle.MA;
  }

  public isAdm() : boolean{
    return this.session.currentPermission === Rolle.ADM;
  }
}
