import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ElternService} from "../../service/eltern.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss']
})
export class ActivateAccountComponent implements OnInit {

  private token!:string;

  constructor(private readonly route:ActivatedRoute,
              private readonly router: Router,
              private readonly elternService:ElternService,
              private readonly toastrService:ToastrService) {
    this.route.params.subscribe(async prams => {
      this.token = prams['token'];
      try{

        let result = await this.elternService.activate(this.token);
        if(result.success){
          this.toastrService.success("Ihr Account wurde erfolgreich aktiviert.","Erfolgreich");
          await this.router.navigate(['/login']);
          return;
        }

        this.toastrService.error(result?.error || result+"", "Fehler bei der Aktivierung!")
      }catch(exc){
        this.toastrService.error(exc?.error || exc+"", "Fehler bei der Aktivierung!")

      }

    });
  }


  ngOnInit(): void {

  }

}
