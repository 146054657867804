import {IAbstractModel} from "../models/IAbstractModel.interface";
import {BackendRequestMethods, JsonOf, WriteReturnType} from "../utils/types/backendTypes";
import {BackendService} from "./backend.service";
import {SessionService} from "./session.service";

export abstract class AbstractService<T extends IAbstractModel> {

  protected items: T[] = [];
  protected revision: number = -1;

  protected constructor(
    protected backend: BackendService,
    protected sessionService: SessionService,
    private getName: BackendRequestMethods,
    private createName: BackendRequestMethods,
    private updateName: BackendRequestMethods,
    private deleteName: BackendRequestMethods
    ) {
  }

  //In dieser Methode darf man this nicht benutzen!!!
  public abstract fromJsonObj<T>(obj: JsonOf<T>): T

  public async create(elem: T): Promise<string> {
    return this.write(elem, this.createName, "Erfolgreich angelegt.")
  }

  public async delete(elem: T): Promise<string> {
    const result = await this.backend.sendRequest(this.deleteName, {
      id: elem.id,
      sessionId: this.sessionService.session.sessionId
    }, false);

    const wrt = result as WriteReturnType;

    if(wrt.success){
      return "Erfolgreich gelöscht.";
    }
    throw wrt.error;
  }

  public async update(elem: T): Promise<string> {
    return this.write(elem, this.updateName, "Erfolgreich geändert.")
  }

  protected async write(elem: T, method: BackendRequestMethods, successMsg: string): Promise<string> {
    const json = BackendService.toJsonObj(elem);

    const data = await this.backend.sendRequest(method, {
      elem: json,
      sessionId: this.sessionService.session.sessionId
    }, true);

    const wrt = data as WriteReturnType;

    if(wrt.success === false){
      throw wrt.error;
    }
    return successMsg;
  }

  public async updateData(): Promise<T[]> {
    try{
      const jsonItems = await this.backend.sendRequest(this.getName,{
        sessionId: this.sessionService.session.sessionId
      }, false);

      if(jsonItems === null){
        this.items = [];
      } else {
        this.items = (jsonItems as any).map(this.fromJsonObj);
      }
      return this.items;
    } catch(err){
      console.log(err);
      throw err;
    } finally {
      this.revision++;
    }
  }

  public getList(): T[] {
    return this.items;
  }

  public getById(id: number): T | null {
    return this.items.find((elem)=>elem.id === id);
  }

  public getRevision(): number {
    return this.revision;
  }
}
