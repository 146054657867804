import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {IIcon} from "../../models/IIcon.interface";
import {IconService} from "../../service/icon.service";

@Component({
  selector: 'app-icon-selector',
  templateUrl: './icon-selector.component.html',
  styleUrls: ['./icon-selector.component.scss']
})
export class IconSelectorComponent implements OnInit {

  @Input()
  icon: IIcon;
  @Output()
  iconChange = new EventEmitter<IIcon>()


  public icons!: IIcon[]
  public editMode = false;

  constructor(
    private readonly eRef: ElementRef,
    private readonly iconService: IconService
  ) { }

  ngOnInit(): void {
    if(!this.icon) {
      this.icon = this.iconService.getById(-1);
      this.iconChange.emit(this.icon);
    }
    this.icons = this.iconService.getList();
  }

  @HostListener('document:click', ['$event'])
  clickOut(event) {
    if(this.eRef.nativeElement.contains(event.target)) {
      //Nothing to do here
    } else {
      this.editMode = false;
    }
  }

}
