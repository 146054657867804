import {BackendService} from "./backend.service";
import {SessionService} from "./session.service";
import {JsonOf} from "../utils/types/backendTypes";
import {AbstractService} from "./abstract.service";
import {Injectable} from "@angular/core";
import {IKind} from "../models/IKind.interface";

@Injectable({
  providedIn: 'root'
})
export class KindService extends AbstractService<IKind>{

  public fromJsonObj<T>(obj: JsonOf<T>):T{
    BackendService.updateIdToProperty(obj, "eltern", (id)=> (window as any).elternService.getById(id), false, () => (window as any).anmeldungService.getRevision());
    BackendService.updateIdToProperty(obj, "anmeldungen", (id)=> (window as any).anmeldungService.getById(id), true, () => (window as any).anmeldungService.getRevision());
    BackendService.updateIdToProperty(obj, "rechnungen", (id)=> (window as any).rechnungService.getById(id), true, () => (window as any).rechnungService.getRevision());
    return obj as unknown as T;
  }

  constructor(
    backend: BackendService,
    sessionService: SessionService) {
    super(
      backend,
      sessionService,
      "getKind",
      "createKind",
      "updateKind",
      "deleteKind"
    );
  }
}
