import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './home/login.component';
import { RegistrationComponent } from './home/registration/registration.component';
import { RecoveryComponent } from './home/recovery/recovery.component';
import { DatenschutzComponent } from './home/datenschutz/datenschutz.component';
import { ImpressumComponent } from './home/impressum/impressum.component';
import {ElternListComponent} from "./settings/eltern-list/eltern-list.component";
import {SubnavItem} from "./headline/subnav/subnav.component";
import {NewAktionComponent} from "./aktion/new-aktion/new-aktion.component";
import {EditAktionComponent} from "./aktion/edit-aktion/edit-aktion.component";
import {AktionsListComponent} from "./aktion/aktions-list/aktions-list.component";
import {AktionCostListComponent} from "./aktion/aktion-cost-list/aktion-cost-list.component";
import {ActivateAccountComponent} from "./home/activate-account/activate-account.component";
import {NewKindComponent} from "./settings/new-kind/new-kind.component";
import {IconsComponent} from "./settings/icons/icons.component";
import {EditKindComponent} from "./settings/edit-kind/edit-kind.component";
import {EditElternComponent} from "./settings/edit-eltern/edit-eltern.component";
import {ElternAnmeldungListComponent} from "./anmeldung/eltern-anmeldung-list/eltern-anmeldung-list.component";
import {AnmeldungConfirmComponent} from "./anmeldung/anmeldung-confirm/anmeldung-confirm.component";
import {SystemSettingsComponent} from "./settings/system-settings/system-settings.component";
import {HelpComponent} from "./home/help/help.component";
import {SessionService} from "./service/session.service";
import {KindBuchenComponent} from "./anmeldung/kind-buchen/kind-buchen.component";
import {KinderBuchenComponent} from "./anmeldung/kinder-buchen/kinder-buchen.component";
import {AktionKinderListComponent} from "./aktion/aktion-kinder-list/aktion-kinder-list.component";

export const SUBNAV_SETTNGS: SubnavItem[] = [
  {route: "/settings/elternList", displayName: "Übersicht"},
  {route: "/settings/system", displayName: "Systemeinstellung", maOnly: true},
  {route: "/settings/icons", displayName: "Icons Verwalten", maOnly: true}
];
const routes: Routes = [
  { path: "login",                    component: LoginComponent                                                 },
  { path: "recovery/:token",          component: RecoveryComponent                                              },
  { path: "activate/:token",          component: ActivateAccountComponent                                       },
  { path: "registration",             component: RegistrationComponent                                          },
  { path: "datenschutz",              component: DatenschutzComponent                                           },
  { path: "impressum",                component: ImpressumComponent                                             },
  { path: "settings/elternList",      component: ElternListComponent,              canActivate: [SessionService]},
  { path: "settings/icons",           component: IconsComponent,                   canActivate: [SessionService]},
  { path: "settings/system",          component: SystemSettingsComponent,          canActivate: [SessionService]},
  { path: "aktion/new",               component: NewAktionComponent,               canActivate: [SessionService]},
  { path: "aktion/edit/:id",          component: EditAktionComponent,              canActivate: [SessionService]},
  { path: "aktion/cost/:id",          component: AktionCostListComponent,          canActivate: [SessionService]},
  { path: "aktion",                   component: AktionsListComponent                                           },
  { path: "anmelden",                 component: ElternAnmeldungListComponent,     canActivate: [SessionService]},
  { path: "anmelden/confirm",         component: AnmeldungConfirmComponent,        canActivate: [SessionService]},
  { path: "anmelden/kindBuchen/:id",  component: KindBuchenComponent,              canActivate: [SessionService]},
  { path: "anmelden/kinderBuchen",    component: KinderBuchenComponent,            canActivate: [SessionService]},
  { path: "kind/new/:id",             component: NewKindComponent,                 canActivate: [SessionService]},
  { path: "kind/edit/:id",            component: EditKindComponent,                canActivate: [SessionService]},
  { path: "eltern/edit/:id",          component: EditElternComponent,              canActivate: [SessionService]},
  { path: "aktion/kinderList/:id",    component: AktionKinderListComponent,        canActivate: [SessionService]},
  { path: "help",                     component: HelpComponent                                                  },
  { path: '**',                       component: HelpComponent                                                  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
