import {Component, OnInit} from '@angular/core';
import {getAktionSearchCategories, IAktion} from "../../models/IAktion.interface";
import {IActionButton} from "../../utils/filter/buttons";
import {AnmeldungStatus} from "../../models/enums";
import {AktionService} from "../../service/aktion.service";
import {FilterEngineService} from "../../service/filter-engine.service";
import {EventQueueService} from "../../service/event-queue-service.service";
import {SessionService} from "../../service/session.service";
import {ToastrService} from "ngx-toastr";
import {IKind} from "../../models/IKind.interface";
import {KindService} from "../../service/kind.service";
import {AnmeldungService} from "../../service/anmeldung.service";
import {IAnmeldung, PASS_PREIS, SOZI_PASS_PREIS} from "../../models/IAnmeldung.interface";
import {Router} from "@angular/router";

const SEKUNDE = 1000;
const MINUTE = 60 * SEKUNDE
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const YEAR = 365 * DAY;

@Component({
  selector: 'app-eltern-anmeldung-list',
  templateUrl: './eltern-anmeldung-list.component.html',
  styleUrls: ['./eltern-anmeldung-list.component.scss']
})
export class ElternAnmeldungListComponent implements OnInit {

  soziPassPreis = SOZI_PASS_PREIS;
  passPreis = PASS_PREIS;

  aktionen: IAktion[] = [];
  kinder: IKind[];
  selections: boolean[][] = [];
  query = "";
  categories = getAktionSearchCategories();
  isMa = false;
  actionButtons: IActionButton[] = [];
  saveActive = false;
  AnmeldungStatus = AnmeldungStatus;
  anmeldungenAktiv = false;
  anmeldungenDatum!: number;
  navigateOnInitDone = false;


  constructor(
    private readonly aktionService: AktionService,
    private readonly kindService: KindService,
    private readonly filterService: FilterEngineService,
    private readonly anmeldungService: AnmeldungService,
    private readonly eventQueue: EventQueueService,
    private readonly sessionService: SessionService,
    private readonly toastrService: ToastrService,
    private readonly router: Router
  ) {  }

  async ngOnInit(): Promise<void> {
    const ownElternId = this.sessionService.session.nutzer.id;
    this.anmeldungenAktiv = this.anmeldungService.isAnmeldungActive();
    this.anmeldungenDatum = this.anmeldungService.getAnmeldungsbeginn();
    this.kinder = this.kindService.getList().filter(k => k.eltern.id === ownElternId);
    this.aktionen = (await this.filterService.filter(this.query,this.aktionService.getList(), "aktionenListInit")).sort((a1, a2) => a1.start - a2.start);
    this.isMa = this.sessionService.isMa();
    this.updateSelections();
    this.eventQueue.getObservable().subscribe(async msg=>{
      if(msg === "initDone"){
        this.aktionen = (await this.filterService.filter(this.query,this.aktionService.getList(), "aktionenListRefresh")).sort((a1, a2) => a1.start - a2.start);
        this.isMa = this.sessionService.isMa();
        this.updateSelections();

        if(this.navigateOnInitDone) {
          this.saveActive = false;
          this.router.navigate(["/anmelden/confirm"]);
        }
      }
    })
  }

  private updateSelections() {
    for(const a of this.aktionen) {
      if(!this.selections[a.id]) {
        this.selections[a.id] = [];
      }
      for(const k of this.kinder) {
        if(this.selections[a.id][k.id] === undefined) {
          this.selections[a.id][k.id] = this.isKindSelected(k, a);
        }
      }
    }
  }

  private isKindSelected(kind: IKind, aktion: IAktion): boolean {
    const a = this.getAnmeldung(kind, aktion);
    return a && (a.status === AnmeldungStatus.GEBUCHT || a.status === AnmeldungStatus.ANGEMELDET || a.status === AnmeldungStatus.VORANGEMELDET || a.status === AnmeldungStatus.WARTELISTE);
  }

  public getAnmeldung(kind: IKind, aktion: IAktion): IAnmeldung {
    return  kind.anmeldungen.filter(a => a.aktion.id === aktion.id)[0]
  }

  async updateFilter():Promise<void>{
    this.aktionen = (await this.filterService.filter(this.query,this.aktionService.getList(), "aktionenListInputChange")).sort((a1, a2) => a1.start - a2.start);
    this.updateSelections();
  }

  getClass(a: IAktion) {
    const max = a.maxKinder;
    const ist = a.anmeldungen.filter(an => an.status === AnmeldungStatus.GEBUCHT).length;

    const rel = ist / max;

    if(rel < 0.8) {
      return "white";
    }
    if(rel < 1) {
      return "yellow";
    }
    return "red";
  }

  async saveAnmeldungen() {

    for(const a of this.aktionen) {
      for(const k of this.kinder) {
        if(this.selections[a.id][k.id] && !this.isKindSelected(k, a)) {
          this.saveActive = true;
          //Anmelden
          try{
            const anmeldung: IAnmeldung = this.getAnmeldung(k, a)
            let zielStatus = this.anmeldungService.isAnmeldungActive() ? AnmeldungStatus.ANGEMELDET : AnmeldungStatus.VORANGEMELDET;
            if(anmeldung) {
              anmeldung.status = zielStatus;
              await this.anmeldungService.update(anmeldung);
            } else {
              await this.anmeldungService.create({
                status: zielStatus,
                aktion: a,
                kind: k,
                bezahlt: false,
                historie: [],
                id: -1,
                created: -1,
                edited: -1
              });
            }
            this.toastrService.success(k.vorname + " wurde an " + a.titel + " angemeldet.");
          } catch (err) {
            console.error(err);
            this.toastrService.error(k.vorname + " konnte nicht an " + a.titel + " angemeldet werden.")
          }
        }
        if(!this.selections[a.id][k.id] && this.isKindSelected(k, a)) {
          //Abmelden
          this.saveActive = true;
          try{
            const anmeldung = this.getAnmeldung(k, a);
            anmeldung.status = AnmeldungStatus.ABGEMELDET;
            await this.anmeldungService.update(anmeldung);
            this.toastrService.success(k.vorname + " wurde von " + a.titel + " abgemeldet.");
          } catch (err) {
            console.error(err);
            this.toastrService.error(k.vorname + " konnte nicht von " + a.titel + " abgemeldet werden.")
          }
        }
      }
    }
    if(this.saveActive){
      this.navigateOnInitDone = true;
    }else {
      this.toastrService.info("Es wurden keine \u00c4nderungen gemacht. Daher wurde auch nichts gespeichert.","Keine \u00c4nderung");
    }
  }

  calcPrice(kind: IKind): string {
    const sozi = !!kind.soziPass;
    let price = 0;
    for(const a of this.aktionen) {
      if(this.selections[a.id][kind.id]) {
        price += sozi ? a.soziPreis : a.preis;
      }
    }
    if(price > 0) {
      price = price + (sozi ? this.soziPassPreis: this.passPreis)
    }
    return price.toFixed(2);
  }

  hasAnmeldung(k: IKind) {
    for(const a of this.aktionen) {
      if(this.selections[a.id][k.id]) {
        return true;
      }
    }
    return false;
  }

  matchesAge(aktion: IAktion, kind: IKind) {
    const geb = kind.geburtsdatum;
    const start = aktion.start;
    const minAge = aktion.minAlter * YEAR;
    const maxAge = (aktion.maxAlter + 1) * YEAR;

    return geb < (start - minAge) && geb > (start - maxAge);
  }
}
