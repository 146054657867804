import {Rolle} from "./enums";
import {IKind} from "./IKind.interface";
import {getAbstractModelKeyPrettyName, IAbstractModel} from "./IAbstractModel.interface";

export interface IEltern extends IAbstractModel{
  vorname: string
  nachname: string
  strasse: string
  hausnummer: string
  plz: number
  ort: string
  zusatz: string
  mail: string
  telefon: string
  mobile: string
  passworthash: string
  lastPasswordChange: number
  kontoinhaber: string
  iban: string
  bic: string
  bank: string
  rolle: Rolle
  newsletter: boolean
  kinder: IKind[]
}



export function isEltern(obj: Object): obj is IEltern {
  function d(value: string): boolean{
    return value !== undefined;
  }

  return obj &&
    d(obj["vorname"]) &&
    d(obj["nachname"]) &&
    d(obj["strasse"]) &&
    d(obj["hausnummer"]) &&
    d(obj["plz"]) &&
    d(obj["ort"]) &&
    d(obj["zusatz"]) &&
    d(obj["mail"]) &&
    d(obj["telefon"]) &&
    d(obj["mobile"]) &&
    d(obj["passworthash"]) &&
    d(obj["lastPasswordChange"]) &&
    d(obj["kontoinhaber"]) &&
    d(obj["iban"]) &&
    d(obj["bic"]) &&
    d(obj["bank"]) &&
    d(obj["rolle"]) &&
    d(obj["newsletter"]) &&
    d(obj["kinder"])
}

export function getElternKeyPrettyName(key: keyof IEltern): string {
  switch (key) {
    case "vorname":
      return "Vorname";
    case "nachname":
      return "Nachname";
    case "strasse":
      return "Straße";
    case "hausnummer":
      return "Hausnummer";
    case "plz":
      return "Postleitzahl";
    case "ort":
      return "Ort";
    case "zusatz":
      return "Adresszusatz";
    case "mail":
      return "Mail";
    case "telefon":
      return "Telefon";
    case "mobile":
      return "Mobil";
    case "passworthash":
      return "Passwort";
    case "lastPasswordChange":
      return "Letzte";
    case "kontoinhaber":
      return "Kontoinhaber";
    case "iban":
      return "IBAN";
    case "bic":
      return "BIC";
    case "bank":
      return "Bank";
    case "rolle":
      return "Rolle";
    case "newsletter":
      return "Newsletter";
    case "kinder":
      return "Kinder";
    default: return getAbstractModelKeyPrettyName(key);
  }
}

export function getElternSearchCategories() {

  return [
    { display: "Allgemein", key: "Allgemein", type: "CAT" },
    { key: "vorname", type: "string" },
    { key: "nachname", type: "string" },
    { key: "rolle", type: "string" },
    { key: "kinder", type: "IKind[]" },
    { key: "id", type: "number" },

    { display: "Kontakt", key: "Allgemein", type: "CAT" },
    { key: "strasse", type: "string" },
    { key: "hausnummer", type: "string" },
    { key: "plz", type: "number" },
    { key: "ort", type: "string" },
    { key: "zusatz", type: "string" },
    { key: "mail", type: "string" },
    { key: "telefon", type: "string" },
    { key: "mobile", type: "string" },

    { display: "Bank", key: "Allgemein", type: "CAT" },
    { key: "kontoinhaber", type: "string" },
    { key: "iban", type: "string" },
    { key: "bic", type: "string" },
    { key: "bank", type: "string" },

    { display: "Newsletter", key: "Allgemein", type: "CAT" },
    { key: "newsletter", type: "boolean" },

  ].map(v => {
    if (v.display == null) {
      v.display = getElternKeyPrettyName(v.key as keyof IEltern);
    }
    return v;
  });
}
