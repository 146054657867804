import { Component } from '@angular/core';
import { ProductionService } from '../service/production.service';
import { SessionService } from '../service/session.service';
import {EventQueueService} from "../service/event-queue-service.service";

@Component({
  selector: 'app-headline',
  templateUrl: './headline.component.html',
  styleUrls: ['./headline.component.scss']
})
export class HeadlineComponent{

  public dev = ProductionService.IS_DEVELOP;
  public isMa!: boolean;
  public isLoggedIn!: boolean

  constructor(
    public readonly sessionService: SessionService,
    public readonly eventQueue: EventQueueService
  ) {
    this.isMa = sessionService.isMa();
    this.isLoggedIn = !!sessionService.session.nutzer

    this.eventQueue.getObservable().subscribe(async msg=>{
      if(msg === "initDone"){
        this.isMa = this.sessionService.isMa();
        this.isLoggedIn = !!this.sessionService.session.nutzer
      }
    });
  }

}
