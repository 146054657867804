import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as sha1 from 'js-sha1';
import { ActivatedRoute, Router } from '@angular/router';
import {ElternService} from "../../service/eltern.service";



@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.scss']
})
export class RecoveryComponent implements OnInit {

  public token = "";
  public username = "";
  public password = "";
  public passwordCheck = "";
  public passwordValid = false;

  constructor(
    private elternService: ElternService,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe(prams => {
      this.token = prams['token'];
    });
  }

  ngOnInit() {
  }

  public async setPassword(){
    if(!this.passwordValid) {
      this.toast.error("Das Passwort erfüllt nicht die Richtlinien", "Fehler");
      return;
    }
    if(this.password !== this.passwordCheck){
      this.toast.error("Die Passwörter sind nicht identisch", "Fehler");
      return;
    }
    if(!this.password){
      this.toast.error("Bitte gib ein Passwort ein", "Fehler");
      return;
    }
    if(!this.username){
      this.toast.error("Bitte gib deinen Nutzernamen ein", "Fehler");
      return;
    }
    if(!this.token){
      this.toast.error("Der Link ist ungültig", "Fehler");
      return;
    }
    try{
      const result = await this.elternService.setPasswordFromRecovery(this.username, this.token, sha1(this.password))
        if(result.success){
          this.toast.success("Passwort wurde erfolgriech gesetzt", "Erfolgreich");
          this.router.navigateByUrl('/home');
        } else {
          this.toast.error(result.error, "Fehler");
        }
    } catch(err) {
      this.toast.error("Fehler beim Setzen des Passworts", "Fehler");
    }
    this.password = "";
    this.passwordCheck = "";
  }
}
