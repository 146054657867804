import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ElternService} from "../../service/eltern.service";
import {KindService} from "../../service/kind.service";
import {IKind} from "../../models/IKind.interface";
import {Geschlecht, Schwimmabzeichen} from "../../models/enums";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-new-kind',
  templateUrl: './new-kind.component.html',
  styleUrls: ['./new-kind.component.scss']
})
export class NewKindComponent implements OnInit {

  kind:IKind = {
    id: -1,
    created: 0,
    edited: 0,
    vorname: "",
    nachname: "",
    geschlecht: Geschlecht.DIVERS,
    strasse: "",
    hausnummer: "",
    ort: "",
    plz: 24983,
    zusatz: "",
    geburtsdatum: null,
    tel: "",
    mobile: "",
    soziPass: "",
    soziFile: "",
    krankheiten: "",
    medikamente: "",
    allergien: "",
    schwimmabzeichen: Schwimmabzeichen.KEINS,
    bemerkung: "",
    laktose: false,
    vegetarier: false,
    veganer: false,
    eltern: null,
    aktivierungsdatum: null,
    rechnungGesendet: null,
    anmeldungen: [],
    bezahlterBetrag:  0,
    rechnungen: []
  };
  public transferleistung = false;


  constructor(private readonly route:ActivatedRoute,
              private readonly elternService:ElternService,
              private readonly kinderService:KindService,
              private readonly toastrService:ToastrService,
              private readonly router:Router) {
    this.route.params.subscribe(async prams => {
      this.kind.eltern = this.elternService.getById(Number.parseInt(prams['id']));
    });
  }

  ngOnInit(): void {
  }

  async saveKind():Promise<void>{
    if(this.transferleistung){
      if(!this.kind.soziPass) {
        this.toastrService.error("Bitte geben Sie eine Bildungspassnummer ein!", "Passnummer fehlt!");
        return;
      }
      if(!this.kind.soziFile){
        this.toastrService.error("Bitte laden Sie Ihren Bildungspass hoch!","Pass fehlt!");
        return;
      }
    }else{
      this.kind.soziPass = null;
      this.kind.soziFile = null;
    }
    try{
      let result = await this.kinderService.create(this.kind);
      this.toastrService.success(result,"Erfolgreich");
      await this.router.navigate(["/settings/elternList"]);

    }catch(exc){
      this.toastrService.error(exc,"Fehler");
    }
  }

}
