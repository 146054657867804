import {Component, OnInit} from '@angular/core';
import {AnmeldungService} from "../../service/anmeldung.service";

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  anmeldungenAktiv = false;
  anmeldungenDatum!: number;

  constructor(
    private readonly anmeldungService: AnmeldungService,
  ) {
    this.anmeldungenAktiv = this.anmeldungService.isAnmeldungActive();
    this.anmeldungenDatum = this.anmeldungService.getAnmeldungsbeginn();
  }

  ngOnInit(): void {
  }

}
