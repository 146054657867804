<div class="container py-4">
  <h2 class="text-center">Neues Kind anlegen</h2>
  <h5 class="text-center">{{kind.eltern?.vorname || ""}} {{kind.eltern?.nachname || ""}}</h5>
  <app-kind-editor [kind]="kind" *ngIf="kind.eltern" [(transferleistung)]="transferleistung"></app-kind-editor>
  <p *ngIf="!kind.eltern">Eltern konnte nicht geladen werden!</p>
</div>
<div style="height:55px;"></div>
<div class="fixed-bottom secondaryBgColor d-flex justify-content-center justify-content-lg-end">
  <button class="btn pcButton m-2" (click)="saveKind()">Speichern</button>
</div>

