<div class="container">
  <h1>Kostenübersicht</h1>
  <h4>{{aktion.id}} - {{aktion.titel}}</h4>

  <i *ngIf="loading" class="fas fa-circle-notch fa-spin fa-10x" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)"></i>

  <table class="table" style="table-layout: fixed">
    <tr>
      <th>Titel</th>
      <th style="width: 100px" class="text-right">Betrag</th>
      <th style="width: 130px" class="text-right">
        <button class="btn pcButton mx-1" (click)="editLine = -1"><i class="fas fa-plus"></i></button>
      </th>
    </tr>
    <tr *ngFor="let cost of sortedCosts">
      <ng-container *ngIf="editLine !== cost.id ">
        <td>{{cost.beschreibung}}</td>
        <td class="text-right">{{cost.preis.toFixed(2)}} €</td>
        <td class="text-right">
          <button class="btn pcButton mx-1" (click)="editLine=cost.id"><i class="fas fa-edit"></i></button>
          <button class="btn pcButton mx-1" (click)="delete(cost)"><i class="fas fa-trash"></i></button>
        </td>
      </ng-container>
      <ng-container *ngIf="editLine === cost.id">
        <td><input class="w-100" type="text" [(ngModel)]="cost.beschreibung"></td>
        <td><input class="w-100" type="number" [(ngModel)]="cost.preis"></td>
        <td class="text-right">
          <button class="btn pcButton mx-1" (click)="saveEdit(cost)"><i class="fas fa-save"></i></button>
          <button class="btn pcButton mx-1" (click)="editLine = undefined"><i class="fas fa-times"></i></button>
        </td>
      </ng-container>
    </tr>
    <tr *ngIf="editLine === -1">
      <td><input class="w-100" type="text" [(ngModel)]="newDesc"></td>
      <td><input class="w-100" type="number" [(ngModel)]="newPrice"></td>
      <td class="text-right">
        <button class="btn pcButton mx-1" (click)="saveCreate()"><i class="fas fa-save"></i></button>
        <button class="btn pcButton mx-1" (click)="editLine = undefined; newDesc = ''; newPrice = 0"><i class="fas fa-times"></i></button>
      </td>
    </tr>
    <tr>
      <th>Gesamtkosten</th>
      <th>{{sum.toFixed(2)}} €</th>
      <th></th>
    </tr>
  </table>
</div>
<div style="height:55px;"></div>
<div class="fixed-bottom secondaryBgColor d-flex justify-content-center justify-content-lg-end">
  <button class="btn pcButton m-2" routerLink="/aktion">Zurück</button>
</div>
