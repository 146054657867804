import {Component, OnInit} from '@angular/core';
import {SessionService} from "../../service/session.service";
import {EventQueueService} from "../../service/event-queue-service.service";
import {IAnmeldung, PASS_PREIS, SOZI_PASS_PREIS} from "../../models/IAnmeldung.interface";
import {getAktionSearchCategories, IAktion} from "../../models/IAktion.interface";
import {IKind} from "../../models/IKind.interface";
import {IActionButton} from "../../utils/filter/buttons";
import {AktionService} from "../../service/aktion.service";
import {KindService} from "../../service/kind.service";
import {FilterEngineService} from "../../service/filter-engine.service";
import {AnmeldungService} from "../../service/anmeldung.service";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {AnmeldungStatus} from 'src/app/models/enums';

const SEKUNDE = 1000;
const MINUTE = 60 * SEKUNDE;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const YEAR = 365 * DAY;

@Component({
  selector: 'app-kind-buchen',
  templateUrl: './kind-buchen.component.html',
  styleUrls: ['./kind-buchen.component.scss']
})
export class KindBuchenComponent implements OnInit {
  public isMa!: boolean;

  soziPassPreis = SOZI_PASS_PREIS;
  passPreis = PASS_PREIS;

  sozi!: boolean
  aktionen: IAktion[];
  status: AnmeldungStatus[];
  kind: IKind;
  query = "";
  categories = getAktionSearchCategories();
  actionButtons: IActionButton[] = [];
  saveActive = false;
  AnmeldungStatus = AnmeldungStatus;
  anmeldungenAktiv = false;
  anmeldungenDatum!: number;
  navigateOnInitDone = false;

  constructor(
    private readonly aktionService: AktionService,
    private readonly kindService: KindService,
    private readonly filterService: FilterEngineService,
    private readonly anmeldungService: AnmeldungService,
    private readonly toastrService: ToastrService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public readonly sessionService: SessionService,
    public readonly eventQueue: EventQueueService,
  ) {
    this.anmeldungenAktiv = this.anmeldungService.isAnmeldungActive();
    this.anmeldungenDatum = this.anmeldungService.getAnmeldungsbeginn();

    this.isMa = sessionService.isMa();

    this.route.paramMap.subscribe(params => {
      const id = parseInt(params.get("id"));
      this.kind = this.kindService.getById(id);
      this.sozi = !!this.kind.soziPass;

      this.status = [];
      for(const a of this.kind.anmeldungen) {
        switch (a.status) {
          case AnmeldungStatus.ANGEMELDET:
          case AnmeldungStatus.VORANGEMELDET:
            if(a.aktion.maxKinder <= a.aktion.anmeldungen.filter(a => a.status === AnmeldungStatus.GEBUCHT).length) {
              this.status[a.aktion.id] = AnmeldungStatus.WARTELISTE;
            } else {
              this.status[a.aktion.id] = AnmeldungStatus.GEBUCHT;
            }
            break;
          case AnmeldungStatus.GEBUCHT:
              this.status[a.aktion.id] = AnmeldungStatus.GEBUCHT;
              break;
          case AnmeldungStatus.ABGEMELDET:
              this.status[a.aktion.id] = AnmeldungStatus.ABGEMELDET;
              break;
          case AnmeldungStatus.WARTELISTE:
              this.status[a.aktion.id] = AnmeldungStatus.WARTELISTE;
              break;
        }
      }

    });

    this.eventQueue.getObservable().subscribe(async msg=>{
      if(msg === "initDone"){
        this.aktionen = (await this.filterService.filter(this.query,this.aktionService.getList(), "aktionenListRefresh")).sort((a1, a2) => a1.start - a2.start);
        this.isMa = this.sessionService.isMa();
        this.updateSelections();

        if(this.navigateOnInitDone) {
          this.saveActive = false;
          this.router.navigate(["/anmelden/kinderBuchen"]);
        }
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.aktionen = (await this.filterService.filter(this.query,this.aktionService.getList(), "aktionenListInit")).sort((a1, a2) => a1.start - a2.start);
    this.isMa = this.sessionService.isMa();
    this.updateSelections();
  }

  private updateSelections() {
  }

  public getAnmeldung(aktion: IAktion): IAnmeldung {
    return  this.kind.anmeldungen.filter(a => a.aktion.id === aktion.id)[0]
  }

  async updateFilter():Promise<void>{
    this.aktionen = (await this.filterService.filter(this.query,this.aktionService.getList(), "aktionenListInputChange")).sort((a1, a2) => a1.start - a2.start);
    this.updateSelections();
  }

  getClass(a: IAktion) {
    const max = a.maxKinder;
    const ist = a.anmeldungen.filter(an => an.status === AnmeldungStatus.GEBUCHT).length;

    const rel = ist / max;

    if(rel < 0.8) {
      return "white";
    }
    if(rel < 1) {
      return "yellow";
    }
    return "red";
  }

  async saveAnmeldungen() {

    for(const a of this.aktionen) {
      const anmeldung: IAnmeldung = this.getAnmeldung(a)
      const zielStatus = this.status[a.id];
      if(zielStatus && (!anmeldung || anmeldung.status !== zielStatus)) {
        //Es gab eine Änderung
        this.saveActive = true;
        try{
          if(anmeldung) {
            anmeldung.status = zielStatus;
            await this.anmeldungService.update(anmeldung);
          } else {
            await this.anmeldungService.create({
              status: zielStatus,
              aktion: a,
              kind: this.kind,
              bezahlt: false,
              historie: [],
              id: -1,
              created: -1,
              edited: -1
            });
          }
          this.toastrService.success(this.kind.vorname + " wurde an " + a.titel + " aktualisiert.");
        } catch (err) {
          console.error(err);
          this.toastrService.error(this.kind.vorname + " konnte nicht an " + a.titel + " aktualisiert werden.")
        }
      }
    }
    if(this.saveActive){
      this.navigateOnInitDone = true;
    }else {
      this.toastrService.info("Es wurden keine \u00c4nderungen gemacht. Daher wurde auch nichts gespeichert.","Keine \u00c4nderung");    }
  }

  calcPrice(): string {
    const sozi = !!this.kind.soziPass;
    let price = 0;
    for(const a of this.aktionen){
      if(this.status[a.id] === AnmeldungStatus.GEBUCHT) {
        price += sozi ? a.soziPreis : a.preis;
      }
    }
    if(price > 0) {
      price = price + (sozi ? this.soziPassPreis: this.passPreis)
    }
    return price.toFixed(2);
  }

  hasAnmeldung() {
    return this.status.some(s => s === AnmeldungStatus.GEBUCHT)
  }

  isBlocked(aktion: IAktion) {
    for(const a of this.aktionen) {
      if(a.id === aktion.id) {
        continue;
      }
      if(this.status[a.id] === AnmeldungStatus.GEBUCHT) {
        if(this.overlaps(a, aktion)){
          return true;
        }
      }
    }
    return false;
  }



  matchesAge(aktion: IAktion) {
    const geb = this.kind.geburtsdatum;
    const start = aktion.start;
    const minAge = aktion.minAlter * YEAR;
    const maxAge = (aktion.maxAlter + 1) * YEAR;

    return geb < (start - minAge) && geb > (start - maxAge)
  }


  private overlaps(aktion1: IAktion, aktion2: IAktion) {
    return aktion1.start < aktion2.ende && aktion1.ende > aktion2.start;
  }

  checkboxClicked(state: AnmeldungStatus, a: IAktion) {
    let targetState = state;
    if(this.status[a.id] === state) {
      targetState = AnmeldungStatus.ABGEMELDET;
    }
    this.status[a.id] = targetState;
  }

  aktionList(a: IAktion): {
    vor: number,
    ang: number,
    geb: number,
    wList: number,
    abGem: number,
  } {
    let vor = 0, ang = 0, geb = 0, wList = 0, abGem = 0;
    for(const an of a.anmeldungen) {
      switch (an.status) {
        case AnmeldungStatus.GEBUCHT:
          geb++; break;
        case AnmeldungStatus.VORANGEMELDET:
          vor++; break;
        case AnmeldungStatus.ANGEMELDET:
          ang++; break;
        case AnmeldungStatus.WARTELISTE:
          wList++; break;
        case AnmeldungStatus.ABGEMELDET:
          abGem++; break;
      }
    }

    return {
      vor,
      ang,
      geb,
      wList,
      abGem
    }
  }

  isFull(a: IAktion) {
    return a.anmeldungen.filter(an => an.status === AnmeldungStatus.GEBUCHT).length >= a.maxKinder
  }
}
