import {BackendService} from "./backend.service";
import {SessionService} from "./session.service";
import {JsonOf, WriteReturnType} from "../utils/types/backendTypes";
import {IAktion} from "../models/IAktion.interface";
import {AbstractService} from "./abstract.service";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class AktionService extends AbstractService<IAktion>{

  public fromJsonObj<T>(obj: JsonOf<T>):T{
    BackendService.updateIdToProperty(obj, "anmeldungen", (id)=> (window as any).anmeldungService.getById(id), true, () => (window as any).anmeldungService.getRevision());
    BackendService.updateIdToProperty(obj, "ausgaben", (id)=> (window as any).ausgabenService.getById(id), true, () => (window as any).ausgabenService.getRevision());
    BackendService.updateIdToProperty(obj, "icon", (id)=> (window as any).iconService.getById(id), false, () => (window as any).iconService.getRevision());
    return obj as unknown as T;
  }

  constructor(
    backend: BackendService,
    sessionService: SessionService) {
    super(
      backend,
      sessionService,
      "getAktion",
      "createAktion",
      "updateAktion",
      "deleteAktion"
    );
  }

  async setActiveAll(aktiv: boolean): Promise<WriteReturnType> {
    return await this.backend.sendRequest("setActiveAll", {
      sessionId: this.sessionService.session.sessionId,
      aktiv: aktiv
    }, true);
  }
}
