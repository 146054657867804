import { Component } from '@angular/core';
import { CheatServiceService } from './service/cheat-service.service';
import { EventQueueService } from './service/event-queue-service.service';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from './service/session.service';
import {AktionService} from "./service/aktion.service";
import {AnmeldungService} from "./service/anmeldung.service";
import {AnmeldungHistoryService} from "./service/anmeldungs-history.service";
import {AusgabenService} from "./service/ausgaben.service";
import {ElternService} from "./service/eltern.service";
import {IconService} from "./service/icon.service";
import {KindService} from "./service/kind.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Click Dummy';

  public i = 0;
  public readonly serviceCount = 6;

  constructor(
    cheatServiceInit: CheatServiceService, //Wichtig, damit der überhapt geladen wird
    private aktionService: AktionService,
    private anmeldungService: AnmeldungService,
    private anmeldungHistoryService: AnmeldungHistoryService,
    private ausgabenService: AusgabenService,
    private elternService: ElternService,
    private iconService: IconService,
    private kindService: KindService,
    sessionService: SessionService,
    private eventQueue: EventQueueService,
    private toastr: ToastrService
    ){
      toastr.toastrConfig.positionClass = "toast-bottom-right";
      toastr.toastrConfig.closeButton = true;
      toastr.toastrConfig.preventDuplicates = true;
      toastr.toastrConfig.countDuplicates = true;
      toastr.toastrConfig.resetTimeoutOnDuplicate = true;
      toastr.toastrConfig.enableHtml = true;

      const init = () => {
        const serviceInitDone = () => {
          this.i++;
          if(this.i === this.serviceCount){
            this.eventQueue.fireEvent("initDone");
          }
        }

        this.aktionService.updateData().then(serviceInitDone);
        this.anmeldungService.updateData().then(serviceInitDone);
        this.ausgabenService.updateData().then(serviceInitDone);
        this.elternService.updateData().then(serviceInitDone);
        this.iconService.updateData().then(serviceInitDone);
        this.kindService.updateData().then(serviceInitDone);
      }

      sessionService.restoreSession().then(init).catch(init);
  }

}
