import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from '../service/session.service';
import * as sha1 from 'js-sha1';
import {ElternService} from "../service/eltern.service";

@Component({
  selector: 'app-home',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {



  public username = "";
  public password = "";
  public email = "";
  public mailIsSending = false;

  constructor(
    private elternService: ElternService,
    private toast: ToastrService,
    public sessionService: SessionService
  ) { }

  ngOnInit() {
  }
  public login() {
    if (this.username !== "" && this.password !== "") {
      this.sessionService.login(this.username, sha1(this.password));
      this.password = "";
    } else {
      this.toast.error("Keine gültigen Eingaben bei der Anmeldung!", "Üngültige Eingabe");
    }

  }

  public logout() {
    this.sessionService.logout();
    this.username = "";
  }

  public sendPasswordRecoveryMail() {
    if(this.mailIsSending){
      this.toast.warning("Es wird bereits eine E-Mail gesendet", "Bitte warten");
      return;
    }
    this.mailIsSending = true;
    this.elternService.sendPasswordRecoveryMail(this.email).then(() => {
      this.toast.success("Wenn die Adresse " + this.email + " im System hinterlegt ist, wurde jetzt eine E-Mail versendet.", "Passwort Wiederherstellung");
      this.email = "";
      this.mailIsSending = false;
    }
    ).catch(() => {
      this.toast.error("Es ist ein Fehler aufgetreten.", "Passwort Wiederherstellung");
      this.mailIsSending = false;
    });
  }
}
