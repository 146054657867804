<div class="container">
  <h1>Impressum</h1>

  <h2>Angaben gemäß § 5 TMG</h2>
  <p>Trägerverein offene Jugendarbeit e.V.<br />
    Alter Kirchenweg 64<br />
    24983 Handewitt</p>

  <p>Vereinsregister: VR1872 FL<br />
    Registergericht: Flensburg</p>

  <p><strong>Vertreten durch:</strong><br />
    Diakon Kai Boisen</p>

  <h2>Kontakt</h2>
  <p>Telefon: 046089720960<br />
    E-Mail: kontakt@toj-online.de</p>

  <h2>Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</h2>
  <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
</div>
