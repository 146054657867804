import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-input-iban',
  templateUrl: './input-iban.component.html',
  styleUrls: ['./input-iban.component.scss']
})
export class InputIbanComponent implements OnInit {

  @Input() public required = false;
  @Input() public tooltip = "";
  @Input() public name!: string;
  @Input() public val!: string;
  @Output() public valChange: EventEmitter<string> = new EventEmitter<string>()

  public valid = true;
  public ibanChecksumValid = true;
  public placeholder = "DE42 0000 0000 0000 0000 00";

  private static regex = /[A-Z][A-Z]\d\d(:? ?\d\d\d\d){4} ?\d\d/;

  constructor() {
  }

  ngOnInit(): void {
    console.log("Hallo")
  }

  public valAsStr(): string {
    let ret = "";
    for(let i = 0; i < this.val.length; i += 4) {
      let substr = this.val.substr(i, 4);
      if(substr.length === 4) {
        ret += substr + " ";
      } else {
        ret += substr;
      }
    }
    return ret;
  }

  private isValid(str: string): boolean {
    const regexResult = InputIbanComponent.regex.exec(str);
    return regexResult != null;
  }

  public setFromStr(str: string): void {
    str = str.replace(/ /g, "");
    this.val = str;
    if(!this.isValid(str)) {
      this.valid = false;
      this.ibanChecksumValid = true;
      return;
    }
    this.valid = true;
    this.ibanChecksumValid = this.isIBAN(str);
    if(!this.ibanChecksumValid) {
      return;
    }

    this.valChange.emit(this.val);
  }

  private isIBAN(s: string){
    const rearranged = s.substring(4,s.length) + s.substring(0,4);
    const numeric   = Array.from(rearranged).map(c =>(isNaN(parseInt(c)) ? (c.charCodeAt(0)-55).toString() : c)).join('');
    const remainder = Array.from(numeric).map(c => parseInt(c)).reduce((remainder, value) => (remainder * 10 + value) % 97,0);

    return remainder === 1;
  }
}
