import {getAbstractModelKeyPrettyName, IAbstractModel} from "./IAbstractModel.interface";
import {IAktion} from "./IAktion.interface";

export interface IAusgaben extends IAbstractModel{
  preis: number
  beschreibung: string
  aktion: IAktion
}



export function isAusgaben(obj: Object): obj is IAusgaben {
  function d(value: string): boolean{
    return value !== undefined;
  }

  return obj &&
    d(obj["preis"]) &&
    d(obj["beschreibung"]) &&
    d(obj["aktion"])
}

export function getAusgabenKeyPrettyName(key: keyof IAusgaben): string {
  switch (key) {
    case "preis":
      return "Preis";
    case "beschreibung":
      return "Beschreibung";
    case "aktion":
      return "Aktion";
    default: return getAbstractModelKeyPrettyName(key);
  }
}

export function getAusgabenSearchCategories() {

  return [
    { display: "Allgemein", key: "Allgemein", type: "CAT" },
    { key: "preis", type: "number" },
    { key: "beschreibung", type: "string" },
    { key: "aktion", type: "IAktion" },
    { key: "id", type: "number" },
  ].map(v => {
    if (v.display == null) {
      v.display = getAusgabenKeyPrettyName(v.key as keyof IAusgaben);
    }
    return v;
  });
}

