import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProductionService {

  public static IS_PRODUCTION = window.location.hostname.indexOf("ferienpass.toj-online.de") !== -1 &&  window.location.hostname.indexOf("test-ferienpass.toj-online.de") === -1;
  public static IS_DEVELOP = !ProductionService.IS_PRODUCTION;

  constructor() { }

  public static isProduction(): boolean{
    return ProductionService.IS_PRODUCTION;
  }

  public static isDevelop(): boolean{
    return ProductionService.IS_DEVELOP;
  }

  public isProduction(): boolean{
    return ProductionService.IS_PRODUCTION;
  }

  public isDevelop(): boolean{
    return ProductionService.IS_DEVELOP;
  }

}
