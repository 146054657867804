import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeadlineComponent } from './headline/headline.component';
import { LoginStatusComponent } from './headline/login-status/login-status.component';
import { AgeFilterPipe } from './filters/age-filter.pipe';
import {FormsModule} from '@angular/forms';
import { FilterComponent } from './utils/filter/filter.component';
import { IconFilterPipe } from './filters/icon-filter.pipe';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { LoadingGifComponent } from './utils/loading-gif/loading-gif.component';
import { SingleDatalistComponent } from './utils/single-datalist/single-datalist.component';
import { LoginComponent } from './home/login.component';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { RegistrationComponent } from './home/registration/registration.component';
import { ConfirmWindowComponent } from './utils/confirm-window/confirm-window.component';
import { SafePipePipe } from './filters/safe-pipe.pipe';
import { RecoveryComponent } from './home/recovery/recovery.component';
import { DatenschutzComponent } from './home/datenschutz/datenschutz.component';
import { ImpressumComponent } from './home/impressum/impressum.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { PasswordCheckComponent } from './utils/password-check/password-check.component';
import { InputComponent } from './utils/input/input.component';
import { InputDateComponent } from './utils/input-date/input-date.component';
import { InputIbanComponent } from './utils/input-iban/input-iban.component';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import { SubnavComponent } from './headline/subnav/subnav.component';
import { ElternListComponent } from './settings/eltern-list/eltern-list.component';
import { NewAktionComponent } from './aktion/new-aktion/new-aktion.component';
import { EditAktionComponent } from './aktion/edit-aktion/edit-aktion.component';
import { AktionEditorComponent } from './aktion/aktion-editor/aktion-editor.component';
import { AktionsListComponent } from './aktion/aktions-list/aktions-list.component';
import { AktionDatePipe } from './filters/aktion-date.pipe';
import { AktionAlterPipe } from './filters/aktion-alter.pipe';
import { AktionCostListComponent } from './aktion/aktion-cost-list/aktion-cost-list.component';
import { ActivateAccountComponent } from './home/activate-account/activate-account.component';
import { NewKindComponent } from './settings/new-kind/new-kind.component';
import { KindEditorComponent } from './settings/kind-editor/kind-editor.component';
import { IconsComponent } from './settings/icons/icons.component';
import { IconSelectorComponent } from './utils/icon-selector/icon-selector.component';
import { EditKindComponent } from './settings/edit-kind/edit-kind.component';
import { EditElternComponent } from './settings/edit-eltern/edit-eltern.component';
import { ElternAnmeldungListComponent } from './anmeldung/eltern-anmeldung-list/eltern-anmeldung-list.component';
import { DatumPipe } from './filters/datum.pipe';
import { ZeitPipe } from './filters/zeit.pipe';
import { AnmeldungConfirmComponent } from './anmeldung/anmeldung-confirm/anmeldung-confirm.component';
import { SystemSettingsComponent } from './settings/system-settings/system-settings.component';
import { HelpComponent } from './home/help/help.component';
import { KindBuchenComponent } from './anmeldung/kind-buchen/kind-buchen.component';
import { KinderBuchenComponent } from './anmeldung/kinder-buchen/kinder-buchen.component';
import { AnmeldungsHistoryComponent } from './anmeldung/anmeldungs-history/anmeldungs-history.component';
import { AktionKinderListComponent } from './aktion/aktion-kinder-list/aktion-kinder-list.component';

@NgModule({
  declarations: [
    AppComponent,
    HeadlineComponent,
    LoginStatusComponent,
    AgeFilterPipe,
    FilterComponent,
    IconFilterPipe,
    LoadingGifComponent,
    SingleDatalistComponent,
    LoginComponent,
    RegistrationComponent,
    ConfirmWindowComponent,
    SafePipePipe,
    RecoveryComponent,
    DatenschutzComponent,
    ImpressumComponent,
    PasswordCheckComponent,
    InputComponent,
    InputDateComponent,
    InputIbanComponent,
    InputComponent,
    SubnavComponent,
    ElternListComponent,
    NewAktionComponent,
    EditAktionComponent,
    AktionEditorComponent,
    AktionsListComponent,
    AktionDatePipe,
    AktionAlterPipe,
    AktionCostListComponent,
    ActivateAccountComponent,
    NewKindComponent,
    KindEditorComponent,
    IconsComponent,
    IconSelectorComponent,
    EditKindComponent,
    EditElternComponent,
    ElternAnmeldungListComponent,
    DatumPipe,
    ZeitPipe,
    AnmeldungConfirmComponent,
    SystemSettingsComponent,
    HelpComponent,
    KindBuchenComponent,
    KinderBuchenComponent,
    AnmeldungsHistoryComponent,
    AktionKinderListComponent,
  ],
  entryComponents: [
    ConfirmWindowComponent,
  ],
  imports: [
    NgxPaginationModule,
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot()
  ],
  providers: [
    AgeFilterPipe,
    AktionDatePipe,
    DatumPipe,
    HttpClient
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
