import {getAbstractModelKeyPrettyName, IAbstractModel} from "./IAbstractModel.interface";
import {IKind} from "./IKind.interface";

export interface IRechnung extends IAbstractModel{
  kind: IKind
  pdf: string | undefined
  betrag: number
  status: string
}


export function isRechnung(obj: Object): obj is IRechnung {
  function d(value: string): boolean{
    return value !== undefined;
  }

  return obj &&
    d(obj["kind"]) &&
    d(obj["pdf"]) &&
    d(obj["betrag"]) &&
    d(obj["status"])
}

export function getRechnungKeyPrettyName(key: keyof IRechnung): string {
  switch (key) {
    case "kind":
      return "Kind";
    case "pdf":
      return "PDF";
    case "betrag":
      return "Betrag";
    case "status":
      return "Rechnungsstatus";
    default: return getAbstractModelKeyPrettyName(key);
  }
}


