import {Component, OnDestroy, OnInit} from '@angular/core';
import {AktionService} from "../../service/aktion.service";
import {getAktionSearchCategories, IAktion} from "../../models/IAktion.interface";
import {FilterEngineService} from "../../service/filter-engine.service";
import {EventQueueService} from "../../service/event-queue-service.service";
import {AnmeldungStatus, Rolle} from "../../models/enums";
import {SessionService} from "../../service/session.service";
import {IActionButton} from "../../utils/filter/buttons";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {AnmeldungService} from "../../service/anmeldung.service";
import {KindService} from "../../service/kind.service";

@Component({
  selector: 'app-aktions-list',
  templateUrl: './aktions-list.component.html',
  styleUrls: ['./aktions-list.component.scss']
})
export class AktionsListComponent implements OnInit, OnDestroy {

  aktionen: IAktion[];
  query = "";
  categories = getAktionSearchCategories();
  isMa = false;
  actionButtons: IActionButton[] = [
    {
      mobile: false,
      title: "Alle Aktivieren",
      value: "<i class=\"fas fa-check-square\"></i>",
      callback: () => this.activateAll(),
      rolle: [Rolle.MA, Rolle.ADM]
    }, {
      mobile: false,
      title: "Alle Deaktivieren",
      value: "<i class=\"fas fa-square\"></i>",
      callback: () => this.deactivateAll(),
      rolle: [Rolle.MA, Rolle.ADM]
    }, {
      mobile: true,
      title: "Neue Veranstaltung",
      value: '<i class="fas fa-plus"></i>',
      callback: () => this.router.navigate(["/aktion/new"]),
      rolle: [Rolle.MA, Rolle.ADM]
    }
  ];

  anmeldungenAktiv = false;
  anmeldungenDatum!: number;
  kinderZumAnmelden: boolean = false;
  timeout: any

  constructor(
    private readonly aktionService: AktionService,
    private readonly filterService: FilterEngineService,
    private readonly eventQueue: EventQueueService,
    private readonly sessionService: SessionService,
    private readonly toastrService: ToastrService,
    private readonly router: Router,
    private readonly anmeldungService: AnmeldungService,
    private readonly kindService: KindService
  ) {
    this.updateAnmTimeStatus();
  }

  private updateAnmTimeStatus() {
    this.anmeldungenAktiv = this.anmeldungService.isAnmeldungActive();
    this.anmeldungenDatum = this.anmeldungService.getAnmeldungsbeginn();
    if (this.anmeldungenAktiv) {
      this.kinderZumAnmelden = this.kindService
        .getList()
        .filter(k => k.eltern.id === this.sessionService.session.nutzer.id)
        .some(k => k.anmeldungen.some(a => a.status === AnmeldungStatus.VORANGEMELDET));
    }
    if(!this.anmeldungenAktiv) {
      this.timeout = setTimeout(() => {
        this.updateAnmTimeStatus()
      }, this.anmeldungenDatum - Date.now());
    }
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeout);
  }

  async ngOnInit(): Promise<void> {
    this.aktionen = (await this.filterService.filter(this.query,this.aktionService.getList(), "aktionenListInit")).sort((a1, a2) => a1.start - a2.start);
    this.isMa = this.sessionService.isMa();
    this.eventQueue.getObservable().subscribe(async msg=>{
      if(msg === "initDone"){
        this.aktionen = (await this.filterService.filter(this.query,this.aktionService.getList(), "aktionenListRefresh")).sort((a1, a2) => a1.start - a2.start);
        this.isMa = this.sessionService.isMa();
      }
    })
  }


  async updateFilter():Promise<void>{
    this.aktionen = (await this.filterService.filter(this.query,this.aktionService.getList(), "aktionenListInputChange")).sort((a1, a2) => a1.start - a2.start);
  }

  getClass(a: IAktion) {
    const max = a.maxKinder;
    const ist = a.anmeldungen.filter(an => an.status === AnmeldungStatus.GEBUCHT).length;

    const rel = ist / max;

    if(rel < 0.8) {
      return "white";
    }
    if(rel < 1) {
      return "yellow";
    }
    return "red";
  }

  aktionList(a: IAktion): {
    vor: number,
    ang: number,
    geb: number,
    wList: number,
    abGem: number,
  } {
    let vor = 0, ang = 0, geb = 0, wList = 0, abGem = 0;
    for(const an of a.anmeldungen) {
      switch (an.status) {
        case AnmeldungStatus.GEBUCHT:
          geb++; break;
        case AnmeldungStatus.VORANGEMELDET:
          vor++; break;
        case AnmeldungStatus.ANGEMELDET:
          ang++; break;
        case AnmeldungStatus.WARTELISTE:
          wList++; break;
        case AnmeldungStatus.ABGEMELDET:
          abGem++; break;
      }
    }

    return {
      vor,
      ang,
      geb,
      wList,
      abGem
    }
  }

  private activateAll() {
    this.setActiveAll(true)
  }

  private deactivateAll() {
    this.setActiveAll(false)
  }

  private async setActiveAll(aktiv: boolean) {
    try {
      const ret = await this.aktionService.setActiveAll(aktiv);
      if(ret.success) {
        this.toastrService.success(ret.error, "Erfolg");
      } else {
        this.toastrService.error(ret.error, "Fehler");
      }
    } catch (err) {
      this.toastrService.error(err?.error || err, "Fehler");
    }
  }

  public async kinderAnmelden() {
    try{
      const msg = await this.anmeldungService.kinderAnmelden();
      this.toastrService.success(msg);
    } catch (e) {
      this.toastrService.error(e?.msg || e, "Fehler");
    }
  }
}
