import {Component, Input, OnInit} from '@angular/core';
import {getElternKeyPrettyName, IEltern} from "../../models/IEltern.interface";
import {Rolle} from "../../models/enums";
import {SessionService} from "../../service/session.service";
import {EventQueueService} from "../../service/event-queue-service.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {ElternService} from "../../service/eltern.service";
import * as sha1 from 'js-sha1';

@Component({
  selector: 'app-edit-eltern',
  templateUrl: './edit-eltern.component.html',
  styleUrls: ['./edit-eltern.component.scss']
})
export class EditElternComponent implements OnInit {

  @Input()
  public eltern!: IEltern;
  public Rolle = Rolle;

  public passwort1 = "";
  public passwort2 = "";
  public passwortOk = true;

  public isMa = false;
  public isAdm = false;
  public isSelf = false;

  public readonly fields = [
    {key: 'vorname', type: 'text', placeholder: '', pflichtfeld: true},
    {key: 'nachname', type: 'text', placeholder: '', pflichtfeld: true},
    {key: 'strasse', type: 'text', placeholder: '', pflichtfeld: true},
    {key: 'hausnummer', type: 'text', placeholder: '', pflichtfeld: true},
    {key: 'plz', type: 'number', placeholder: '', pflichtfeld: true},
    {key: 'ort', type: 'text', placeholder: '', pflichtfeld: true},
    {key: 'zusatz', type: 'text', placeholder: '', pflichtfeld: false},
    {key: 'mail', type: 'mail', placeholder: '', pflichtfeld: true},
    {key: 'telefon', type: 'phone', placeholder: '', pflichtfeld: false},
    {key: 'mobile', type: 'phone', placeholder: '', pflichtfeld: false},
    {key: 'kontoinhaber', type: 'text', placeholder: '', pflichtfeld: true},
    {key: 'iban', type: 'iban', placeholder: '', pflichtfeld: true},
    {key: 'bic', type: 'text', placeholder: '', pflichtfeld: true},
    {key: 'bank', type: 'text', placeholder: '', pflichtfeld: true},
  ];


  constructor(
    private readonly sessionService: SessionService,
    private readonly eventQueue: EventQueueService,
    private readonly route:ActivatedRoute,
    private readonly elternService: ElternService,
    private readonly toastrService:ToastrService,
    private readonly router:Router
  ) {
    EditElternComponent.addLabel(this.fields);
    this.route.params.subscribe(async prams => {
      this.eltern = this.elternService.getById(Number.parseInt(prams['id']));
      this.isSelf = this.sessionService.session.nutzer.id === this.eltern.id;
    });

    this.isMa = this.sessionService.isMa();
    this.isAdm = this.sessionService.isAdm();

    this.eventQueue.getObservable().subscribe(async msg=>{
      if(msg === "initDone"){
        this.isMa = this.sessionService.isMa();
        this.isAdm = this.sessionService.isAdm();
      }
    });
  }

  private static addLabel(arr: Array<{key: string, label?: string}>) {
    arr.forEach(v => {
      if(!v.label) {
        return v.label = getElternKeyPrettyName(v.key as keyof IEltern);
      }
    });
  }

  ngOnInit(): void {
  }


  async saveEltern():Promise<void>{
    if(this.passwort1 !== this.passwort2) {
      this.toastrService.error("Passwörter stimmen nicht überein.", "Fehler")
    }
    if(!this.passwortOk && this.passwort1) {
      this.toastrService.error("Passwort ist nicht sicher.", "Fehler")
    }
    if(this.passwort1) {
      this.eltern.passworthash = sha1(this.passwort1)
    }

    try{
      let result = await this.elternService.update(this.eltern);
      this.toastrService.success(result,"Erfolgreich");
      await this.router.navigate(["/settings/elternList"]);
    }catch(exc){
      this.toastrService.error(exc,"Fehler");
    }
  }
}
