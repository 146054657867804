export interface IAbstractModel {
  id: number
  created: number
  edited: number
}

export function getAbstractModelKeyPrettyName(key: string): string {
  switch (key) {
    case "id": return "ID";
    case "created": return "Erstellt";
    case "edited": return "Bearbeitet";
    default:
      console.warn("Unknown key: " + key);
      return key;
  }
}
