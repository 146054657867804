import {Component} from '@angular/core';
import {IKind} from "../../models/IKind.interface";
import {ActivatedRoute, Router} from "@angular/router";
import {KindService} from "../../service/kind.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-edit-kind',
  templateUrl: './edit-kind.component.html',
  styleUrls: ['./edit-kind.component.scss']
})
export class EditKindComponent {

  public kind!: IKind
  public transferleistung = false;


  constructor(private readonly route:ActivatedRoute,
              private readonly kinderService:KindService,
              private readonly toastrService:ToastrService,
              private readonly router:Router) {
    this.route.params.subscribe(async prams => {
      this.kind = this.kinderService.getById(Number.parseInt(prams['id']));
      this.transferleistung = !!this.kind.soziPass;
    });
  }

  async saveKind():Promise<void>{
    if(this.transferleistung){
      if(!this.kind.soziPass) {
        this.toastrService.error("Bitte geben Sie eine Bildungspassnummer ein!", "Passnummer fehlt!");
        return;
      }
      if(!this.kind.soziFile){
        this.toastrService.error("Bitte laden Sie Ihren Bildungspass hoch!","Pass fehlt!");
        return;
      }
    }else{
      this.kind.soziPass = "";
      this.kind.soziFile = "";
    }
    try{
      let result = await this.kinderService.update(this.kind);
      this.toastrService.success(result,"Erfolgreich");
      await this.router.navigate(["/settings/elternList"]);

    }catch(exc){
      this.toastrService.error(exc,"Fehler");
    }
  }
}
