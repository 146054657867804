<div class="container py-5">
    <h3 class="text-center">Anmeldungsübersicht {{aktion.titel}}</h3>
    <div class="d-flex justify-content-end align-content-center">
        <button class="btn pcButton mr-2" (click)="mediListeDrucken()"><i class="fas fa-suitcase-medical fa-2x"></i></button>
        <button class="btn pcButton" (click)="kinderlisteDrucken()"><i class="fas fa-clipboard-list fa-2x"></i></button>
    </div>
    <table class="table my-4">
        <tr class="text-center grayBgColor sticky-top">
            <th colspan="5">Gebucht</th>
        </tr>
        <tr class="text-center grayBgColor sticky-top">
            <th class="tdId">ID</th>
            <th class="tdKind">Kind</th>
            <th class="tdAlter">Alter</th>
            <th class="tdAdresse">Adresse</th>
            <th class="tdAktion">Aktion</th>
        </tr>
        <tr *ngFor="let a of gebuchtListe">
            <td class="tdId">{{a.kind.id}}</td>
            <td class="tdKind">{{a.kind.vorname}} {{a.kind.nachname}}</td>
            <td class="tdAlter">{{a.kind.geburtsdatum | ageFilter}} Jahre</td>
            <td class="tdAdresse">{{a.kind.strasse}} {{a.kind.hausnummer}}, {{a.kind.plz}} {{a.kind.ort}}</td>
            <td class="tdAktion">
                <button *ngIf="!isLoading[a.kind.id]" class="btn pcButton ml-1" ngbTooltip="Kind auf Warteliste setzen." (click)="changeAnmeldestatus(a, anmeldeStatus.WARTELISTE)"><i class="fas fa-hourglass"></i></button>
                <button *ngIf="!isLoading[a.kind.id]" class="btn pcButton ml-1" ngbTooltip="Kind abmelden." (click)="changeAnmeldestatus(a, anmeldeStatus.ABGEMELDET)"><i class="fas fa-close"></i></button>
                <span class="btn pcButton" *ngIf="isLoading[a.kind.id]"><i class="fas fa-circle-notch fa-spin"></i></span>
            </td>
        </tr>
    </table>

    <table class="table my-4">
        <tr class="text-center grayBgColor sticky-top">
            <th colspan="5">Warteliste</th>
        </tr>
        <tr class="text-center grayBgColor sticky-top">
            <th class="tdId">ID</th>
            <th class="tdKind">Kind</th>
            <th class="tdAlter">Alter</th>
            <th class="tdAdresse">Adresse</th>
            <th class="tdAktion">Aktion</th>
        </tr>
        <tr *ngFor="let a of warteListe">
            <td class="tdId">{{a.kind.id}}</td>
            <td class="tdKind">{{a.kind.vorname}} {{a.kind.nachname}}</td>
            <td class="tdAlter">{{a.kind.geburtsdatum | ageFilter}} Jahre</td>
            <td class="tdAdresse">{{a.kind.strasse}} {{a.kind.hausnummer}}, {{a.kind.plz}} {{a.kind.ort}}</td>
            <td class="tdAktion">
                <button *ngIf="!isLoading[a.kind.id]" class="btn pcButton ml-1" ngbTooltip="Kind buchen." (click)="changeAnmeldestatus(a, anmeldeStatus.GEBUCHT)"><i class="fas fa-check"></i></button>
                <button *ngIf="!isLoading[a.kind.id]" class="btn pcButton ml-1" ngbTooltip="Kind abmelden." (click)="changeAnmeldestatus(a, anmeldeStatus.ABGEMELDET)"><i class="fas fa-close"></i></button>
                <span class="btn pcButton" *ngIf="isLoading[a.kind.id]"><i class="fas fa-circle-notch fa-spin"></i></span>
            </td>
        </tr>
    </table>
    <table class="table my-4">
        <tr class="text-center grayBgColor sticky-top">
            <th colspan="5">Angemeldet</th>
        </tr>
        <tr class="text-center grayBgColor sticky-top">
            <th class="tdId">ID</th>
            <th class="tdKind">Kind</th>
            <th class="tdAlter">Alter</th>
            <th class="tdAdresse">Adresse</th>
            <th class="tdAktion">Aktion</th>
        </tr>
        <tr *ngFor="let a of angemeldeteListe">
            <td class="tdId">{{a.kind.id}}</td>
            <td class="tdKind">{{a.kind.vorname}} {{a.kind.nachname}}</td>
            <td class="tdAlter">{{a.kind.geburtsdatum | ageFilter}} Jahre</td>
            <td class="tdAdresse">{{a.kind.strasse}} {{a.kind.hausnummer}}, {{a.kind.plz}} {{a.kind.ort}}</td>
            <td class="tdAktion">
                <button *ngIf="!isLoading[a.kind.id]" class="btn pcButton ml-1" ngbTooltip="Kind buchen." (click)="changeAnmeldestatus(a, anmeldeStatus.GEBUCHT)"><i class="fas fa-check"></i></button>
                <button *ngIf="!isLoading[a.kind.id]" class="btn pcButton ml-1" ngbTooltip="Kind auf Warteliste setzen." (click)="changeAnmeldestatus(a, anmeldeStatus.WARTELISTE)"><i class="fas fa-hourglass"></i></button>
                <button *ngIf="!isLoading[a.kind.id]" class="btn pcButton ml-1" ngbTooltip="Kind abmelden." (click)="changeAnmeldestatus(a, anmeldeStatus.ABGEMELDET)"><i class="fas fa-close"></i></button>
                <span class="btn pcButton" *ngIf="isLoading[a.kind.id]"><i class="fas fa-circle-notch fa-spin"></i></span>
            </td>
        </tr>
    </table>
    <table class="table my-4">
        <tr class="text-center grayBgColor sticky-top">
            <th colspan="5">Vorangemeldet</th>
        </tr>
        <tr class="text-center grayBgColor sticky-top">
            <th class="tdId">ID</th>
            <th class="tdKind">Kind</th>
            <th class="tdAlter">Alter</th>
            <th class="tdAdresse">Adresse</th>
            <th class="tdAktion">Aktion</th>
        </tr>
        <tr *ngFor="let a of vorangemeldeteListe">
            <td class="tdId">{{a.kind.id}}</td>
            <td class="tdKind">{{a.kind.vorname}} {{a.kind.nachname}}</td>
            <td class="tdAlter">{{a.kind.geburtsdatum | ageFilter}} Jahre</td>
            <td class="tdAdresse">{{a.kind.strasse}} {{a.kind.hausnummer}}, {{a.kind.plz}} {{a.kind.ort}}</td>
            <td class="tdAktion">
                <button *ngIf="!isLoading[a.kind.id]" class="btn pcButton ml-1" ngbTooltip="Kind buchen." (click)="changeAnmeldestatus(a, anmeldeStatus.GEBUCHT)"><i class="fas fa-check"></i></button>
                <button *ngIf="!isLoading[a.kind.id]" class="btn pcButton ml-1" ngbTooltip="Kind auf Warteliste setzen." (click)="changeAnmeldestatus(a, anmeldeStatus.WARTELISTE)"><i class="fas fa-close"></i></button>
                <span class="btn pcButton" *ngIf="isLoading[a.kind.id]"><i class="fas fa-circle-notch fa-spin"></i></span>
            </td>
        </tr>
    </table>
</div>