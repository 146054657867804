import {Component} from '@angular/core';
import {SessionService} from 'src/app/service/session.service';
import {EventQueueService} from 'src/app/service/event-queue-service.service';
import * as sha1 from 'js-sha1';
import {CheatServiceService} from 'src/app/service/cheat-service.service';
import {Rolle} from "../../models/enums";

@Component({
  selector: 'app-login',
  templateUrl: './login-status.component.html',
  styleUrls: ['./login-status.component.scss']
})
export class LoginStatusComponent {
  public username = "";
  public password = "";
  public isEt: boolean;
  public maxPerm: Rolle;
  public Rolle = Rolle;
  public loading = false;
  constructor(
    public sessionService: SessionService,
    public cheatService: CheatServiceService,
    private eventQueue: EventQueueService) {
    this.updatePermissionButtons();

    this.eventQueue.getObservable().subscribe(msg => {
      if (msg === "initDone") {
        this.updatePermissionButtons();
        this.loading = false;
      }
    });
  }

  private updatePermissionButtons() {
    if(this.sessionService.session && this.sessionService.session.nutzer){
      this.isEt = this.sessionService.session.currentPermission === Rolle.ET;
      this.maxPerm = this.sessionService.session.nutzer.rolle;
    } else {
      this.isEt = true;
      this.maxPerm = Rolle.ET;
    }
  }

  public login() {
    this.sessionService.login(this.username, sha1(this.password));
    this.password = "";
  }

  public logout() {
    this.sessionService.logout();
    this.username = "";
  }

  public changeRoll() {
    this.sessionService.togglePermission();
    this.loading = true;
  }

}
