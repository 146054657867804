import {getAbstractModelKeyPrettyName, IAbstractModel} from "./IAbstractModel.interface";
import {Schwimmabzeichen} from "./enums";
import {IIcon} from "./IIcon.interface";
import {IAnmeldung} from "./IAnmeldung.interface";
import {IAusgaben} from "./IAusgaben.interface";

export interface IAktion extends IAbstractModel{
  titel: string
  beschreibung: string
  start: number
  ende: number
  ort: string
  veranstalter: string
  minAlter: number
  maxAlter: number
  preis: number
  soziPreis: number
  maxKinder: number
  schwimmabzeichen: Schwimmabzeichen
  icon: IIcon
  aktiv: boolean
  anmeldungen: IAnmeldung[]
  ausgaben: IAusgaben[]
}


export function isAktion(obj: Object): obj is IAktion {
  function d(value: string): boolean{
    return value !== undefined;
  }

  return obj &&
    d(obj["titel"]) &&
    d(obj["beschreibung"]) &&
    d(obj["start"]) &&
    d(obj["ende"]) &&
    d(obj["ort"]) &&
    d(obj["veranstalter"]) &&
    d(obj["minAlter"]) &&
    d(obj["maxAlter"]) &&
    d(obj["preis"]) &&
    d(obj["soziPreis"]) &&
    d(obj["maxKinder"]) &&
    d(obj["schwimmabzeichen"]) &&
    d(obj["icon"]) &&
    d(obj["aktiv"]) &&
    d(obj["anmeldungen"]) &&
    d(obj["ausgaben"])
}

export function getAktionKeyPrettyName(key: keyof IAktion): string {
  switch (key) {
    case "titel":
      return "Titel";
    case "beschreibung":
      return "Beschreibung";
    case "start":
      return "Start";
    case "ende":
      return "Ende";
    case "ort":
      return "Ort";
    case "veranstalter":
      return "Veranstalter";
    case "minAlter":
      return "Mindestalter";
    case "maxAlter":
      return "Höchstalter";
    case "preis":
      return "Preis";
    case "soziPreis":
      return "Sozialpreis";
    case "maxKinder":
      return "Maximale Anmeldungen";
    case "schwimmabzeichen":
      return "Schwimmabzeichen";
    case "icon":
      return "Icon";
    case "aktiv":
      return "Aktiv";
    case "anmeldungen":
      return "Anmeldungen";
    case "ausgaben":
      return "Ausgaben";
    default: return getAbstractModelKeyPrettyName(key);
  }
}

export function getAktionSearchCategories() {

  return [
    { display: "Allgemein", key: "Allgemein", type: "CAT" },
    { key: "titel", type: "string" },
    { key: "beschreibung", type: "string" },
    { key: "start", type: "Date" },
    { key: "ende", type: "Date" },
    { key: "ort", type: "string" },
    { key: "veranstalter", type: "string" },
    { key: "minAlter", type: "number" },
    { key: "maxAlter", type: "number" },
    { key: "preis", type: "number" },
    { key: "soziPreis", type: "number" },
    { key: "maxKinder", type: "number" },
    { key: "schwimmabzeichen", type: "string" },
    { key: "aktiv", type: "boolean" },
    { key: "id", type: "number" },
  ].map(v => {
    if (v.display == null) {
      v.display = getAktionKeyPrettyName(v.key as keyof IAktion);
    }
    return v;
  });
}
